.ItemContainer {
  display: flex;
  align-items: flex-start;
}
.MainLabel {
  font-weight: bold;
  flex-basis: 19.4rem;
  flex-shrink: 0;
}
.FormContent {
  margin-left: 19.4rem;
}
.DeadLineAttendance__FormContainer {
  align-items: center;
}
.DeadLineAttendance__AssumptionText {
  font-weight: bold;
}
.DeadLineAttendance__Select {
  width: 27rem;
  margin-left: 1rem;
  cursor: not-allowed;
}
.LeaveComment__Content {
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  padding-left: 2rem;
}
.LeaveComment__Label {
  font-weight: bold;
  padding-right: 1rem;
}
.QuestionnaireForm__Content {
  margin-top: 2rem;
}
.GrayedContent {
  background-color: var(--bui-colors-gray-100);
  border-radius: 5px;
}
.submitButtonWrapper {
  text-align: right;
  margin-top: 1.2rem;
}

.form {
  margin-top: 2.4rem;
}

.submitButton {
  width: 12rem;
}
