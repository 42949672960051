@use "../../../styles/variables.scss" as *;

.root {
  color: $color-black;
  position: relative;
}
.arrowIcon {
  width: 1rem;
}
.calendarContainer {
  position: absolute;
  z-index: 2;
}
.controller {
  width: 100%;
  padding: 1.4rem 1.6rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}
.muted {
  color: $color-gray-darken-1;
  fill: $color-gray-darken-1;
}
