.u-margin-top-2\.4 {
  margin-top: 2.4rem;
}
.BreadCrumbWrapper {
  margin-top: 1.6rem;
}
.SummaryDetailWrapper {
  margin-top: 2rem;
}
.ContentWrapper {
  margin-top: 2rem;
}
.ContentTabWrapper {
  margin-left: 2rem;
}
