@use "../../../styles/variables.scss" as *;

.tableWrapper {
  background-color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
  overflow-x: scroll;
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  color: $color-black-lighten-2;

  thead .row:first-child {
    border-top: 0;
  }
}

.headRow {
  font-size: 1.2rem;

  & > th {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}

.row {
  border-top: 1px solid var(--bui-colors-gray-300);
  min-height: 6.4rem;
  box-sizing: border-box;

  & > td {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 9.3rem;
    box-sizing: border-box;

    &.title {
      padding-right: 1.4rem;
      text-align: left;
      border-top: 0;

      & > a {
        color: $color-black;
        font-size: 1.4rem;
        font-weight: bold;
        text-decoration: underline;
        width: 11.6rem;
        max-height: 4.2rem;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
      }
    }

    &.plan {
      width: 0;
      min-width: 4rem;
      max-width: 6rem;
    }

    &.code {
      width: 0;
      white-space: pre-wrap;
      word-break: break-all;
    }

    &.num {
      min-width: 4rem;
    }
  }
}

.cell {
  padding: 1.8rem 2.2rem;
  white-space: nowrap;
  color: $color-black;
}

.heading {
  color: $color-black-lighten-2;
}

.sort {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;

  &.highlighted {
    color: $color-primary;
    fill: $color-primary;
  }
}

.level15 {
  background-color: $color-analytics-level-15;
}

.level14 {
  background-color: $color-analytics-level-14;
}

.level13 {
  background-color: $color-analytics-level-13;
}

.level12 {
  background-color: $color-analytics-level-12;
}

.level11 {
  background-color: $color-analytics-level-11;
}

.level00 {
  background-color: $color-analytics-level-00;
}

.level01 {
  background-color: $color-analytics-level-01;
}

.level02 {
  background-color: $color-analytics-level-02;
}

.level03 {
  background-color: $color-analytics-level-03;
}

.level04 {
  background-color: $color-analytics-level-04;
}

.level05 {
  background-color: $color-analytics-level-05;
}

.notfound {
  text-align: center;
}

@include max-screen($breakpoint-mobile) {
  .title {
    padding: 0.4rem;
  }

  .job {
    padding: 0 2rem;
    min-width: 5.6rem;
  }

  .tableWrapper {
    overflow-x: scroll;
  }
}
