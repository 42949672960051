@use "../../../styles/variables.scss" as *;
@use "sass:color";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;
  padding: 1.4rem 0;
  height: 6.4rem;
  box-sizing: border-box;

  &:hover {
    background: $color-gray-lighten-2;
  }
}

.isUnread {
  background: #f1f7e9;

  .attention {
    visibility: visible;
  }

  &:hover {
    background: color.adjust($color-gray-lighten-2, $lightness: -5%);
  }
}

.isActive {
  background: $color-gray-lighten-2;
}

.left {
  width: 6rem;
  height: 2.8rem;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.right {
  padding: 0 0.8rem 0 1rem;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.rightHeader {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.name {
  @include font-bold-m();
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 12.6rem;
}

.datetime {
  font-size: $font-regular-xs-font-size;
  line-height: 1.5rem;
  margin-left: 1.6rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  font-size: $font-regular-xs-font-size;
  width: 26rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.6rem;
  line-height: 1.2;
}

.icon {
  width: 3.6rem;
  height: 3.6rem;
  fill: #89c33b;
  background-color: #fff;
  border-radius: 50%;
}

.attention {
  padding: 0.8rem;
  line-height: 0;
  visibility: hidden;
}

.attention__dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: inline-block;
  background: $color-primary;
}

.follow {
  flex-grow: 1;
  text-align: right;
  display: none;
}
