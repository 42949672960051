@use "../../styles/variables.scss" as *;

.buttonContainer {
  width: 16rem;
}

@include max-screen($breakpoint-mobile) {
  .buttonContainer {
    margin-top: 2rem;
    width: 100%;
  }
}
