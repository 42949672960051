@use "../../../styles/variables.scss" as *;

.root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  white-space: nowrap;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.bold {
  font-weight: bold;
}
