@use "../../../../styles/variables.scss" as *;

.root {
  width: 100%;
  table-layout: fixed;
  background-color: #ffffff;
  border-radius: 0.8rem;
  margin-top: 2rem;
}

.header {
  padding: 1.4rem 9rem;
  font-weight: normal;
  font-size: $font-regular-m-font-size;
  color: var(--bui-colors-black);
}
.actionHeader {
  @extend .header;
  width: 14rem;
}

.notfound {
  text-align: center;
}
