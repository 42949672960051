.stackForm > .stackItem:not(:first-child) {
  margin-top: 2rem;
}
.fieldLayout {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  // アイコンのセル
  > *:first-child {
    padding-right: 0.8rem;
  }
}

.fieldLayoutOneRow {
  @extend .fieldLayout;
}

.fieldLayoutMultiRows {
  @extend .fieldLayout;
  > *:nth-child(n + 2) {
    grid-column: 2 / 3;
  }
}

.scheduleTimeFieldLayout {
  @extend .fieldLayoutMultiRows;
  > *:first-child {
    align-self: flex-start;
    margin-top: 1rem;
  }
}

.formFooter {
  padding-top: 3.2rem;
  padding-bottom: 1.6rem; // 共通モーダル自体のpadding(1.6rem) + 1.6rem = 3.2rem
  text-align: center;
}

.studyPlanFieldStack {
  margin-top: 1.5rem;
}
.studyPlanFieldStack > *:not(:first-child) {
  margin-top: 0.4rem;
}
.studyPlanFieldStack:first-of-type {
  margin-right: 4rem;
}
.studyPlanDurationInput {
  width: 10.4rem;
}
.studyPlanAmountInput {
  width: 11.4rem;
}
.studyPlanUnitTextWrapper {
  padding: 0 1rem;
}
.scheduleFormLabel {
  padding: 0;
  margin: 0;
}
.scheduleTimeHStack {
  display: flex;
}
.scheduleTimeHStack {
  display: flex;
  align-items: center;
}
.scheduleTimeHStack > *:not(:last-child) {
  margin-right: 1rem;
}
.scheduleDatePicker {
  width: 11.2rem;
  box-sizing: border-box;
}
