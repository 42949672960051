@use "../../../../styles/variables" as *;

.root {
  margin-top: 2.4rem;
}

.message {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;

  & > * {
    display: inline-flex;
    line-height: 2.4rem;
    margin-left: 1rem;
  }

  & > :first-child {
    margin-left: 0;
  }
}

.content {
  word-wrap: break-word;
  min-width: 0;
}

.body {
  margin-top: 0.8rem;
  margin-left: 3.2rem;
  padding: 1.6rem 2rem;
  background-color: #f3f7ff;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
}
.status {
  margin-top: 1.6rem;
  border-left: 0.4rem solid $color-primary-lighten-1;
  padding-left: 2rem;
  font-weight: bold;
}
.statusWithReactions {
  @extend .status;
  margin-top: 2.4rem;
}
.status__reactions {
  margin-top: 0.8rem;
  & > button,
  & > a {
    margin-left: 1rem;
    &:first-child {
      margin-left: 0;
    }
  }
}
.status__done {
  color: $color-primary;
}
.status__ignored {
  color: $color-black;
}
.botIcon {
  width: 2.4rem;
  height: 2.4rem;
}
.botIconWrapper {
  fill: $color-icon-cyan;
}
.name {
  font-weight: bold;
}
.eyeIcon {
  fill: #abb1c1;
}
.datetime {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
}
.caution {
  font-size: $font-regular-xs-font-size;
  color: $color-gray-darken-3;
  margin-left: 1.4rem;
}
.skillName {
  color: $color-black;
  font-weight: bold;
  text-decoration: underline;
}
.warning {
  margin-left: 3.2rem;
  font-size: $font-regular-xs-font-size;
}
.emoji {
  font-family: $emoji-font-family;
}

.grayButton {
  --bui-button-main-color: var(--bui-colors-gray-900);
}

.redButton {
  --bui-button-main-color: var(--bui-colors-darkred-300);
}

@include max-screen($breakpoint-tablet-wide) {
  .header {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .caution {
    margin-top: 1rem;
    margin-left: 3.2rem;
  }
  .status__reactions {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;
    & > button {
      margin-top: 1rem;
      margin-left: 0;
      &:first-child {
        margin-top: 0;
      }
    }
    & > a {
      margin-top: 1rem;
      margin-left: 0;
      & > button {
        width: 100%;
      }
    }
  }
}
