@use "../../../styles/variables.scss" as *;

.root {
  position: relative;
  display: inline-block;
  & > svg {
    position: absolute;
    top: 1.1rem;
    left: 2rem;
    fill: $color-gray-darken-1;
  }
}
.isBlock {
  display: block;
  width: 100%;
}
.input {
  height: 4.6rem;
  border: 0;
  border-radius: 0.4rem;
  border: 1px solid $color-gray-lighten-1;
  padding: 0 4.8rem;
  &:focus {
    outline: 0;
    border-color: $color-primary;
  }
  &::placeholder {
    color: #abb1c1;
  }
}
.reset {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0;
  &:hover {
    & > .icon {
      fill: $color-gray-darken-3;
    }
  }
}
.icon {
  width: 1.6rem;
  height: 1.6rem;
  fill: $color-gray-darken-1;
}
