.Lecture {
  display: grid;
  grid-template-columns: 2.6rem 1fr;
}
.Lecture > div:nth-of-type(3) {
  grid-row: 2;
  grid-column: 2;
}

.Lecture__attendanceStatus {
  margin-left: 0.4rem;
}
.Lecture__attendance {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Lecture__attendanceIcon {
  width: 1.6rem;
  height: 1.6rem;
}
.Lecture__absenseReason {
  margin-top: 0.8rem;
}
