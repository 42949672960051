@use "../../../styles/variables.scss" as *;

.newLink {
  margin: 1.6rem 0;
  display: flex;
  justify-content: flex-end;
}

.Btn {
  width: 14.8rem;
}

.tableWrapper {
  margin: 1.6rem 0;
}
