@use "../../../styles/variables.scss" as *;

.buttonLink {
  margin: 1rem 0 0 1.6rem;
  &:first-child {
    margin-left: 0;
  }
}

.tableWrapper {
  margin-top: 2rem;
}

@include max-screen($breakpoint-mobile) {
  .buttonLink {
    margin-left: 1rem;
  }
}

.icon {
  margin-right: 1rem;
  margin-left: -0.4rem;
}
