@use "../../../styles/variables.scss" as *;

.input {
  border: 1px solid $color-gray-normal;
  border-radius: 0.5rem;
  height: 4.6rem;
}

.error {
  border-color: $color-notification;
}
