@use "../../../styles/variables.scss" as *;

.root {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  overflow: hidden;
}

.right {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  min-width: 0;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: block;
  }

  .right {
    display: none;
  }

  .focused {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .covered {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }
}
