.defaultSize {
  width: 2.4rem;
  height: 2.4rem;
}

.disabled {
  opacity: 0.5;
}
.hoverWithBackground {
  border-radius: 0.6rem;
  padding: 0.4rem;
}
.hoverWithBackground:hover {
  background: #eee;
}
