@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 2rem;
}
.error {
  position: absolute;
  color: $color-notification;
  font-size: $font-regular-xs-font-size;
}

.messageFieldset__ignorance {
  position: relative;
}
.messageFieldset__ignorance__field {
  width: 8.4rem;
  height: 2.4rem;
  margin: 0 0.8rem;
  border: 1px solid #dde0e8;
  border-radius: 0.2rem;
}
.messageFieldset__ignorance__fieldError {
  border-color: $color-notification;
}
.block {
  margin-top: 1.6rem;
}
