@use "../../../styles/variables.scss" as *;

.root {
  position: relative;
}

.dropdown {
  display: none;
}

.dropdown.opened {
  background: #fff;
  display: block;
  position: absolute;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-shadow: 0 2px 6px 0 rgba(29, 40, 50, 0.1);
  width: 11.4rem;
  margin-top: 0.8rem;
  right: 1rem;
  padding: 0.8rem 0 0;
  z-index: 20;
  overflow: hidden;
}

.dropdownRow {
  height: 3.8rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  cursor: not-allowed;

  &:hover {
    background: $color-gray-lighten-3;
  }

  &.clickable {
    cursor: pointer;
  }

  &.dropdownCloseButton {
    height: 4.4rem;
    padding: 0;
    color: $color-primary;
    font-weight: bold;
    justify-content: center;
    cursor: pointer;
  }
  .checkedLabel {
    color: $color-primary;
    font-weight: bold;
  }
}

.dropdownSeparator {
  border-bottom: 1px solid $color-gray-lighten-1;
  margin: 1rem 0 0;
}

.backdrop {
  z-index: 10;
}

@include min-screen(calc($breakpoint-mobile + 1px)) {
  .backdrop {
    background: none;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
  }

  .dropdown.opened {
    width: 100vw;
    position: fixed;
    box-sizing: border-box;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }
}
