@use "../../../../styles/variables.scss" as *;

.dropdown {
  display: none;
}

.openedDropdown {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-shadow: 0 2px 6px 0 rgba(29, 40, 50, 0.1);
  box-sizing: border-box;
  width: 42.8rem;
  z-index: 20;
}

.backdrop {
  z-index: 10;
}

@include min-screen(calc($breakpoint-mobile + 1px)) {
  .backdrop {
    background: none;
  }
}

.main {
  padding: 1.6rem;
}
.closeButton {
  width: 100%;
  height: 4.7rem;
  border-top: 1px solid #eee;
  padding: 1rem;
  color: $color-primary;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;

  &:hover {
    background: $color-gray-lighten-3;
  }
}

@include max-screen($breakpoint-mobile) {
  .openedDropdown {
    display: block;
    position: fixed;
    box-sizing: border-box;
    width: 100vw;
    height: 35.4rem;
    top: calc(50% - 35.4rem / 2);
    bottom: calc(50% - 35.4rem / 2);
    left: 0;
    right: 0;
    z-index: 20;
  }

  .closeButton {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
