@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 3.2rem;
}
.bookshelf {
}
.bookshelf__list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 3rem;
}
.categoryName {
  &:before {
    content: "";
    display: inline-block;
    background: $color-primary;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
}
.row {
  display: flex;
  justify-content: space-between;
}
.select {
  width: 22.4rem;
}

@include max-screen($breakpoint-mobile) {
}
