@use "./variables.scss" as *;

.bg-primary {
  background-color: $color-primary;
}
.bg-black {
  background-color: $color-black;
}
.bg-black-lighten-1 {
  background-color: $color-black-lighten-1;
}
.bg-black-lighten-2 {
  background-color: $color-black-lighten-2;
}
.bg-gray-lighten-1 {
  background-color: $color-gray-lighten-1;
}
.bg-gray-lighten-2 {
  background-color: $color-gray-lighten-2;
}
.bg-gray-lighten-3 {
  background-color: $color-gray-lighten-3;
}
.bg-gray-darken-1 {
  background-color: $color-gray-darken-1;
}
.bg-gray-darken-2 {
  background-color: $color-gray-darken-2;
}
.bg-gray-darken-3 {
  background-color: $color-gray-darken-3;
}
.bg-gray-normal {
  background-color: $color-gray-normal;
}
.bg-yellow {
  background-color: $color-icon-yellow;
}
.bg-pink {
  background-color: $color-icon-pink;
}
.bg-notification {
  background-color: $color-notification;
}
.bg-white {
  background-color: #fff;
}
.bg-green {
  background-color: $color-icon-green;
}
.bg-red {
  background-color: $color-red;
}

.text-default {
  fill: currentColor;
}
.text-primary {
  color: $color-primary;
}
.text-black {
  color: $color-black;
}
.text-black-lighten-1 {
  color: $color-black-lighten-1;
}
.text-black-lighten-2 {
  color: $color-black-lighten-2;
}
.text-gray-lighten-1 {
  color: $color-gray-lighten-1;
}
.text-gray-lighten-2 {
  color: $color-gray-lighten-2;
}
.text-gray-lighten-3 {
  color: $color-gray-lighten-3;
}
.text-gray-darken-1 {
  color: $color-gray-darken-1;
}
.text-gray-darken-2 {
  color: $color-gray-darken-2;
}
.text-gray-darken-3 {
  color: $color-gray-darken-3;
}
.text-yellow {
  color: $color-icon-yellow;
}
.text-notification {
  color: $color-notification;
}
.text-gray-normal {
  color: $color-gray-normal;
}
.text-pink {
  color: $color-icon-pink;
}
.text-white {
  color: #fff;
}
.text-green {
  color: $color-icon-green;
}
.text-red {
  color: $color-red;
}

.groupHover:hover .hover-text-primary,
.hover-text-primary:hover {
  color: $color-primary;
}

.groupHover:hover .hover-bg-primary,
.hover-bg-primary:hover {
  background-color: $color-primary;
}

.groupHover:hover .hover-text-notification,
.hover-text-notification:hover {
  color: $color-notification;
}

.groupHover:hover .hover-bg-notification,
.hover-bg-notification:hover {
  background-color: $color-notification;
}

.groupHover:hover .hover-bg-gray-lighten-2,
.hover-bg-gray-lighten-2:hover {
  background-color: $color-gray-lighten-2;
}

.groupHover:hover .hover-bg-red-lighten-2,
.hover-bg-red-lighten-2:hover {
  background-color: $color-red-ligten-2;
}
