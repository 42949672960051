@use "../../../styles/variables.scss" as *;

.actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.tile {
  box-sizing: border-box;
  height: 27rem;
  width: 27rem;
  margin: 0 3rem 4.4rem 0;
  padding: 3.2rem 5rem;
  flex-shrink: 0;
  flex-grow: 0;

  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  background-color: #fff;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.tileTitle {
  font-size: 1.6rem;
  font-weight: bold;
  color: $color-black;
  white-space: nowrap;
}

.tileIconContainer {
  width: 4.8rem;
  height: 4.8rem;
  margin: 3.2rem 0 2.4rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tileButtons {
  width: 100%;
}

.tileButtonLink {
  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

@include max-screen($breakpoint-mobile) {
  .tile {
    width: 100%;
  }
}
