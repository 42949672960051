@use "../../../styles/variables.scss" as *;

.UpdateToAttendView > p:nth-of-type(2) {
  margin-top: 2rem;
}

.ButtonAreaWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 1.6rem;
  > button {
    width: 12rem;
  }
}
.ButtonAreaWrapper--margin-top24 {
  margin-top: 2.4rem;
}

.button {
  width: 12rem;
}

.UpdateToAbsentView > *:nth-child(2) {
  margin-top: 1.6rem;
}

.ReadonRadioGroups__Desc::after {
  content: "*";
  position: relative;
  top: -0.2rem;
  color: $color-notification;
  font-weight: bold;
}
.ReadonRadioGroups__Radios {
  margin-top: 1rem;
}
.ReadonRadioGroups__Radios > li:not(:first-of-type) {
  margin-top: 0.6rem;
}

.StaffShareNoteTextarea {
  margin-bottom: 1rem;
}
