@use "../../../styles/variables.scss" as *;

.tableControl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto 1fr;
}

@include max-screen($breakpoint-mobile) {
  .tableControl {
    display: block;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
  }
}
