@use "../../../styles/variables" as *;

.input {
  width: 33.5rem;
  margin-top: 2.4rem;
}

.input_label {
  display: flex;
}

.input_error {
  font-size: $font-regular-xs-font-size;
  color: $color-notification;
  margin-left: 1rem;
}

.button {
  margin-top: 3.2rem;
  display: flex;
  justify-content: center;
}
