@use "../../../styles/variables.scss" as *;

.tableWrapper {
  background-color: #fff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  overflow: hidden;
}
.studentName {
  color: $color-black;
  text-decoration: underline;
}
.studentName:hover {
  // 他のアナリティクスと合わせるため、ホバー時に色を変えないにする
  color: $color-black;
}
