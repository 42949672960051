@use "../../../../src/styles/variables.scss" as *;

.row {
  height: 5.5rem;
  border-bottom: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;

  &:last-child {
    border-bottom: 0;
  }
}

.col {
}
.studentLink {
  font-weight: bold;
  color: $color-black;
}

.last_col {
  text-align: end;
  vertical-align: middle;
  padding-right: 2rem;
}

.link {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: $color-primary;
  }
  & > svg {
    fill: currentColor;
  }
}

.modal {
  padding: 0;
  height: auto;
  margin: 0 auto;
  position: absolute;
  background: #fff;
  overflow: auto;
  outline: none;
}

.form_row {
  display: flex;
  width: 100%;
  margin-top: 1.6rem;
}

.form_row__button {
  display: flex;
  justify-content: center;
}

.input_group {
  display: block;
  width: 100%;
}

.input__time {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-normal;
  color: $color-black;
  padding: 1.6rem;
  height: 4.6rem;
  width: 100%;
  box-sizing: border-box;
}

.input__time_wrapper {
  margin-right: 0.8rem;
}

.input__date {
  width: 100%;
  cursor: pointer;
}

.input_errors {
  color: $color-notification;
  font-size: 1.2rem;
}

.btn {
  margin-top: 2rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem 5rem;
}
