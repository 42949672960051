@use "../../../styles/variables" as *;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@include max-screen($breakpoint-mobile) {
}
