@use "../../../styles/variables.scss" as *;

.root {
  z-index: 3;
}

.calendarControl {
  position: relative;
  cursor: pointer;
  padding: 0.8rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: $color-black;
}

.disabled {
  cursor: not-allowed;
}

.calendar {
  position: absolute;
  top: 5rem;
  z-index: 2;
}

.dateRange {
  margin: 0 4rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: $color-black;
}

.dateRange:hover {
  color: $color-primary;

  .calendarIcon {
    fill: $color-primary;
  }
}

.icon {
  fill: #abb1c1;
}

.icon:hover {
  fill: $color-primary;
}

.disabledIcon {
  @extend .disabled;

  &:hover {
    fill: #abb1c1;
  }
}

.calendarIcon {
  margin-left: 0.8rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
  }

  .calendarControl {
    cursor: default;
  }

  .dateRange {
    margin: 0 4.8rem;
  }

  .icon {
    fill: #abb1c1;
  }

  .calendarIcon {
    display: none;
  }
}
