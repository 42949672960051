@use "../../../../styles/variables.scss" as *;

.row {
  border-bottom: 1px solid var(--bui-colors-gray-300);
}

.nameCell {
  padding: 1.2rem 1.4rem;
}

.actionCell {
  padding: 0;
}

.actionList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-black-lighten-2;
}

.link {
  padding: 0 1.4rem;
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    fill: $color-black-lighten-2;
  }
  &:hover {
    color: $color-primary;
    svg {
      fill: $color-primary;
    }
  }

  & + & {
    padding: 0 1.4rem 0 0;
  }
}

.modalheader {
  color: $color-primary;
}

.form_row {
  display: flex;
  justify-content: center;
}

.input {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  color: $color-black;
  padding: 1.6rem;
  height: 4.6rem;
  width: 100%;
  box-sizing: border-box;
}

.input__error {
  color: $color-notification;
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 1rem;
}

.label {
  color: $color-gray-darken-2;
  font-size: $font-regular-xs-font-size;
}

.btn {
  margin-top: 2rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem 5rem;
}

.modal {
}
