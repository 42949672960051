.formContainer {
  width: 100%;
  max-width: 56.4rem;

  .buttonWrapper {
    margin: 2.4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .inputLabel {
    text-align: start;
  }

  .row {
    display: flex;
    justify-content: center;
    .input {
      width: 100%;
      margin-right: 0.8rem;
      text-align: start;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.cancelButton {
  width: 12rem;
}

.submitButton {
  width: 12rem;
}
