@use "../../../styles/variables.scss" as *;

// react-modalのデフォルトスタイルを上書き
.overlay {
  top: 0;
  left: 0;
  padding: 0;
  background: rgba(90, 90, 90, 0.7);
  position: fixed;
  z-index: $z-index-overlay;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.modal {
  left: 0;
  right: 0;
  padding: 0;
  width: 40%;
  min-width: $breakpoint-mobile;
  height: auto;
  max-height: 80%;
  margin: 0 auto;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

@include min-screen($breakpoint-mobile) {
  .modal {
    top: 50%;
    transform: translateY(-50%);
  }
}

@include max-screen($breakpoint-mobile) {
  .overlay {
    top: 0;
  }

  .modal {
    width: 100vw;
    min-width: unset;
    height: 70vh;
    max-height: 70vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    border-radius: 6px 6px 0 0;
    margin: auto 0;
    box-shadow: 0 2px 6px;
  }
}
