@use "../../../../../styles/variables.scss" as *;

.root {
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;
}

.buttons {
  line-height: 0;
  margin: 1rem 0;
  padding: 0 1.6rem;
  display: flex;
  gap: 2rem;
}

.active {
  color: $color-primary;
  fill: $color-primary;
}

@include max-screen($breakpoint-mobile) {
  .root {
    font-size: 1.2rem;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .buttons .button {
    margin-left: 0;
  }
}
