@use "../../../styles/variables.scss" as *;

.root {
  text-align: center;
  margin-bottom: 1.6rem;
}

.name {
  color: $color-black;
  font-size: $font-regular-l-font-size;
  font-weight: bold;
  white-space: normal;
  max-width: 22.4rem;
  margin: 1.6rem auto 0 auto;
}

.schoolName {
}

.sectionName {
}

.tags {
  margin-top: 1.6rem;
}

.tag {
  margin-right: 1rem;
}

.link {
  margin-left: 0.8rem;
}

.studentsCount {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-1;
  margin-top: 1rem;
}

.iconNaviSchool {
  width: 6.8rem;
  height: 6.8rem;
  fill: $color-primary;
}

@include max-screen($breakpoint-mobile) {
}
