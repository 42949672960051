@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 4.8rem;
}
.heading {
  font-size: 2.2rem;
}
.description {
  margin-top: 3.2rem;
  text-align: center;
  & > a {
    color: $color-primary;
    font-weight: bold;
  }
}
.nameContainer {
  display: flex;
}
.lastNameContainer,
.firstNameContainer {
  margin-left: 0.8rem;
  &:first-child {
    margin-left: 0;
  }
}
.passwordContainer,
.passwordConfirmContainer {
  margin-top: 2.4rem;
}
.buttonContainer {
  width: 24rem;
  max-width: 80%;
  margin: 3.2rem auto 0 auto;
}

.checkboxContainer {
  margin-top: 2.4rem;
  text-align: center;
}

@include max-screen($breakpoint-tablet) {
  .root {
    margin-top: 3.2rem;
  }
  .buttonContainer {
    width: 100%;
  }
}
