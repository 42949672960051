@use "../../../styles/variables.scss" as *;

.root {
  max-height: 36rem;
  overflow: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    /* for CSS変数をサポートしていないブラウザ */
    max-height: calc(
      100vh - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-notifications-heading-height} - #{$mobile-header-menu-footer-height}
    );

    /* CSS変数をサポートしている場合 */
    max-height: calc(
      var(--innerHeight) - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-notifications-heading-height} - #{$mobile-header-menu-footer-height}
    );
  }
}
