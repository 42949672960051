@use "../../../../styles/variables" as *;

.root {
  width: 100%;
  padding: 3.2rem 1.6rem 4rem 1.6rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  box-sizing: border-box;
}

.heading {
  text-align: center;
  font-size: $font-regular-xl-font-size;
  margin: 0;
  padding: 0;
}

.descripition {
  margin-top: 1.6rem;
  text-align: center;
}

.form {
  margin: 3.2rem auto 0 auto;
  width: 100%;
  max-width: 56.4rem;
}
