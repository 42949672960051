@use "../../../styles/variables.scss" as *;

.message {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 54rem;
  max-width: 95%;
  padding: 1.4rem 1.6rem;
  color: #fff;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  z-index: $z-index-overlay + 10;
  li {
    white-space: pre-line;
    text-align: left;
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
  & > svg {
    margin-right: 1.4rem;
  }
}
.hidden {
  transition: opacity 0.5s;
  opacity: 0;
  z-index: -1;
}
.success {
  background-color: rgba(126, 207, 164, 0.8);
  fill: #fff;
}
.error {
  background-color: rgba(244, 130, 129, 0.8);
  fill: #fff;
}
.closeIcon {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: -0.8rem;
}

@include max-screen($breakpoint-mobile) {
  .message {
    min-width: auto;
    width: 95%;
    text-align: left;
  }
}
