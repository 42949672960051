@use "../../../styles/variables.scss" as *;

.root {
  max-width: 68rem;
  & > ol {
    @include font-regular-m();
    padding: 0;
    padding-left: 2rem;
    box-sizing: border-box;
    line-height: 2.4rem;
    margin-top: 2rem;
    & > li {
      display: list-item;
      list-style-type: decimal;
      & > ol {
        padding-left: 2rem;
        & > li {
          list-style-type: lower-alpha;
        }
      }
      .numberedListSection {
        & > li {
          display: list-item;
          list-style-type: decimal;
        }
      }
    }
  }
}
.heading {
  @include font-regular-m();
  font-size: 2rem;
  margin-top: 4.8rem;
}
.summarySection {
  @include font-bold-m();
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.preambleSection {
  @include font-regular-m();
  line-height: 2.4rem;
}
