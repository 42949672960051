@use "../../../styles/variables.scss" as *;

.root {
  font-size: $font-regular-xs-font-size;
  margin: 0.8rem 0;
  display: block;
}
.mute {
  color: $color-gray-darken-2;
  font-weight: bold;
}

.inline {
  display: inline;
}

.required {
  color: $color-notification;
}

.noMargin {
  margin: 0;
}
