@use "../../../styles/variables.scss" as *;
.flex {
  display: flex;
}
.flex__alignCenter {
  align-items: center;
}
.flex__wrap {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.main {
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - 11.4rem
  ); // header(6.4) + margin1(1) + margin2(0.8) + margin3(3.2)
}

.daterange {
  padding-top: 2rem;
}

.calendar {
  flex: 1;
}
.scheduleModal {
  font-size: $font-regular-m-font-size;
  margin-left: 0.4rem;
}

.scheduleModal__header {
  display: flex;
  fill: #abb2c1;
  margin: -0.4rem;
  color: $color-black;
  font-size: $font-bold-m-font-size;
}

.scheduleModal__form > * {
  padding-top: 2rem;
}
.scheduleModal__form > :first-child {
  padding-top: 0;
}

.scheduleModal__formLabel {
  font-size: $font-bold-m-font-size;
  color: $color-gray-darken-2;
  font-weight: bold;
}
.scheduleModal__formLabel__sm {
  font-size: 1.2rem;
}
.scheduleModal__formLabel__md {
  font-size: 1.4rem;
}
.scheduleModal__formCheckbox {
  margin-right: 2rem;
}
.scheduleModal__formRow__space {
  padding-top: 2rem;
}
.scheduleModal__formTitleLabel {
  padding-top: 0.3rem;
}
.scheduleModal__formInput__title {
  padding-top: 0.6rem;
}
.scheduleModal__formStartEnd > :first-child {
  padding-top: 1rem;
}
.scheduleModal__formStudyPlanAmount > :first-child {
  margin-right: 4rem;
}

.scheduleModal__formErrorField {
  color: $color-notification;
  padding-top: 0.4rem;
}
.scheduleModal__formInput {
  padding-top: 0.8rem;
}

.scheduleModal__datepicker {
  width: 11.2rem;
}

.scheduleModal__timeSchedule {
  margin-right: 1rem;
}

.scheduleModal__recurrencePicker {
  width: 14rem;
}
.scheduleModal__titleIcons__form {
  width: 2.8rem;
}
.scheduleModal__formLabel__subLabel {
  font-size: $font-bold-xs-font-size;
}

.scheduleModal__header__detail {
  justify-content: flex-end;
}

.scheduleModal__header__form {
  justify-content: space-between;
}
.scheduleModal__formContent {
  box-sizing: border-box;
  width: 100%;
}
.scheduleModal__formContent__spaceTopMd {
  padding-top: 1.5rem;
}
.scheduleModal__formContent__spaceTopSm {
  padding-top: 0.4rem;
}
.scheduleModal__formMemo__textarea__container {
  min-height: 8.4rem;
  max-height: 20rem;
  width: 100%;
}
.scheduleModal__formMemo__textarea {
  max-height: 20rem;
  resize: vertical;
}
.scheduleModal__formMemo > :first-child {
  margin-top: 0.4rem;
}

.submitButton {
  width: 14rem;
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
}

.icon__form {
  fill: $color-primary;
  padding-right: 0.6rem;
}

.scheduleModal__header__icon {
  @extend .icon;
  margin: 0 0.8rem;
  cursor: pointer;
}

@include max-screen($breakpoint-mobile) {
  .main {
    height: calc(
      100vh - 17.4rem
    ); // sp-header(6) + header(6.4) + margin1(1) + margin2(0.8) + margin3(3.2)
  }
  .daterange {
    padding-top: 0;
  }
  .scheduleModal__formStudyPlanAmount > :first-child {
    margin-right: 0;
  }
}
