@use "../../styles/variables.scss" as *;

.RowLink {
  line-height: 0;
  svg,
  &:visited svg {
    fill: $color-black-lighten-2;
  }
  &:hover svg {
    fill: $color-primary;
  }
}
