@use "../../../../styles/variables.scss" as *;

.label {
  @include font-bold-xs();
  color: $color-gray-darken-2;
}

.submitButton {
  width: 9.2rem;
}
