@use "../../../../../styles/variables.scss" as *;

.form {
  border-radius: 5px;
  background: $color-gray-lighten-3;
  padding: 1.6rem 1.6rem 2rem 1.6rem;
  position: relative;
}
.supplement {
  font-weight: normal;
}
.unitDragIndicator {
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin: auto;
  fill: #abb0c1;
}
.contentDragIndicator {
  fill: #abb0c1;
  margin-right: 2rem;
}

.contentName {
  color: $color-black;
}
.contentIcon,
.closeIcon {
  fill: #abb0c1;
}

.closeIcon:hover {
  opacity: 50%;
}

.settingContentButton {
  width: 19rem;
}
