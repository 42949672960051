@use "../../styles/variables.scss" as *;

.buttonContainer {
  width: 16rem;
}

@include max-screen($breakpoint-mobile) {
  .buttonContainer {
    margin-top: 2rem;
    width: 100%;
  }
}

.iconAccount {
  min-width: 2.4rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  fill: #f2ba5f;
}

.lastColumn {
  padding: 0;
  padding-right: 3.2rem;
  .editLinkContainer {
    display: inline;
    margin-right: 2.4rem;
  }
}
