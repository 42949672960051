@use "../../../../styles/variables.scss" as *;

.root {
  border-bottom: 1px solid var(--bui-colors-gray-300);
  box-sizing: border-box;
}

.root:last-child {
  border-bottom: 0;
}

.comment {
  margin-top: 1.6rem;
  overflow-wrap: anywhere;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.4rem 1.6rem 0;
  font-weight: bold;

  .recordedAt {
    margin-left: 1.6rem;
  }
}

.body {
  padding: 0 1.6rem;
  margin-top: 1.6rem;
}

.name {
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;

  a {
    color: $color-black;
  }
}

.icon {
  fill: $color-icon-pink;
  width: 2.8rem;
  height: 2.8rem;
}

.studyRecordImages {
  display: flex;
  flex-wrap: wrap;
}

.studyRecordImage {
  width: 15rem;
  height: 15rem;
  background: $color-gray-lighten-2;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.5rem;
  margin-left: 1.6rem;
  margin-bottom: 1.6rem;

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}

/* アニメーションの定義 */
@keyframes focusAnimation {
  0% {
    background-color: #fdf9ed;
  }
  99.99% {
    // これがないとフェードしてしまう.パッと色を切り替えるために指定
    background-color: #fdf9ed;
  }
  100% {
    background-color: #fff;
  }
}
.focus {
  padding-top: 0.1rem; // bodyでpaddingされている分をハイライトさせるために指定
  background-color: #fdf9ed; // イレギュラーな色なのでデザイントークン化したい
  animation: focusAnimation 2.5s forwards; // アニメーション後は#fffを適用したままにする
}

@include max-screen($breakpoint-mobile) {
  // FIXME: しっかりSP対応は後回しなので、
  // 最低限じゃまにならない位置に表示してあります
  .body {
    position: static;
    margin-top: 1rem;
  }
}
