@use "../../../styles/variables.scss" as *;

.svg {
  fill: currentColor;
}

.toggle {
  display: inline-flex;
  align-items: center;
  color: $color-gray-darken-2;
  &:hover {
    color: $color-primary;
  }
  &.limited {
    color: $color-primary;
    &:hover {
      color: $color-gray-darken-2;
    }
  }
}

.selectedTagBox {
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.4rem;
  padding: 1.6rem;
  margin-bottom: 1.6rem;
  max-height: 12rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-right: 1rem;
  }
}

.filteredTagBox {
  margin-bottom: 1.6rem;
  height: 17rem;
  overflow: auto;
}

.tagBtn {
  line-height: 3.8rem;
}

.submitBtn {
  padding: 0 1.6rem;
}

.footer {
  display: flex;
  justify-content: center;
}

.notification {
  font-size: $font-regular-xs-font-size;
}
