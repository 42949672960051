@use "../../../styles/variables.scss" as *;

.drawerContainer {
  top: 0;
  right: 0;
  padding: 0;
  min-width: 50%;
  width: auto;
  height: 100%;
  margin: 0;
  position: fixed;
  background: #fff;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  z-index: $z-index-overlay + 1;
  box-shadow: 0 0 10px rgba(29, 40, 50, 0.1); // modalと同じ値
}
