@use "../../../styles/variables.scss" as *;

.root {
  color: $color-gray-darken-2;
  fill: $color-gray-darken-2;
  margin-top: 2.4rem;
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-start;
}

.attachments {
  flex-grow: 1;
}

.errors {
  width: 100%;
}

.error {
  color: $color-notification;
  font-size: 1.2rem;
  margin-top: 0.8rem;
}

.content {
  margin-right: 1rem;
  flex-grow: 1;
  position: relative;
}

.content__input {
  line-height: 1.6;
  resize: none;
}
.content__inputDisabled {
  resize: none;
}

.content__file {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  width: 100%;
  height: 4.5rem;
  padding: 1rem 1.6rem;
  background: #fff;
  &.fieldError {
    border: 1px solid $color-notification;
    background: #fbf0f0;
  }
}

.imageAttachmentsAttributes {
  display: none;
}

.filename {
  margin-bottom: 1.6rem;
  display: inline-block;
}

.imageIcon {
  position: absolute;
  right: 1.6rem;
  top: 0.8rem;
}

.zoomIcon {
  position: absolute;
  right: 5.6rem;
  top: 0.6rem;
}
.button {
  width: 10.8rem;
  height: 4.6rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: block;
  }

  .content {
    margin-right: 0;
  }

  .button {
    margin: 1.4rem auto;
  }
  .error {
    margin-bottom: 0.6rem;
  }
  .content__file.fieldError {
    border: 1px solid $color-gray-lighten-1;
  }
}
