@use "../../../styles/variables.scss" as *;

.root {
  overflow-x: scroll;
}

@media only print {
  // 印刷時のSafariにだけ適用
  // See: https://wojtek.im/journal/targeting-safari-with-css-media-query
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and
    (-webkit-appearance: none) {
    // NOTE: グラフを印刷幅ちょうどに表示するためにグラフのwidthを100%に指定すると、テーブル部分がはみ出して見切れてしまうので調整
    .root {
      overflow-x: visible;
    }
  }
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  color: $color-black-lighten-2;
}

.headRow {
  font-size: 1.2rem;

  & > th {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}

.row {
  border-top: 1px solid var(--bui-colors-gray-300);
  min-height: 6.4rem;
  box-sizing: border-box;

  & > td {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 9.3rem;
    box-sizing: border-box;

    &.title {
      padding-left: 0rem;
      padding-right: 0rem;
      text-align: left;
      font-size: 1.4rem;
      font-weight: bold;
      width: 18rem;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: normal;
    }

    &.checkboxCell {
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      min-width: 1rem;
    }

    &.colorLegend {
      padding-left: 0rem;
      padding-right: 1.4rem;
      min-width: 1rem;
    }
  }
}

.cell {
  white-space: nowrap;
  color: $color-black;
}

.heading {
  color: $color-black-lighten-2;
  padding: 1.2rem 0;
}

.tbody {
}

.column,
.sort {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.column {
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;
}

.sort {
  color: $color-primary;
  fill: $color-primary;
}

.headCheckbox {
  cursor: pointer;
}

.headCheckbox.editing {
  display: none;
}

.headCheckboxLabel {
  display: none;
}

.headCheckboxLabel.editing {
  display: inline-block;
  fill: #abb1c1;
  cursor: pointer;
}

.checkbox {
  cursor: pointer;
}

.checkbox.disabled {
  display: none;
}

.checkboxLabel {
  display: none;
}

.disabled .checkboxLabel {
  display: inline-block;
  fill: #abb1c1;
}

.cell.disabled,
.cell.unchecked {
  opacity: 0.5;
}

.level15 {
  background-color: $color-analytics-level-15;
}

.level14 {
  background-color: $color-analytics-level-14;
}

.level13 {
  background-color: $color-analytics-level-13;
}

.level12 {
  background-color: $color-analytics-level-12;
}

.level11 {
  background-color: $color-analytics-level-11;
}

.level00 {
  background-color: $color-analytics-level-00;
}

.level01 {
  background-color: $color-analytics-level-01;
}

.level02 {
  background-color: $color-analytics-level-02;
}

.level03 {
  background-color: $color-analytics-level-03;
}

.level04 {
  background-color: $color-analytics-level-04;
}

.level05 {
  background-color: $color-analytics-level-05;
}

.notfound {
  text-align: center;
}

@include max-screen($breakpoint-mobile) {
  .root {
    overflow-x: scroll;
  }

  .title {
    padding: 0.4rem;
  }
}
