@use "../../styles/variables.scss" as *;

.datePicker {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto 1fr;
}
.datePicker div:nth-child(1) {
  grid-column-start: 2;
}
.datePicker div:nth-child(2) {
  margin-left: auto;
}

.itemsContainer {
  margin: 2rem auto;
  max-width: 104.6rem;
  min-width: 40.8rem;
}

@include max-screen($breakpoint-mobile) {
  .datePicker {
    display: block;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
  }

  .itemsContainer {
    margin: 1.6rem 0;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
}
