@use "../../../styles/variables" as *;

.root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 22rem);
  min-height: 30rem;
  background: #fff;
  margin-top: 3.2rem;
  border: 1px solid $color-gray-lighten-1;
  overflow: hidden;
  border-radius: 0.8rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    height: calc(100vh - 4rem);
  }
}
