@use "../../../../src/styles/variables.scss" as *;

.table_wrapper {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  margin: 0;
  overflow-x: auto;
}

.table {
  width: 100%;
  text-align: center;
  color: $color-black;
  padding: 0 2rem;
  border-collapse: collapse;
  white-space: nowrap;

  & td {
    padding: 1.4rem;
  }
}

.header {
  height: 3.5rem;
  border-bottom: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  color: $color-black-lighten-2;
  font-size: $font-regular-xs-font-size;
}

.row {
  height: 5.5rem;
  border-bottom: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;

  &:last-child {
    border-bottom: 0;
  }
}

.col {
  width: 15%;
}

.last_col {
  text-align: end;
  vertical-align: middle;
  padding-right: 2rem;
}

.link {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
  display: inline-flex;
  align-items: center;
}

@include max-screen($breakpoint-mobile) {
  .table_wrapper {
    overflow-x: scroll;
  }
}
