@use "../../../../styles/variables.scss" as *;

$color-completed-text-color: $color-primary;

.root {
  margin-top: 2rem;
  background: #fff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
}
.plan {
  display: flex;
  border-bottom: 1px solid var(--bui-colors-gray-300);
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.rowBetween {
  @extend .row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.plan__left {
  width: 7.2rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  flex-shrink: 0;
}
.image {
  width: 5.8rem;
  height: 5.8rem;
  object-fit: contain;
}
.plan__right {
  flex-grow: 1;
}
.title {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 1.6rem 0;
  word-wrap: break-word;
}
.header {
  padding: 1rem;
}
.graph {
  padding: 1rem;
}
.period {
  margin-right: 3.2rem;
}
.comment {
}
.table {
  width: 100%;
  padding: 1.6rem 0 0 0;
  border-collapse: collapse;
}
.table__data {
  padding: 1.6rem;
  text-align: center;
}
.table__dataNarrow {
  width: 8rem;
}
.table__headerRow {
  font-size: $font-regular-xs-font-size;
}
.table__header {
  border-bottom: 1px solid var(--bui-colors-gray-300);
  padding-bottom: 0.6rem;
  color: $color-gray-darken-2;
  text-align: center;
}
.table__header__dates {
  display: flex;
  justify-content: space-between;
  padding: 0 1.6rem;
}
.completed {
  color: $color-completed-text-color;
}
.noData {
  margin: 1.6rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    overflow-x: scroll;
  }
}
