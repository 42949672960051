@use "../../../styles/variables.scss" as *;

.root {
  display: block;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 3.2rem;

  .iconReturnWrapper {
    display: none;
  }

  &:hover {
    color: $color-primary;

    .iconSchool {
      display: none;
    }

    .iconReturnWrapper {
      display: flex;
    }
  }
}

.opened {
  justify-content: space-between;
  align-items: center;
}

.active {
  .label {
    color: $color-primary;
    font-weight: bold;
  }
}

.iconWrapper {
  margin: 0 1.4rem;
  color: $color-black-lighten-2;
  display: flex;
  justify-content: center;
}

.iconReturnWrapper {
  width: 3.6rem;
  height: 3.6rem;
  background: $color-primary;
  display: none;
}

.iconReturn {
  fill: #fff;
  width: 2.4rem;
  height: 2.4rem;
}

.iconSchool {
  fill: $color-primary;
  width: 3.6rem;
  height: 3.6rem;
}

.label {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: $color-primary;

    .labelLeft,
    .labelRight {
      color: $color-primary;
    }
  }
}

.opened .label {
  display: flex;
}

.labelLeft {
  width: 100%;
  color: $color-black-lighten-1;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8rem;
}

.labelRight {
  display: none;
}

.schoolName,
.sectionName {
  overflow: hidden;
  text-overflow: ellipsis;
}

@include max-screen($breakpoint-mobile) {
  .root {
    justify-content: left;
    align-items: center;
  }

  .iconWrapper {
    color: $color-black-lighten-2;
    text-align: center;
    margin: 0 1.6rem 0 1.4rem;
  }

  .label {
    display: flex;
    width: auto;
  }

  .labelLeft {
    width: auto;
    text-decoration: underline;
  }

  .labelRight {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  .iconRight {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2rem;
    fill: $color-primary;
  }
  .iconReturnWrapper {
    display: flex;
  }
}
