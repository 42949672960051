@use "../../../styles/variables.scss" as *;
.FieldSet {
  display: block;
  margin: 0;
  padding: 0;
  display: flex;
  width: max-content;
  border-radius: 0.3rem;
  overflow: hidden;
  border: 1px solid var(--bui-colors-gray-300);
}

.InputRadio {
  border: none;
  border-width: 0;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
}
.Label {
  position: relative;
  cursor: pointer;
}
.Label:not(:first-of-type) {
  border-left: 1px solid var(--bui-colors-gray-300);
}

.DefaultColorTypeLabel {
  text-align: center;
  padding: 1.2rem 2rem;
  font-size: 1.4rem;
  color: $color-gray-darken-2;
  background-color: $color-gray-lighten-2;
}
.DefaultColorTypeLabel__checked {
  background-color: $color-primary;
  color: #fff;
}
.DefaultColorTypeLabel__disabled {
  color: $color-gray-darken-2;
  background-color: $color-gray-lighten-2;
  cursor: not-allowed;
}
