@use "../../../styles/variables.scss" as *;

/* 
 * TODO: スペース系もスタイルのユーティリティに寄せるようにする(helpers/styles/*)
 */
:root {
  --themeMainSectionPadding: 1.6rem;
}

.root {
  padding: var(--themeMainSectionPadding);
  border-radius: 0.8rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
}

.pageHeader {
  margin: 0;
  display: inline-flex;
  align-items: center;
  line-height: 2.7rem; // iconの有無で位置をズラさないようにiconのheightより大きく取る
  font-size: 1.8rem;
  font-weight: bold;
}

.icon {
  line-height: 0;
}

.helpUrl {
  margin-left: 0.5rem;
  svg {
    display: block;
  }
}
.scrollX {
  overflow-x: auto;
  // 親が横方向にスクロールバーが出ていると、子に対してのpadding-rightがなくなるブラウザの挙動のため、x方向のpaddingは個で持つ必要がある
  // https://web.archive.org/web/20170707053030/http://www.brunildo.org/test/overscrollback.html
  padding-left: 0;
  padding-right: 0;
}
