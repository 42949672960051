@use "../../../styles/variables.scss" as *;

.tableControl {
  position: relative;
}
.switchTabs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftContent {
  display: flex;
  justify-content: left;
}
.desktop {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.mobile {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .desktop {
    display: none;
  }
  .mobile {
    display: grid;
    grid-template-columns: 1fr;
  }
  .switchTabs {
    justify-content: center;
  }
  .leftContent {
    margin-top: 2.1rem;
  }
}
