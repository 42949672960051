@use "../../../styles/variables.scss" as *;

.dropdown {
  position: absolute;
  top: 2px;
  padding: 0.8rem 0 0;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-shadow: 0 2px 6px 0 rgba(29, 40, 50, 0.1);
  box-sizing: border-box;
  width: max-content;
  z-index: 20;
}

.dropdownRow {
  height: 3.8rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $color-gray-lighten-3;
  }
}

.dropdownSeparator {
  border-bottom: 1px solid $color-gray-lighten-1;
  margin: 1rem 0 0;
}

.dropdownCloseButton {
  height: 4.4rem;
  padding: 0;
  color: $color-primary;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
}

@include min-screen(calc($breakpoint-mobile + 1px)) {
  .backdrop {
    background: none;
  }
}

@include max-screen($breakpoint-mobile) {
  .dropdown {
    width: 100vw;
    position: fixed;
    box-sizing: border-box;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
  }
}
