@use "../../../styles/variables.scss" as *;

.label {
  cursor: pointer;
  &:hover {
    fill: $color-primary;
  }
}
.input {
  display: none;
}
