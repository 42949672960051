@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 3.2rem;
  padding: 2.4rem 3.2rem;
  background-color: #ffffff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  &:hover {
    opacity: 0.6;
    .icon {
      & > svg {
        fill: $color-primary;
      }
    }
    .application__name {
      color: $color-primary;
    }
  }
}

.application {
  display: flex;
  align-items: center;
}

.application__image {
  margin-bottom: auto;
  margin-right: 2.4rem;
  & > img {
    width: 4.4rem;
    height: 4.4rem;
  }
}

.application__info {
  display: block;
}

.application__summary {
  display: flex;
  margin-bottom: 1.2rem;
}

.application__name {
  @include font-bold-xl();
}

.application__description {
  display: block;
  @include font-regular-m();
}

.icon {
  margin: auto 0;
  margin-left: 3.2rem;
  & > svg {
    fill: $color-gray-lighten-1;
  }
}
