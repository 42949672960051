@use "../../../../../styles/variables" as *;

.root {
  margin-bottom: 1.6rem;
}

.display {
  padding: 1.6rem;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-wrap: anywhere;
}

.btn {
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1.8rem;
  padding: 1rem 4rem;
  margin: -0.8rem 0 -0.8rem 0.8rem;
  white-space: nowrap;
}

.inline {
  display: flex;

  div {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.formGroup {
  display: block;
  width: 100%;
  margin-bottom: 1.6rem;
}

.btnGroup {
  margin: 2rem auto;
  text-align: center;
}

.input__error {
  color: $color-notification;
  font-size: $font-regular-xs-font-size;
  margin: 0 0.8rem 0.8rem;
}
