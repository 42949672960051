@use "../../../../styles/variables.scss" as *;

.root {
  &:last-child > .link > .content {
    border-bottom: 0;
  }
}

.link {
  display: block;
  padding: 0 1.6rem;
  cursor: pointer;

  &:hover {
    background: #f3f7ff;
  }

  &.hovered {
    background: #f3f7ff;
  }
}

.content {
  padding: 1.6rem 0;
  border-bottom: 1px solid $color-gray-lighten-1;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 1.4rem;
  width: 2.8rem;
  height: 2.8rem;
}

.main {
  & > .link > .content,
  & > .link > .content > a {
    fill: $color-primary;
    color: $color-primary;
  }
}

.gray {
  & > .link {
    &.active {
      font-weight: bold;
    }

    &.disabled,
    &.disabled:hover {
      cursor: default;
      background: $color-gray-lighten-3;
    }
  }

  & > .link > .content,
  & > .link > .content > a {
    fill: #abb1c1;
    color: $color-black;
  }
}
