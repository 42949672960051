@use "../../../styles/variables.scss" as *;

.root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;

  &.highlighted {
    color: $color-primary;
    fill: $color-primary;
  }
}

@include max-screen($breakpoint-mobile) {
}
