@use "../../../../../styles/variables.scss" as *;

.content {
  border: 1px solid var(--bui-colors-gray-300);
  margin-top: 1rem;
  padding: 1.4rem;
  display: flex;
  align-items: flex-start;
  border-radius: 0.5rem;
  position: relative;
  background-color: #fff;
}

.imageContainer {
  height: 8.2rem;
  width: 8.2rem;
}

.image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.right {
  margin-left: 1.4rem;
}

.learningMaterial {
  display: flex;
  line-height: 1.4rem;
}

.title {
  overflow-wrap: anywhere;
  @include font-regular-m();
}

.externalLink {
  @include font-regular-s();
  margin-left: 2rem;
  display: flex;
  color: $color-primary;
  & > svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: $color-primary;
  }
}
.externalLink {
  @include font-regular-s();
  margin-left: 2rem;
  display: flex;
  color: $color-primary;
  & > svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: $color-primary;
  }
}

.recordedAt {
  margin-right: 0;
  margin-left: auto;
  white-space: nowrap;
  & > a {
    @include font-regular-xs();
    color: $color-black-lighten-2;
    line-height: 1.2rem;
  }
}

@include max-screen($breakpoint-mobile) {
  // FIXME: しっかりSP対応は後回しなので、
  // 最低限じゃまにならない位置に表示してあります
  .recordedAt {
    position: static;
    margin-top: 1rem;
  }
}
