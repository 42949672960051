@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.4rem 1.6rem;
}

.left {
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 1.6rem;
}

.name {
  @include font-regular-s();
}

.datetime {
  @include font-regular-xs();
  color: $color-black-lighten-2;
  margin-top: 0.8rem;
}

.content {
  @include font-regular-s();
  width: 26rem;
  margin-top: 0.2rem;
}

.iconNoticeCommentWrapper {
  width: 2.8rem;
  height: 2.8rem;
  background: #fff;
}

.iconNoticeComment {
  fill: $color-gray-darken-1;
  width: 2.8rem;
  height: 2.8rem;
}
