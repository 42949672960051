@use "../../../styles/variables.scss" as *;

$highlighted_background: #c2daff;
$highlighted_text_color: $color-primary-darken-1;

.container {
  display: inline-block;
}

// react-day-pickerのクラスを置き換える
.root {
  display: inline-block;
  background-color: white;
  box-shadow: 0 0.2rem 1rem 0 rgb(29 40 50 / 10%);
  border-radius: 0.6rem;
  padding: 2.4rem 2.2rem;
  font-weight: normal;
}
.button[aria-disabled="true"] {
  opacity: 0.25;
  pointer-events: auto;
}
.button:not([aria-disabled="true"]) {
  cursor: pointer;
}
.months {
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2.6rem;
}
.caption_label {
  margin: 0;
  font-size: 1.6rem;
  font-weight: bolder;
  color: #4f4e5d;
}
.caption {
  display: block;
  position: relative;
  padding: 0;
  margin: 0.8rem 0 1.5rem;
  text-align: center;
}
.nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  top: 0;

  .multiple_months .caption_start & {
    justify-content: flex-start;
  }
  .multiple_months .caption_end & {
    justify-content: flex-end;
  }
}
.table {
  border-spacing: 0 1.1rem;
}
.head_cell {
  text-align: center;
  font-size: 1.1rem;
  font-weight: normal;
  color: #757575;
  padding: 0 0 1rem;
}
.cell {
  border-radius: 0;
  width: 3.9rem;
  height: 3.8rem;
  text-align: center;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}
.day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  width: 3.9rem;
  min-width: 3.9rem;
  max-width: 3.9rem;
  height: 3.8rem;
  border-radius: 0;
  color: $color-black;

  &:hover {
    background-color: $highlighted_background;
    color: $highlighted_text_color;

    &.day_selected {
      background-color: $color-gray-lighten-3;
      color: $color-primary;
    }
  }
}
.day_selected {
  width: 100%;
  height: 100%;
  background-color: $color-primary;
  color: white;
}
.day_today {
  background-color: $highlighted_background;
  color: $highlighted_text_color;
}
.day_disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

// react-day-pickerのmodifiersに当てるクラス
.selected_date_range,
.range_middle {
  background-color: #e1edff;
  color: $color-black;
}
.selected_date_range:hover,
.selected_date_range_start,
.selected_date_range_end,
.range_start,
.range_end {
  background-color: $color-primary;
  color: white;
}
.highlighted {
  background-color: $color-gray-lighten-3;
  color: $color-black;
}
.highlighted_start {
  background-color: $highlighted_background;
  color: $highlighted_text_color;
}
