@use "../../../../styles/variables.scss" as *;

/* コースフォーム */
.thumbnail {
  text-align: center;
  cursor: pointer;
  padding-left: 2.4rem;
  display: block;
}
.thumbnail__input {
  display: none;
}
.thumbnail__empty,
.thumbnail__image {
  width: 8rem;
}
.thumbnail__empty {
  height: 10.8rem;
  background: $color-gray-lighten-3;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #abb0c1;
  border: 2px dotted #dde0e8;
  box-sizing: border-box;
  border-radius: 3px;
  &:hover {
    background: $color-gray-lighten-2;
  }
}
.thumbnail__label {
  color: $color-primary;
  font-size: 1rem;
}
.thumbnail:hover .thumbnail__label {
  text-decoration: underline;
}

.courseInfo {
  margin-left: 4rem;
  flex-grow: 1;
}
.duration__radio {
  margin-right: 4.4rem;
}
.duration__date {
  margin-right: 1rem;
}
.duration__time {
}
.duration__tilde {
  margin: 0 1rem;
}

.unitBtn {
  width: 100%;
  height: 4.4rem;
  background: $color-gray-lighten-3;
  font-weight: bold;
  fill: $color-black;
  color: $color-black;
  border-radius: 5px;
  &:hover {
    color: $color-primary;
    fill: $color-primary;
  }
}
.unitHeader {
  font-size: $font-regular-m-font-size;
  margin: 2rem 0 0 0;
}
@include max-screen($breakpoint-mobile) {
  .thumbnail {
    width: 6.4rem;
  }
  .thumbnail__empty,
  .thumbnail__image {
    width: 4.8rem;
  }
  .thumbnail__empty {
    height: 6.5rem;
  }
}

.submitButton {
  width: 12rem;
}
