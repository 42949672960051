@use "../../../styles/variables.scss" as *;

.root {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.left {
  width: auto;
  height: 100%;
  z-index: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.rightContainer {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 61.7rem;
  height: 100%;
  min-width: 0px;
}

@include max-screen($breakpoint-tablet-960) {
  .rightContainer:not(:has([data-main-layout_min-width-auto_tablet="false"])) {
    min-width: auto;
  }
}

.right {
  height: 100%;
  overflow-x: visible;
  overflow-y: scroll;
  z-index: 1;

  /* NOTE: iOSでスクロールができない問題への対処 ref: developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling */
  -webkit-overflow-scrolling: touch;
}

.navigation {
  height: 100%;
}

.header {
}

.main {
  padding: 0.8rem 3.2rem 3.2rem;
  box-sizing: border-box;
}

@include max-screen($breakpoint-mobile) {
  .root {
    width: 100vw;
  }

  .left {
    z-index: 4;
  }

  .right {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
  }

  .main {
    padding: 0.8rem 1.6rem;
  }
}

@media only print {
  .header,
  .navigation {
    display: none;
  }

  .left {
    z-index: 1;
  }

  .right {
    overflow-y: visible;
  }
}
