@use "../../../../styles/variables" as *;

.root {
}
.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.skills {
  margin-top: 1rem;
}
.noSkillsText {
  text-align: center;
  color: #4f4e5d;
}
.link {
  fill: #abb1c1;
}
.link__text {
  font-size: $font-regular-xs-font-size;
  font-weight: bold;
  margin-left: 0.4rem;
}

.text_button {
  padding-left: 0;
  padding-right: 0;
  fill: $color-black-lighten-2;
  color: $color-black-lighten-2;
  &:hover {
    fill: $color-primary;
    color: $color-primary;
  }
}
.name {
  color: $color-black;
  font-weight: bold;
}
.nameColumn {
  width: 32rem;
}
.descriptionColumn {
  min-width: 12rem;
}
.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.enabledColumn {
  width: 13.6rem;
}
.editColumn,
.deleteColumn {
  min-width: 8.8rem;
  width: 8.8rem;
}
.skillMaster {
  display: flex;
  margin-right: 1rem;

  & > img {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: auto;
    margin-right: 1.5rem;
    margin-bottom: auto;
  }

  & > .skillName {
    margin: auto 0;
  }
}

@include max-screen($breakpoint-tablet) {
  .skills {
    overflow-x: scroll;
  }
  .headColumn:first-child {
    min-width: 24rem;
  }
  .headColumn:nth-child(2) {
    min-width: 32rem;
  }
}
