@use "../../../src/styles/variables.scss" as *;

.linkContainer {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
  &:hover {
    color: $color-primary;
  }
  & > svg {
    fill: currentColor;
  }
}

.modal {
  padding: 0;
  height: auto;
  margin: 0 auto;
  position: absolute;
  background: #fff;
  overflow: auto;
  outline: none;
}

.formContainer {
  width: 100%;
}

.formRow {
  display: flex;
  width: 100%;
  margin-top: 1.6rem;
}

.formRowButton {
  display: flex;
  justify-content: center;
}

.inputGroup {
  display: block;
  width: 100%;
}

.inputTime {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-normal;
  color: $color-black;
  padding: 1.6rem;
  height: 4.6rem;
  width: 100%;
  box-sizing: border-box;
}

.inputTimeWrapper {
  margin-right: 0.8rem;
}

.inputDate {
  width: 100%;
  cursor: pointer;
}

.inputErrors {
  color: $color-notification;
  font-size: 1.2rem;
}

.btn {
  margin-top: 2rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem 5rem;
}
