@use "../../../styles/variables.scss" as *;

.button {
  height: 3.2rem;
  width: 3.2rem;
  border: 1px solid $color-gray-normal;
  border-radius: 0.4rem;
  background-color: #fff;
  padding: 0;
  line-height: 0;

  .disabled {
    cursor: not-allowed;
  }
}

.lineIcon {
  height: 56%;
  width: 56%;
  fill: $color-line-color;
}

.modal {
}

.render {
  visibility: hidden;
  position: absolute;
  width: 800px;
  height: 220px;
}

.canvas {
  width: 100%;
}

.messageLabelLine {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 1.4rem;

  .messageLabel {
    font-weight: bold;
  }

  .messageLength {
    color: $color-black-lighten-2;
  }
}

.message {
  resize: vertical;
}

.submitButton {
  display: flex;
  justify-content: center;
}
