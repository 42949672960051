@use "../../../../src/styles/variables.scss" as *;

.root {
  position: relative;
}
.field {
  display: flex;
  align-items: center;
  border: 1px solid $color-gray-normal;
  border-radius: 0.5rem;
  padding: 1rem 1.4rem;
  height: inherit;
  background-color: inherit;
}
.error {
  border-color: $color-notification;
}
.disabled {
  background: $color-gray-lighten-3;
}
.calendarContainer {
  position: relative;
}
.calendar__icon {
  margin-right: 1.4rem;
}
.noIconSpacer {
  content: ".";
  height: 2.4rem;
}
