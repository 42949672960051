@use "../../../../styles/variables.scss" as *;

.addButton {
  display: inline-flex;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
}

.addButtonSP {
  display: none;
  width: 2.8rem;
  padding: 0;
  justify-content: center;
  align-items: center;
}

@include max-screen($breakpoint-mobile) {
  .addButton {
    display: none;
  }

  .addButtonSP {
    display: inline-flex;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
