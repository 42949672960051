@use "../../../styles/variables.scss" as *;

.root {
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  background: #fff;
  color: $color-black-lighten-2;
  transition: width 0.2s ease-out;
  white-space: nowrap;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}

.opened,
.closed {
  width: 25.6rem;
}

.closed.collapsible {
  width: 6.4rem;
}

.mask {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .root {
    overflow: hidden;
    position: fixed;
    width: 86.4%;
    left: -86.4%;
    transition: left 0.4s ease-out;
    z-index: 4;
  }

  .opened {
    left: 0%;
  }
  .closed.collapsible {
    width: 86.4%;
  }

  .mask {
    display: block;
    width: 100%;
    height: 100%;
    background: #333;
    position: fixed;
    opacity: 0.5;
    z-index: 1;
  }

  .scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
