@use "../../../../styles/variables.scss" as *;

.nameLink {
  color: $color-black;
  font-weight: bold;
  text-decoration: underline;
}
.nameLink:hover {
  color: $color-black;
}
