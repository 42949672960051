@use "../../styles/variables.scss" as *;

.titleViewContainer {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 1.2rem;
  font-weight: bold;
}

.studentSelectContainer {
  margin-top: 1rem;
  padding-left: 0.2rem;
}
