@use "../../../styles/variables.scss" as *;

.studentTagFormTableWrapper {
  height: 32rem;
  overflow-y: auto;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  box-sizing: border-box;
}

.studentTagFormTable {
  border-bottom: 1px solid $color-gray-lighten-1;
  border-radius: 0;
}

.studentTagFormTable--bottomBorder_none {
  border-bottom: none;
}

.studentTagFormTableRow {
  padding-top: 0;
  padding-bottom: 0;
}

.studentTagFormTableCell {
  padding-top: 0;
  padding-bottom: 0;
  height: 3.6rem;
}

.studentTagFormTable__fullName {
  padding-left: 2rem;
}

.studentTagFormGreyText {
  color: $color-gray-darken-2;
}
