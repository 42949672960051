@use "../../../styles/variables.scss" as *;
.buttonCell {
  min-width: 16rem;
  font-size: 1.2rem;
  color: $color-black-lighten-2;
}
.tagTypeCell {
  min-width: 15rem;
}
.buttonsArea {
  > :not([hidden]) ~ :not([hidden]) {
    margin-left: 3rem;
  }
}
.tableRow {
  height: 6rem;
}
.tableRow > td {
  height: 6rem;
}
