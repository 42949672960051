@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
}
.title {
  font-weight: bold;
}
.name {
  color: $color-primary;
}
.follow {
  fill: #abb1c1;
}
.close {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .close {
    display: inline-block;
    fill: #abb1c1;
  }
}
