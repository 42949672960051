@use "../../styles/variables.scss" as *;

.root {
  margin-top: 2rem;
  background: #fff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.4rem 1.6rem 0;
  font-weight: bold;
}

.name {
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;

  a {
    color: $color-black;
  }
}

.icon {
  fill: $color-icon-pink;
  width: 2.8rem;
  height: 2.8rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin-top: 1rem;

    & + & {
      margin-top: 1.6rem;
    }
  }
}
