@use "../../../../../styles/variables" as *;

.root {
  margin-bottom: 1.6rem;
}

.display {
  padding: 1.6rem;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.display__label {
  display: inline-flex;
  align-items: center;
}
.display__label__bold {
  font-weight: bold;
  font-size: $font-regular-xl-font-size;
}
.display__label__email {
  font-size: $font-regular-m-font-size;
}
.display__label__icon {
  background: $color-primary;
  fill: #fff;
  padding: 0.4rem;
  margin-right: 1.6rem;
}
.icon {
  width: 1.8rem;
  height: 1.8rem;
}

.btn {
  color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 1.8rem;
  padding: 1rem 4rem;
  margin: -0.8rem 0;
}

.inline {
  display: flex;

  div {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.formGroup {
  display: block;
  width: 100%;
}

.btnGroup {
  margin: 2rem auto;
  text-align: center;
}

.input__error {
  color: $color-notification;
  font-size: $font-regular-xs-font-size;
  margin: 0 0.8rem 0.8rem;
}

.modal {
  top: 30%;
  padding: 0;
  width: 50%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
}
.field {
  margin-top: 1.6rem;
}
.submit {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.submit__btn {
  width: 14.2rem;
}

@include max-screen($breakpoint-mobile) {
  .modal {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    border-radius: 0;
  }
  .display__setting {
    display: block;
  }
  .setting__btn {
    margin: 1.5rem auto 0;
  }
}
