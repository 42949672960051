@use "../../../../styles/variables.scss" as *;

$color-completed-text-color: #f6c77b;
$color-progress-text-color: $color-primary;

.icon {
  fill: $color-icon-pink;
  display: block;
  margin: 0 auto;
  width: 2.8rem;
  height: 2.8rem;
}

.studentName {
  text-decoration: underline;
  color: #4f4e5d;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-top: 1rem;
  display: block;
  font-size: 1.4rem;
}

.job {
  margin-top: 1rem;
  color: $color-gray-darken-3;
  font-weight: 500;
  font-size: 1.4rem;
}

.table {
  background: #fff;
  fill: #a1a5ae;
  border-collapse: separate;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  border-spacing: 0;
  word-break: break-word;
  width: 100%;
  overflow: hidden;
  margin-top: 1.6rem;
  break-inside: avoid;
}

.tableHeader {
  border-bottom: 1px solid $color-gray-lighten-1;
  border-right: 1px solid $color-gray-lighten-1;
  font-weight: bold;
  color: $color-black-lighten-2;
  font-size: $font-regular-xs-font-size;
  text-align: center;
  word-break: keep-all;
  padding: 0 1rem;
  box-sizing: border-box;
  height: 3.2rem;

  &.student {
    width: 12rem;
    min-width: 12rem;
    vertical-align: top;
    padding: 2rem 1rem;
    border-bottom: none;
  }

  &.learningMaterial {
    width: 24.2rem;
    word-break: break-word;
  }

  &.number {
    width: 9.5rem;
  }

  &.percentage {
    width: 5.6rem;
  }

  &.date {
    border-right: none;
    color: $color-black;

    &.today {
      color: #f15360;
    }
  }
}

.graphHeader {
  border-right: none;
}
.graphHeader > :first-of-type {
  margin-right: 0.4rem;
}
.graphHeader > :last-of-type {
  margin-left: 0.4rem;
}

.tableCell {
  @include font-regular-s;

  border-bottom: 1px solid $color-gray-lighten-1;
  border-right: 1px solid $color-gray-lighten-1;
  padding: 1rem;
  box-sizing: border-box;
  word-break: keep-all;
  white-space: nowrap;
  text-align: center;
  color: #4f4e5d;
  font-weight: 500;
  text-align: center;

  .tableRow:last-child & {
    border-bottom: none;
  }

  &.noData {
    text-align: center;
    color: $color-gray-darken-3;
    border-right: none;
  }

  &.learningMaterial {
    word-break: break-word;
    white-space: normal;
    text-align: left;

    .learningMaterialContainer {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .learningMaterialImage {
        width: 6.8rem;
        height: 6.8rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        flex: 0 0 6.8rem;
      }

      .learningMaterialTitle {
        flex: 1 1 0;
        overflow: hidden;
        line-height: 1.8rem;
        max-height: 5.4rem;
        min-width: 2.8rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }

  &.number {
    white-space: normal;
  }

  &.dateRange {
    text-align: left;
  }

  &.percentage {
    color: $color-black;
    font-weight: bold;

    .high {
      color: $color-primary;
    }

    .seconds + .amount {
      margin-top: 2rem;
    }
  }

  &.chartCell {
    padding: 0 1rem;
    line-height: 0;
    border-right: none;
    .seconds + .amount {
      margin-top: 2rem;
    }
  }
}

.center {
  text-align: center;
}

.chartContainer {
  width: 100%;
  height: auto;
}

.chart {
  width: 100%;
  overflow: hidden;

  .background {
    fill: #fafafa;
  }

  .line {
    stroke: $color-gray-lighten-1;

    &.todayLine {
      stroke: #f15360;
      stroke-width: 2px;
    }
  }

  .bar {
    fill: #dde0e8;

    &.low {
      fill: #ff9897;
    }

    &.middle {
      fill: #f7c26d;
    }

    &.high {
      fill: #79a7f2;
    }
  }
}
