@use "../../styles/variables.scss" as *;

.message {
  height: calc(100vh - 24rem);
  min-height: 30rem;
}

@include max-screen($breakpoint-mobile) {
  .message {
    height: calc(100vh - 4rem);
  }
}
