@use "../../../styles/variables.scss" as *;
@use "sass:color";

.root {
  margin-top: 2rem;
}

.container {
  background: #fff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  text-align: center;
}

.heading {
  font-size: $font-regular-xl-font-size;
  margin-top: 3.2rem;
}

.description {
  font-size: $font-regular-m-font-size;
}

.link {
  color: $color-primary;
  font-weight: bold;
}

.skillMasters {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.skillMaster {
  box-sizing: border-box;
  height: 25.4rem;
  width: 27.2rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  margin-bottom: 4rem;
  &:hover {
    opacity: 0.6;
    transition: opacity 0.2s;
  }
  &:hover > &__heading {
    color: $color-primary;
  }
}
.skillMasterNew {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background: url("/images/icon_skill_new.png");
    display: inline-block;
    width: 5.4rem;
    height: 5.4rem;
    top: -1.6rem;
    left: -1.6rem;
    background-size: cover;
  }
}
.skillMaster__heading {
  margin-top: 3.2rem;
  font-size: $font-regular-xl-font-size;
  color: $color-black;
}
.skillMaster__image {
  width: 100%;
  margin-top: 2.4rem;

  & > img {
    height: 8.8rem;
    width: 8.8rem;
  }
}
.skillMaster__button {
  margin-top: 2.4rem;
  width: 17rem;
  // NOTE: スキル全体にopacityを設定しているので、Buttonもともとのopacityは打ち消しておく
  &:hover,
  &:disabled {
    color: rgba(
      color.channel($color-primary, "red"),
      color.channel($color-primary, "green"),
      color.channel($color-primary, "blue"),
      1
    );
    border-color: rgba(
      color.channel($color-primary, "red"),
      color.channel($color-primary, "green"),
      color.channel($color-primary, "blue"),
      1
    );
  }
}

@include max-screen($breakpoint-mobile) {
  .skillMasters {
    display: block;
  }
  .container {
    padding: 1.6rem;
  }
  .skillMaster {
    margin: 2rem auto;
    width: auto;
  }
}
