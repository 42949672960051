@use "../../../../styles/variables" as *;

.button {
  fill: $color-gray-darken-2;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
}

.qrcodeContainer {
  width: 30%;
  height: auto;
  min-width: 100px;
  margin: 0 auto 3.2rem;
}

.qrcode {
  width: 100% !important;
  height: 100% !important;
}

.printButton {
  margin: 3.2rem auto 2rem;
}

@media only print {
  .printBody {
    display: none;
  }

  .modal {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    border-radius: 0;
  }

  .body {
    height: 100%;
  }

  .printButton {
    display: none;
  }
}
