@use "../../../../styles/variables" as *;

.root {
  margin-top: 1.6rem;
}

.recipientsContainer {
  max-height: 25.2rem;
  overflow-y: scroll;
}

.recipients {
  width: 12.8rem;
  box-shadow: 0 0 10px 0 rgba(29, 40, 50, 0.1);
  border: 1px solid $color-gray-lighten-1;
  z-index: 3;
  background: #fff;
  padding: 0.8rem 1.6rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column-reverse;
}

.destinationLabel {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  margin-right: 0.6rem;
}

.destinationLabel__icon {
  margin-left: 0.8rem;
  fill: $color-gray-lighten-1;
}
.destinationLabel__link {
  color: $color-primary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.destinationLabelNoRecipients {
  opacity: 0.5;
}

.selector {
  margin-left: 1rem;
}

.control {
  display: flex;
  align-items: center;
}
.tagButton {
  margin-right: 0;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 0 0 1.4rem;
  }
}
