@use "../../../styles/variables.scss" as *;

.root {
  @include font-regular-xs();
  display: flex;
  line-height: 1.2rem;
  border-top: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: $color-primary;
  background: #fff;
  cursor: pointer;
  height: $mobile-header-menu-footer-height;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
