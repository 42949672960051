@use "../../../styles/variables.scss" as *;

.root {
  color: $color-black-lighten-2;
}
.link {
  width: 13rem;
  display: inline-flex;
  align-items: center;
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;
  &:hover {
    color: $color-primary;
    fill: $color-primary;
  }
}
.label {
  margin-left: 0.6rem;
}
