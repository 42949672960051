@use "../../../styles/variables.scss" as *;

.u-mt-1 {
  margin-top: 1rem;
}
.u-flex-align-center {
  display: flex;
  align-items: center;
}
.questionnaireFormStack {
  margin-top: 1.4rem;
}
.questionnaireFormStack > *:not(:first-child) {
  margin-top: 1rem;
}
.rootContainer {
  padding: 1rem 1.6rem;
  padding-bottom: 1.6rem;
}
.questionnaireType {
  flex: 1 0 22rem;
  max-width: 22rem;
  padding-right: 1rem;
  box-sizing: border-box;
}
.closeButton {
  fill: $color-gray-darken-1;
  padding-left: 1rem;
}
.addButtonContainer {
  margin-top: 1.6rem;
}
.addButtonIcon {
  fill: currentColor;
  font-size: 0;
}
.contentMargin {
  margin-left: 22rem;
}
.addQuestionnaireButtonContainer {
  margin-top: 1.6rem;
}
.questionnairesStack > *:not(:first-child) {
  margin-top: 2rem;
}
.questionnaireDisabled * {
  cursor: not-allowed;
}
.questionLayout {
  display: grid;
  grid-template-columns: 22rem 1fr;
}
.formatWrapper {
  grid-column: 1/2;
}
.questionWrapper {
  grid-column: 2/3;
  padding-left: 1rem;
}
.questionErrorWrapper {
  margin-left: 1.6rem;
  grid-column: 2/3;
}

.choiceLayout {
  display: grid;
  grid-template-columns: 1fr 3.2rem;
  margin-left: 23rem;
  align-items: center;
}
.removeChoiceButtonWrapper {
  justify-self: end;
}
