@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  flex-wrap: wrap;
}
.staffboard {
  width: 100%;
}
.charts {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.chartContainer {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
.chart {
  margin-top: 2rem;
  width: 50%;
  padding: 0 0.8rem;
  box-sizing: border-box;
}

@include max-screen($breakpoint-tablet-wide) {
  .charts {
    padding: 0 0.6rem;
  }
  .chartContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin-bottom: 2rem;
  }
  .charts {
    padding: 0;
  }
  .chart {
    width: 100%;
    padding-left: 0;
  }
}
