@use "../../../styles/variables.scss" as *;
@use "sass:color";

.switchButton {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  fill: $color-gray-darken-2;
  color: $color-gray-darken-2;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: normal;

  .toggle {
    margin-right: 0.4rem;
    line-height: 0;
  }

  &.checked {
    fill: $color-primary;
    color: $color-primary;
  }

  &.disabled {
    cursor: default;

    &.checked {
      fill: color.adjust($color-primary, $lightness: 20%);
      color: color.adjust($color-primary, $lightness: 20%);
    }
  }
}

.label {
  white-space: nowrap;
}
