@use "../../../styles/variables.scss" as *;
.main {
  margin-top: 2.4rem;
}
.backLink {
  margin-top: 2rem;
}
.form {
  margin-top: 2.4rem;
}
