@use "../../../styles/variables.scss" as *;
@use "sass:color";

.root {
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  color: #fff;
  fill: #fff;
  padding: 0 3.2rem;
  cursor: pointer;
  height: 4.5rem;
  white-space: nowrap;
  line-height: 1;
}

.root:disabled {
  background: #abb1c1;
  cursor: not-allowed;
}

@mixin color-scheme-base($primary_color) {
  background: #fff;
  border: 1px solid $primary_color;
  color: $primary_color;
  fill: $primary_color;
  font-weight: bold;
}

// 白地のテーマ
@mixin white-ground-theme($primary_color) {
  @include color-scheme-base($primary_color);

  &:hover,
  &:disabled {
    fill: rgba(
      color.channel($primary_color, "red"),
      color.channel($primary_color, "green"),
      color.channel($primary_color, "blue"),
      0.6
    );
    color: rgba(
      color.channel($primary_color, "red"),
      color.channel($primary_color, "green"),
      color.channel($primary_color, "blue"),
      0.6
    );
    fill: rgba(
      color.channel($primary_color, "red"),
      color.channel($primary_color, "green"),
      color.channel($primary_color, "blue"),
      0.6
    );
    border-color: rgba(
      color.channel($primary_color, "red"),
      color.channel($primary_color, "green"),
      color.channel($primary_color, "blue"),
      0.6
    );
  }
}
.whiteTheme {
  @include white-ground-theme($color-primary);
  &:disabled {
    background-color: #fff;
    color: $color-gray-darken-1;
    border: 1px solid $color-gray-darken-1;
    fill: $color-gray-darken-1;
  }
}

.grayTheme {
  @include white-ground-theme($color-gray-darken-3);
}
.redTheme {
  @include white-ground-theme($color-icon-pink);
}
.outlinedSuperLightGray {
  background-color: #fff;
  border: 1px solid $color-gray-lighten-1;
  color: $color-gray-darken-3;
  font-weight: bold;
  &:hover {
    background-color: $color-black-lighten-2;
    border: 1px solid $color-black-lighten-2;
    color: #fff;
  }
}
.block {
  width: 100%;
}
.xsmall {
  padding: 0.8rem 1.6rem;
  font-size: $font-regular-xs-font-size;
  height: 2.8rem;
}

.small {
  padding: 0 2rem;
  height: 3.6rem;
  & > svg {
    margin-left: -0.4rem;
  }
}

.medium {
  height: 4.5rem;
}

.large {
  height: 4.8rem;
}

.round {
  border-radius: 100rem;
}

.icon {
  margin-right: 1rem;
}

.root[data-variant="ghost"] {
  border: none;
  background-color: transparent;
  font-weight: bold;
  svg {
    fill: currentColor;
  }
  path {
    fill: currentColor;
  }
}
.root[data-variant="ghost"][data-color-type="black"] {
  color: $color-black;
}
.root[data-variant="ghost"][data-color-type="primary"] {
  color: $color-primary;
}
.root[data-variant="ghost"][data-color-type="grayDarken1"] {
  color: $color-gray-darken-1;
}
