@use "../../../styles/variables.scss" as *;

.root {
  background: #fff;
  color: $color-primary-lighten-1;
  display: flex;
  align-items: center;
}

.help {
  border-radius: 50%;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-left: 1.6rem;

  &:hover {
    background: #e1edff;
  }
}

.iconHelp {
  fill: $color-primary;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1.2rem 0 1.2rem 2.2rem;
  }

  .left {
    width: 25rem;
  }

  .right {
    display: flex;
    width: 11rem;
  }

  .help {
    display: none;
  }

  .headerAccount {
    margin: auto !important;
  }
}
