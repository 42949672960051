@use "../../../styles/variables.scss" as *;

.table {
  width: 100%;
  background: #fff;
  fill: #a1a5ae;
  border-collapse: separate;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  border-spacing: 0;
  word-break: break-word;
  // テーブル内の 非static要素へのz-indexが他に影響を与えないようにする
  isolation: isolate;
}
.noOutlineBorder {
  border: 0;
}
.headColumn {
  padding: 0.8rem 2rem;
  font-weight: bold;
  color: $color-black-lighten-2;
  font-size: $font-regular-xs-font-size;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
}
.width160 {
  min-width: 16rem;
}
.width240 {
  min-width: 24rem;
}

.column {
  border-top: 1px solid $color-gray-lighten-1;
  line-height: 1.3;
  height: 5.4rem;
  box-sizing: border-box;
  padding: 1.6rem;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}
.noWrap {
  white-space: nowrap;
}
.bold {
  font-weight: bold;
}
.sticky {
  z-index: 2;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $color-gray-lighten-1;
  background: #fff;
}
.firstRow_borderTop_none tr:first-child td {
  border-top: none;
}

.horizontalScroll {
  overflow-x: auto;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
}
.noBorder {
  border-top: none;
}

.evenColorTr:not(:nth-child(2n)) {
  background-color: $color-gray-lighten-3;
}
.noRowsColumn {
  padding: 2.4rem 0;
  &:first-child {
    padding-left: 0;
  }
}

@include max-screen($breakpoint-mobile) {
  .headColumn {
    min-width: 6.4rem;
  }
}

.DependantTable {
  table-layout: fixed;
}
