@use "../../../styles/variables.scss" as *;

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(124, 131, 150, 0.7);
}
