@use "../../../styles/variables.scss" as *;

.root {
  display: inline-flex;
  align-items: center;
  margin-left: -0.2rem;
}
.root,
.label {
  cursor: pointer;
}
.block {
  display: flex;
  width: 100%;
}
.label {
  display: flex;
  align-items: center;
}
.icon {
  fill: #fff;
  flex-shrink: 0;
}
.labelText {
  flex: 1;
}
.iconChecked {
  stroke: none;
  fill: $color-primary;
}
.iconDisabled {
  stroke: none;
  fill: $color-gray-darken-1;
}
.iconWithLabel {
  margin-right: 0.8rem;
  &.narrowMargin {
    margin-right: 0.2rem;
  }
}
.disabled,
.disabled > * {
  cursor: not-allowed;
}
.block__label {
  width: 100%;
}
.input {
  display: none;
}
:not(.iconChecked) {
  &.strokeColorLighten1 {
    stroke: $color-gray-lighten-1;
  }
  &.strokeColorNormal {
    stroke: $color-gray-normal;
  }
  &.strokeColorDarken {
    stroke: #abb0c1;
  }
}
