@use "../../../styles/variables.scss" as *;

.root {
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.rowBetween {
  justify-content: space-between;
}

.rowAlignEnd {
  justify-content: flex-end;
}

.rowErrors {
  flex-direction: column;
}

.field {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.seconds {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
  margin-right: 1.6rem;
}

.secondsField {
  @extend .field;
}

.unit {
  flex-shrink: 0;
  margin: 0 1.6rem;
}

.secondsField__unit {
  @extend .unit;
}

.amountField__unit {
  @extend .unit;
}

.amount {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.amountField {
  @extend .field;
}

.date {
  width: 35.5rem;
  margin-right: 1.6rem;
}

.comment {
  flex-grow: 2;
}

.learningMaterial {
  width: 100%;
}

.button {
  width: 10.8rem;
}

@include max-screen($breakpoint-mobile) {
  .comment {
    margin: 1.6rem 0;
  }
}
