@use "../../../styles/variables.scss" as *;

:global .fc {
  background-color: #fff;
}
.root {
  overflow-x: auto;
  width: 100%;
  height: 100%;
}

:global .fc-theme-standard {
  border-radius: 8px;
}

:global .weekcalendarSize {
  width: auto;
}

:global .fc-scrollgrid-section-liquid > td {
  border-radius: 0 0 8px 8px;
}

:global .fc-scrollgrid-section-header > td {
  border-radius: 0 8px 0 0;
}

:global .fc table.fc-scrollgrid {
  border-radius: 8px;
  --fc-border-color: var(--bui-colors-gray-400);
  --fc-neutral-bg-color: var(--bui-colors-gray-400);
}
:global .fc .fc-timegrid-divider {
  padding: 0;
}

:global .fc .fc-timegrid-slot-label-cushion {
  color: $color-black-lighten-2;
  font-size: 1.2rem;
  font-weight: normal;
  padding-top: 0.8rem;
}

:global .fc .fc-timegrid-axis-cushion {
  font-size: 1.2rem;
  color: $color-black-lighten-2;
}

:global .fc .fc-col-header-cell {
  color: $color-gray-darken-3;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

:global .fc-day-today {
  background-color: $color-gray-lighten-2 !important;
}

.timeAxis {
  padding-left: 14px;
}

@include max-screen($breakpoint-mobile) {
  :global .weekcalendarSize {
    width: 600px;
  }
}

@media only print {
  // 印刷時に収まるようにwidthを上書き
  // ライブラリによって動的に付けられるstyle属性に勝つために
  // 仕方なく!importantを利用しています
  :global .fc table {
    width: 90vw !important;
  }
}
