@use "../../../styles/variables.scss" as *;

.message {
  margin-top: 2rem;
  height: calc(100vh - 24rem);
  min-height: 30rem;
}

.newMessage {
  padding: 2rem 2.4rem;
  font-weight: bold;
}

.newMessage__button {
  width: 100%;
  height: 4.5rem;
  background: #fff;
  border: 1px solid $color-primary;
  color: $color-primary;
  fill: $color-primary;
  font-weight: bold;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 4.5rem;

  &:hover {
    background: $color-gray-lighten-3;
  }
}

@include max-screen($breakpoint-mobile) {
  .message {
    height: calc(100vh - 4rem);
  }
}
