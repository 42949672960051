.errorText {
  font-size: 1.4rem;
}
.sectionScheduleFormWrapper {
  padding-top: 0.4rem;
}

.submitButton {
  width: 14rem;
}
