@use "../../../styles/variables.scss" as *;
.tableWrapper {
  overflow-x: scroll;
}

@media only print {
  // 印刷時のSafariにだけ適用
  // See: https://wojtek.im/journal/targeting-safari-with-css-media-query
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and
    (-webkit-appearance: none) {
    // NOTE: グラフを印刷幅ちょうどに表示するためにグラフのwidthを100%に指定すると、テーブル部分がはみ出して見切れてしまうので調整
    .tableWrapper {
      overflow-x: visible;
    }
  }
}

.AnalyticsTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  color: $color-black-lighten-2;
}
.AnalyticsTable--fixed {
  table-layout: fixed;
}

.AnalyticsTableTh {
  font-size: 1.2rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.AnalyticsTableTr {
  min-height: 6.4rem;
  box-sizing: border-box;
}

.AnalyticsTableTd {
  border-top: 1px solid var(--bui-colors-gray-300);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 9.3rem;
  box-sizing: border-box;
  white-space: nowrap;
  color: $color-black;
}

.AnalyticsTableTd--whitespace-normal {
  white-space: normal;
}

.AnalyticsTableTd--disabled {
  opacity: 0.5;
}
.AnalyticsTableTd--noBorderTop {
  border-top: none;
}
.AnalyticsTableTd--positionRelative {
  position: relative;
}

.AnalyticsTableTd__Title {
  padding-left: 0rem;
  padding-right: 0rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: bold;
  width: 18rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
.AnalyticsTableTd--NoText {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  min-width: 1rem;
}
.AnalyticsTableTd__Colorlegend {
  padding-left: 0rem;
  padding-right: 1.4rem;
  min-width: 1rem;
}

.Checkbox {
  cursor: pointer;
}
.Checkbox--disabled {
  display: none;
}
.Checkbox--hidden {
  display: none;
}
.Checkbox__Label {
  display: inline-block;
  fill: #abb1c1;
}
.Checkbox__Label--hidden {
  display: none;
}
