@use "../../../styles/variables.scss" as *;

.root {
  display: none;

  &:focus {
    outline: 0;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: flex;
    width: 2.4rem;
    position: absolute;
    top: 2rem;
    left: 2rem;
    align-items: center;
    justify-content: center;
  }

  .opened {
    position: relative;
    top: 0;
    left: 0;
  }

  .iconClose {
    width: 2.4rem;
    height: 2.4rem;
    fill: $color-primary;
    display: none;
  }

  .opened .iconClose {
    display: block;
  }
}
