@use "../../../styles/variables.scss" as *;

.Summary {
  padding: 1.6rem 2rem 2rem;
}
.PieChartWrapper {
  margin-top: 1.6rem;
  height: 27.5rem;
}
.TableControl {
  display: grid;
  position: relative;
  margin-top: 2.4rem;
  grid-template-columns: 1fr auto 1fr;
}
.TableControl > div:last-child {
  margin-left: auto;
}

.TableControl__RightArea {
  display: flex;
  align-items: center;

  > * {
    margin-left: 1.6rem;
  }
}

.TableControl__LeftArea {
  display: flex;
  align-items: center;
}

.MainContentsWrapper {
  background-color: #fff;
  margin-top: 2rem;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
}
.TabWrapper {
  margin-top: -1rem;
}

.LearningMaterialTagName {
  display: flex;
}
.LearningMaterialTagName__Warning {
  fill: #f2ba5f;
  margin-left: 0.4rem;
}
.TableWrapper {
  border-top: 1px solid var(--bui-colors-gray-300);
}

@include max-screen($breakpoint-mobile) {
  .TableControl {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .TableControl > div:last-child {
    margin-left: 0;
  }
  .TableControl__RightArea {
    display: flex;
    align-items: center;
    > * {
      margin-left: 0;
    }
  }
}
