@use "../../../../styles/variables.scss" as *;

.root {
  padding: 0 1.6rem 2.4rem;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 0 1.6rem;
  }
}
