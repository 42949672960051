@use "../../../styles/variables.scss" as *;

.root {
  color: $color-black-lighten-2;
  width: 100%;
  height: 6.4rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  &:hover {
    background: $color-gray-lighten-3;
  }
  &:focus {
    outline: 0;
  }
}
.iconWrapper {
  width: 7.2rem;
  display: flex;
  justify-content: center;
}
.icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: $color-black-lighten-2;
}
.label {
  display: none;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
}
.iconOpen {
  @extend .icon;
  display: block;
}
.iconClose {
  @extend .icon;
  display: none;
}
.opened .iconOpen {
  display: none;
}
.opened .iconClose {
  display: block;
}
.opened .label {
  display: flex;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: none;
  }
}
