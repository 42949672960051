@use "../../../../../../styles/variables.scss" as *;

.root {
  height: 0;
  transition: 0.5s;
  overflow: hidden;
}

.visible {
  height: auto;
  display: block;
  background: $color-gray-lighten-3;
}
