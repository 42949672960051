@use "../../../styles/variables.scss" as *;

.ltHalf {
  --themeProgressBarTrackFillColor: #b9de73;
}
.gteqHalf {
  --themeProgressBarTrackFillColor: #80cebd;
}
.completed {
  --themeProgressBarTrackFillColor: #629af7;
}
