@use "../../../styles/variables.scss" as *;

.root {
  border-bottom: 1px solid $color-gray-normal;
  overflow-x: auto;
}
.root--border-none {
  border: none;
}
.tabs,
.scrollableTabs {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}
.tab,
.scrollableTab {
}
.tabLink {
  width: 16rem;
  height: 5.5rem;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
}
.tabLink:hover,
.activeLink {
  color: $color-black;
  border-bottom: 2px solid #5788e4;
  font-weight: bold;
}

@include max-screen($breakpoint-mobile) {
  // NOTE: iOS Safariでページ遷移時にhover状態が残ってしまう場合がある。
  // なので、SPの場合はhover時のスタイルをリセットしつつ、
  // hoverかつ.activeLinkの瞬間にはリセットされないようにしておく
  .tabLink:hover {
    color: $color-black-lighten-2;
    border-bottom: 0;
  }
  .tabLink:hover.activeLink {
    color: $color-black;
    border-bottom: 2px solid #5788e4;
  }
  .tabs {
    justify-content: space-around;
  }
  .tab {
    flex-grow: 1;
  }
  .tabLink {
    width: 100%;
  }
  .scrollableTabs {
    justify-content: flex-start;
    overflow-x: scroll;
  }
  .scrollableTab {
    flex-grow: 1;
    min-width: 160px;
  }
}

@media only print {
  .root {
    display: none;
  }
}
