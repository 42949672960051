@use "../../../styles/variables.scss" as *;

.form,
.input {
  width: 100%;
}
.root {
  width: 100%;
}

.input {
  height: 4.4rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.4rem;
  padding: 1rem 1.4rem;
  box-sizing: border-box;
}

.tagsWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-height: 17rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tagButton {
  display: inline-block;
  margin-top: 1rem;
  max-width: 100%;
  box-sizing: border-box;
  & > button {
    margin-right: 1rem;
  }
}
.newButton {
  display: flex;
  align-items: center;
  color: $color-primary;
  fill: $color-primary;
  font-weight: bold;
}

@include max-screen($breakpoint-mobile) {
  .tagsWrapper {
    max-height: 20rem;
  }
}
