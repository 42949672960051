@use "../../../styles/variables.scss" as *;

.root {
  padding: 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  height: 4.6rem;
  background-color: #fff;

  &:focus {
    outline: 0;
    border: 1px solid $color-primary;
  }

  &.fileInput {
    padding: 1.1rem 1.6rem;
  }
  &::placeholder {
    color: $color-black;
    opacity: 0.5;
  }
  &:disabled {
    opacity: 50%;
  }
}

.hasError,
.hasError:focus {
  border-color: $color-notification;
  background: #fbf0f0;
}

.isBlock {
  width: 100%;
}
.unit {
  display: inline-block;
  margin: 0 1rem;
  vertical-align: bottom;
}

.inputLeftIconGroup {
  position: relative;
  & > input {
    padding-left: 4.8rem;
  }
  & > svg {
    position: absolute;
    top: 1.2rem;
    left: 1.4rem;
  }
}
