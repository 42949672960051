.breadcrumbsWrapper {
  margin-top: 1.6rem;
}
.breadcrumbLastLabel {
  margin-left: 1.2rem;
}
.breadcrumbLinkLabel {
  margin-right: 1.2rem;
}
.main {
  margin-top: 2.4rem;
}
.mainContentWrapper {
  min-width: 80rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
