@use "../../../styles/variables.scss" as *;

.notice {
  font-size: 1.2rem;
  color: #656e98;
  margin-top: 2.4rem;
  display: inline-flex;
  align-items: center;
  line-height: 2.7rem;
}

.attentionIcon {
  fill: $color-gray-darken-2;
  margin-right: 0.5rem;
}

@include max-screen($breakpoint-mobile) {
  .notice {
    margin-top: 0rem;
    display: block;
  }
}

.submitButton {
  width: 12rem;
}
