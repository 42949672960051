@use "../../../styles/variables.scss" as *;

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.filter {
  flex-grow: 1;
}

.svg {
  fill: currentColor;
  margin-right: 0.6rem;
  // SVGに含まれる空白のせいで余白を取りすぎに見えるので調整
  margin-left: -1rem;
}
.button:not(:first-of-type) {
  margin-left: 2rem;
}

@include max-screen($breakpoint-mobile) {
  .navigation {
    flex-wrap: wrap;
  }

  .filter {
    width: 100%;
  }

  .button {
    margin: 0 auto 2rem;
  }
}
