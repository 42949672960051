@use "../../../../styles/variables.scss" as *;
.studentsTable__nameColumn:first-child {
  padding-left: 3.2rem;
}
.studentsTable__icon {
  fill: $color-icon-pink;
}
.studentsTable__fullName {
  padding-left: 2rem;
}
.studentsTable__loader {
  border-top: 1px solid $color-gray-lighten-1;
}
.studentsTable__caution {
  text-align: center;
  font-size: 1.4rem;
}
/* TODO: 付焼き刃的な対応になってしまってるので、どこかのタイミングで Tableコンポーネントを見直す */
.studentsTable {
  border-bottom: 1px solid $color-gray-lighten-1;
  border-radius: 0;
  margin-bottom: 1.6rem;
}
table.studentTable--bottomBorder_none {
  border-bottom: none;
}

.scrollView__scroller {
  /* 親からはみ出ないようにする */
  box-sizing: border-box;
  height: 42.8rem;
  overflow-y: scroll;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
}
