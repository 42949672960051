@use "../../../styles/variables.scss" as *;
@use "sass:color";

// 白地
@mixin applyThemeColorToBorder($theme-color) {
  background: #fff;
  border: 1px solid $theme-color;
  color: $theme-color;
  fill: $theme-color;
  &:hover {
    background: $theme-color;
    color: #fff;
    fill: #fff;
    & > .icon {
      fill: #fff;
    }
  }
}

// プライマリカラー地
@mixin applyThemeColorToBackground($theme-color) {
  background: $theme-color;
  font-weight: normal;
  color: #fff;
  & > .icon {
    fill: #fff;
  }
  &:hover {
    background: $theme-color;
    color: #fff;
    border-color: transparent;
  }
}

.root {
  border-radius: 1.4rem;
  display: inline-flex;
  align-items: center;
  padding: 0 1.6rem;
  line-height: 1;
  height: 2.8rem;
  font-size: $font-regular-xs-font-size;
  cursor: pointer;
  transition: 0.2s;
  font-weight: bold;
  max-width: 100%;
  box-sizing: border-box;

  &:active,
  &:focus {
    outline: 0;
  }
}

.withIcon {
  padding: 0 0.8rem 0 1.6rem;
}

.label {
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.defaultColor {
  @include applyThemeColorToBorder($color-primary);
}

.mainColor {
  @include applyThemeColorToBackground($color-primary);
}

.whiteColor {
  @include applyThemeColorToBorder($color-primary);

  &:hover {
    background: #e1edff;
    color: $color-primary;
    & > .icon {
      fill: $color-primary;
    }
  }
}

.grayColor {
  background: $color-gray-lighten-2;
  color: $color-black;
  font-weight: normal;
  &:enabled:hover {
    @include applyThemeColorToBackground($color-primary);
  }
  &:disabled:hover {
    background: $color-gray-lighten-2;
    color: $color-black;
    cursor: default;
  }
}

.lightGrayColor {
  background: $color-gray-lighten-3;
  color: $color-black;
  font-weight: normal;
  cursor: default;

  .icon {
    background: $color-primary;
    fill: #fff;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.8rem;
    cursor: pointer;

    &:hover {
      background: color.adjust($color-primary, $lightness: -10%);
    }
  }
}

.greenColor {
  background: #88c931;
  color: #ffffff;
  fill: #ffffff;
  font-weight: normal;
  &:disabled:hover {
    cursor: default;
  }
}

.smarttagColor {
  background: #fdecec;
  font-weight: normal;
  color: $color-black;
  &:enabled:hover {
    background: $color-icon-pink;
    color: #fff;
  }
  &:disabled:hover {
    background: #fdecec;
    color: $color-black;
    cursor: default;
  }
}
.smarttagWhiteColor {
  @include applyThemeColorToBorder($color-icon-pink);
}
.smarttagActiveColor {
  @include applyThemeColorToBackground($color-icon-pink);
}
.sectionTagColor {
  background: #fff;
  border: 1px solid $color-primary;
  color: $color-primary;
  fill: $color-primary;
  &:disabled:hover {
    cursor: default;
  }
}

// light blue
.lightBlueSolid {
  background: $color-light-blue;
  color: #fff;
  &:hover {
    background: $color-light-blue-lighten-1;
  }
}
.lightBlueOutline {
  @include applyThemeColorToBorder($color-light-blue);
}

// light green
.lightGreenSolid {
  color: #fff;
  background: $color-light-green;
  &:hover {
    background: $color-light-green-lighten-1;
  }
}
.lightGreenOutline {
  @include applyThemeColorToBorder($color-light-green);
}
