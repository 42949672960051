@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 2rem 1.6rem;
  box-sizing: border-box;
  height: 2.4rem;
}

.toggleButton {
  top: -1rem;
  right: 2rem;
}
