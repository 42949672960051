@use "../../../../styles/variables" as *;

.list {
  margin-bottom: 3.2rem;
}

.list__row {
  display: flex;
  align-items: center;
  padding: 2rem;
}

.list__checkbox {
}

.list__name {
  margin: 0 2.4rem;
  cursor: pointer;
}

.submit {
  display: flex;
  justify-content: center;
}

.notfound {
  @include font-bold-m();
  color: #f03d3d;
}

.notfound__text {
  margin: 1.6rem 0;
}
