@use "../../../../styles/variables" as *;

.root {
  display: flex;
  align-items: center;
}

.name {
  margin-right: 0.8rem;
  max-width: 27rem;
}

.button {
  margin-left: 0.8rem;
  word-break: keep-all;
}
