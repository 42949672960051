@use "../../../styles/variables.scss" as *;

.root {
  width: 16.8rem;
  height: 23.8rem;
  margin-right: 1.6rem;
  margin-bottom: 3.2rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
}
.imageContainer {
  padding: 3rem 0;
  text-align: center;
  & > img {
    height: 10rem;
    width: 7.2rem;
    object-fit: contain;
  }
}
.title {
  margin: 0 1.6rem 3rem 1.6rem;
  display: -webkit-box;
  word-break: break-word;
  overflow: hidden;
  /* NOTE: onにするとautoprefixerに消されてしまうので */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
