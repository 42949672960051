@use "../../../styles/variables.scss" as *;

.courseResult__name {
  font-weight: bold;
  color: $color-primary;
  &:hover {
    color: $color-black-lighten-2;
  }
}

.BtnPreview {
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.3rem;
  &:hover {
    border: 1px solid $color-primary;
  }
}

.BtnPreview__Link {
  &:visited {
    fill: $color-black-lighten-2;
  }
  &:hover {
    fill: $color-primary;
  }
}
