@use "../../../styles/variables.scss" as *;

.tableWrapper {
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  background-color: #fff;
  width: 100%;

  & > table {
    border-collapse: collapse;
    width: 100%;

    & > thead > tr {
      height: 3.5rem;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid $color-gray-lighten-1;

      & > th {
        padding: 0 1.6rem;
        white-space: nowrap;
      }
    }

    & > tbody > tr {
      width: 100%;
      border-top: 1px solid $color-gray-lighten-1;

      &:first-child {
        border: 0;
      }

      & > td {
        padding: 1.6rem;
      }
    }
  }
}

.template {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-black-lighten-2;
  white-space: nowrap;
}

.svg {
  fill: currentColor;
}

@include max-screen($breakpoint-mobile) {
  .tableWrapper {
    overflow-x: scroll;
  }
}
