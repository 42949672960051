@use "../../../styles/variables.scss" as *;

.root {
  color: $color-gray-darken-2;
  fill: $color-gray-darken-2;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  background: #fff;
  padding: 1.6rem;
}

.field {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  padding: 1.6rem;
}

.fieldError {
  border: 1px solid $color-notification;
}

.form {
}

.header {
  display: flex;
}

.body {
  width: 100%;
}

.attachments {
  flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.8rem;
}

.footer__icon {
  display: inline-block;
  margin-right: 3.2rem;
}

.error {
  color: $color-notification;
  font-size: 1.2rem;
}

.staffName {
  width: 100%;
}

.staffName__error {
  @extend .error;
  display: inline-block;
  margin-left: 1.6rem;
}

.template {
}

.comment {
  margin-top: 1.4rem;
}

.comment__input {
  @extend .field;
  width: 100%;
  height: 10.5rem;
}

.comment__error {
  @extend .error;
  display: inline-block;
  margin-left: 1.6rem;
}

.attachmentsAttributes {
  display: none;
}

.imageAttachmentsAttributes {
  display: none;
}

.apiErrors {
  flex-grow: 1;
}

.apiErrors__error {
  @extend .error;
  margin-top: 0.8rem;
}

.filename {
  margin-bottom: 1.6rem;
  display: inline-block;
}

@include max-screen($breakpoint-mobile) {
}
