@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid $color-gray-lighten-1;
  &:hover {
    background: #edf0fa;
  }
  &:last-child {
    border-bottom: 0;
  }
}
.highlighted {
  background: #f3f7ff;
}
