@use "./../../../styles/variables.scss" as *;

.ContentResultTable {
  &__table {
    background: $color-gray-lighten-3;
    border: 1px solid $color-gray-lighten-1;
    border-radius: 0.8rem;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    max-height: calc(100vh - 32rem);
    overflow: auto;
    white-space: nowrap;
    width: 100%;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    color: $color-primary;
    fill: $color-primary;
    &:hover {
      color: $color-black-lighten-2;
      fill: $color-black-lighten-2;
    }
  }
  &__icon {
    flex-shrink: 0;
  }
}

.ContentResultTableHeader {
  &__row {
    height: 3.2rem;
    &--sticky1 {
      position: sticky;
      z-index: 2;
      top: 0;
    }
    &--sticky2 {
      position: sticky;
      z-index: 2;
      top: 3.2rem;
    }
  }
  &__title {
    @include font-bold-xs();
    border-right: 1px solid var(--bui-colors-gray-300);
    text-align: center;
    min-width: 20rem;
    max-width: 20rem;
    background: #fff;
    white-space: normal;
    &--sticky {
      position: sticky;
      z-index: 1;
      left: 0;
    }
  }
  &__categories {
    @include font-bold-xs();
    border-right: 1px solid var(--bui-colors-gray-300);
    text-align: center;
    background: $color-gray-lighten-3;
    &--sticky {
      position: sticky;
      z-index: 1;
      left: 0;
    }
    &--noborder {
      border-right: 0;
    }
  }
}

.ContentResultTableRow {
  &__row {
    height: 4.2rem;
  }
  &__cell {
    @include font-regular-m();
    text-align: center;
    border-right: 1px solid var(--bui-colors-gray-300);
    border-bottom: 1px solid var(--bui-colors-gray-300);
    background: #fff;
    &--sticky {
      position: sticky;
      z-index: 1;
      left: 0;
    }
    &--noborder {
      border-right: 0;
    }
  }
}

.ScoreText {
  &--green {
    color: #78b12b;
  }
  &--red {
    color: $color-notification;
  }
}

@include max-screen($breakpoint-mobile) {
  .ContentResultTableHeader__row--sticky1,
  .ContentResultTableHeader__row--sticky2,
  .ContentResultTableHeader__title--sticky,
  .ContentResultTableHeader__categories--sticky,
  .ContentResultTableRow__cell--sticky {
    position: relative;
    top: 0;
    left: 0;
  }
}
