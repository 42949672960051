@use "../../../styles/variables.scss" as *;

.root {
  margin-bottom: 1.6rem;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.dateRange {
  grid-column: 2 / 3;
}
.menus {
  display: inline-flex;
  align-items: center;
}
.leftMenu {
  grid-column: 1 / 2;
  display: flex;
}
.leftMenu__tab {
  padding-left: 1.6rem;
  display: none;
}

.rightMenu {
  grid-column: 3 / 4;
  justify-content: flex-end;
}
.menus__btn {
  height: 3.2rem;
}
.menus__addbtn {
  margin-right: 1.6rem;
  width: 3.6rem;
  padding: 0;
}
.menus__todaybtn {
  border: 1px solid #dde0e8;
  color: $color-gray-darken-3;
}

@include max-screen($breakpoint-mobile) {
  .header {
    grid-template-columns: 1fr 1fr;
    /* TODO: DateRangePickerとSingleDatePickerで内部のマージンの取り方が違うので
    外から帳尻合わせしてる。今直すと影響度が全体になってしまうので、KAIZENとしてコンポーネントを諸々整理したらこの帳尻合わせをやめる*/
    grid-template-rows: 3.8rem auto;
    gap: 1.4rem 0;
    margin-bottom: 0;
    button {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .dateRange {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leftMenu {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  .rightMenu {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  .header__spmenu {
    display: flex;
    justify-content: space-between;
  }
  .menus__addbtn {
    margin-right: 0;
  }
  .leftMenu__tab {
    display: block;
  }
}

@media only print {
  .root {
    display: none;
  }
}
