@use "../../../styles/variables.scss" as *;

.root {
  display: inline-flex;
  width: 3.6rem;
  height: 3.2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dde0e8;
  border-radius: 0.4rem;
  color: $color-gray-darken-3;
  background-color: #fff;
  &:hover {
    border: 1px solid $color-primary;
    background-color: $color-gray-lighten-3;
    .icon {
      fill: $color-primary;
    }
  }
  &:active {
    border: 1px solid $color-primary;
    background-color: #eff1f9;
    .icon {
      fill: $color-primary;
    }
  }
}

.icon {
  fill: $color-gray-darken-3;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: none;
  }
}

@media only print {
  .root {
    display: none;
  }
}
