@use "../../../styles/variables.scss" as *;

.main {
  text-align: center;
}
.message {
  margin-top: 3.2rem;
}
.buttonContainer {
  margin-top: 3.2rem;
}
.children {
  margin: 3.2rem auto 0 auto;
  width: 68rem;
}
.button {
  width: 22rem;
}

@include max-screen($breakpoint-tablet) {
  .children {
    width: 90%;
  }
}
@include max-screen($breakpoint-mobile) {
  .children {
    width: 90%;
  }
}
