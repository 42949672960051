.mainSectionWrapper {
  margin-top: 1.6rem;
  text-align: center;
}

.description {
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
}

.modalContainer {
  max-width: 59.6rem;
}
