@use "../../../styles/variables.scss" as *;

.PopoverContent {
  z-index: $z-index-overlay + 10; // モーダルの前に表示することがあるためモーダルの値(40)より大きくしておく
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
