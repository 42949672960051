@use "../../styles/variables.scss" as *;

.wrapper {
  width: 100%;
  overflow: auto;
}
.wrapper th {
  box-sizing: content-box;
}
.studyPlanValues {
  display: flex;
  align-items: stretch;
}
.sectionScheduleColorDot > *:first-of-type {
  flex-shrink: 0;
}
.studyPlanElement:first-of-type {
  flex-shrink: 0;
}
.studyPlanElement:not(:first-of-type) {
  margin-top: 0.6rem;
}
.studyPlanValues__icon {
  z-index: 1;
  flex-shrink: 0;
}
.studyPlanValues__text {
  margin-left: 1rem;
  padding-right: 1rem;
  background-color: $color-gray-lighten-2;
  margin-left: -1.4rem;
  padding-left: 2.4rem;
  padding-right: 1rem;
  border-radius: 0 99999px 99999px 0;
  display: flex;
  align-items: center;
}
.studyPlanValues__text > :not(:first-of-type) {
  margin-left: 0.6rem;
}

.resultTable {
  table-layout: fixed;
  min-width: 800px;
}
.scheduleTimeWidth {
  width: 15%;
}
.summaryWidth {
  width: 20%;
}
.targetNumWidth {
  width: 7%;
}
.attendeesNumWidth {
  width: 10%;
}
.clasroomWidth {
  width: 15%;
}
.teacherWidth {
  width: 15%;
}
.lectureWidth {
  width: 18%;
}
.colorDotColumn {
  padding-right: 0rem;
}
.SummaryLinkWrapper {
  margin-left: 1.6rem;
}
.noResults {
  margin-top: 3.2rem;
}
.modal {
  max-width: 48rem;
}
.backBtn {
  cursor: pointer;
  color: $color-gray-darken-2;
  fill: $color-gray-darken-2;
  font-weight: normal;

  &:hover {
    color: $color-primary;
    fill: $color-primary;
  }
}
.backArrowIcon {
  width: 2rem;
}

@include max-screen($breakpoint-mobile) {
  .wrapper {
    overflow: revert;
  }
  .modal {
    max-width: revert;
  }
}
@media print {
  .wrapper {
    overflow: revert;
  }
}
