@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  flex-wrap: nowrap;
}

.inputContainer {
  width: 14.2rem;
  box-sizing: border-box;

  & + & {
    margin-left: 0.8rem;
  }
}

.icon {
  border-radius: 50%;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

.iconSearch {
  fill: $color-primary-lighten-1;
  border-radius: 50%;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;

  &:hover {
    text-decoration: underline;

    & > .icon {
      background: $color-primary-lighten-2;
    }
  }
}

.label__text {
  margin-left: 0.8rem;
  word-break: keep-all;
}

.dropdownList {
  left: 0;
  top: 4.4rem;
  width: 42.4rem;
}

.dropdownBody {
  width: 42.4rem;
  padding: 0.8rem;
  background: #fff;
  display: flex;
  box-shadow: 0 0.2rem 1rem 0 rgba(29, 40, 50, 0.1);
  border-radius: 0.6rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
}

.buttonContainer {
  margin-left: 1rem;
  width: 10.4rem;
}

@include max-screen($breakpoint-mobile) {
  .input {
    padding: 0 1rem;
    width: 10rem;
  }

  .dropdownList {
    left: -1.6rem;
    top: 4.8rem;
    width: 100vw;
  }

  .dropdownBody {
    width: 100vw;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $color-gray-lighten-1;
  }
}
