@use "../../../../../styles/variables.scss" as *;

.modal {
  width: 98rem;
  max-width: 90%;
}

.loader__container {
  height: calc(
    42.8rem + 1px
  ); // NOTE: .tableWrapperのborder 1px分を加算してガタつかないように対処
}

.searchForm__label {
  @include font-regular-s();
  margin: 0.8rem 0;
}

.searchForm__bottom {
  margin-bottom: 1.6rem;
}

.searchForm__checkboxLabel {
  margin-right: 1.6rem;
}

.tableWrapper {
  height: 42.8rem;
  overflow-y: scroll;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
}
.headColumn {
  min-width: 0;
  border-bottom: 1px solid $color-gray-lighten-1;
}

.checkboxColumn {
  width: 2.4rem;
  min-width: 2.4rem;
  padding: 0;
}

.row {
  td:not(.emptyTableRow) {
    border-top: 0;
    border-bottom: 1px solid $color-gray-lighten-1;
  }
  td.emptyTableRow {
    border-top: 0;
    border-bottom: 0;
  }
}

.contentName {
  color: #505a7c;
}

.contentName__durationValue {
  @include font-regular-s();
}

@include max-screen($breakpoint-mobile) {
  .modal {
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
  .tableWrapper {
    height: 48rem;
  }
}

.submitButton {
  width: 10.4rem;
}

.addContentButton {
  width: 14.8rem;
}
