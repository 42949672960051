.root {
  max-width: 68rem;
  & > ul {
    padding-left: 2rem;

    & > li {
      list-style: circle;
    }
  }
}
