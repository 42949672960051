@use "../../../styles/variables.scss" as *;

.root {
  width: 29rem;
}
.recipients {
  min-height: 14rem;
  max-height: 40rem;
  overflow-y: scroll;
  padding: 1.2rem 0.9rem 1.6rem 1.1rem;
  box-sizing: border-box;
}
.recipient {
  border-bottom: 1px solid var(--bui-colors-gray-300);
  padding: 0.4rem 0;
}
.recipient:first-child {
  padding-top: 0;
}
.recipients__btn {
  margin-top: 1.4rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    width: 28rem;
    margin: auto;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 1rem 0 rgba(29, 40, 50, 0.1);
  }
}
