@use "../../../styles/variables.scss" as *;

.AttendeesMenu {
  background-color: $color-gray-lighten-3;
  padding: 1.4rem 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1.6rem;
}

.BatchActionMenu {
  top: 3.4rem;
  left: 0rem;
  background-color: #fff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 4px;
  padding: 0.4rem 0;
  width: 26rem;
}
.BatchActionButtonIconWrapper {
  margin-right: -0.6rem;
  > svg {
    display: block;
  }
}
.BatchActionMenu > ul {
  width: 100%;
}

.BatchActionMenuItem {
  padding: 0.8rem 1.4rem;
  cursor: pointer;
  display: block;
}
.BatchActionMenuItem:hover {
  background-color: #f3f7ff;
  color: $color-primary;
}

@include max-screen($breakpoint-mobile) {
  .AttendeesMenu {
    grid-template-columns: revert;
    gap: 1.4rem;
  }
}
