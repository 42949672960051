@use "../../../styles/variables" as *;

.root {
  height: 100%;
  overflow-y: scroll;
}

.no_student {
  margin-top: 4rem;
  text-align: center;
}

@include max-screen($breakpoint-mobile) {
}
