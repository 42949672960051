@use "../../../../styles/variables.scss" as *;

.root {
  margin-top: 1.2rem;
}
.backLink {
  margin: 2rem 0;
}
// CourseSummary
.course__thumbnail {
  padding: 0 1.2rem 0 1.2rem;
  img {
    width: 8rem;
    height: 8rem;
    object-fit: contain;
  }
}
.course__name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #505a7c;
  margin-bottom: 1.6rem;
}
.course__description {
  @include font-regular-xs();
  color: $color-gray-darken-3;
  word-break: break-all;
}
.btn__group {
  display: flex;
  justify-content: right;
}
.tag__prefix {
  @include font-bold-m();
  color: #5a648a;
  margin-right: 1rem;
}
.tag__name--undefined {
  color: #5a648a;
}
.tag__btn {
  margin-left: 2rem;
}

// StudentList
.table {
  max-height: 43rem;
  overflow-y: scroll;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  tr:first-child {
    td {
      border-top: 0;
    }
  }
}
.table__row--blank {
  color: $color-black-lighten-1;
  opacity: 0.5;
}
.name__icon {
  fill: $color-icon-pink;
  padding: 0 1.6rem;
  min-width: 2.4rem;
}
.name {
  color: $color-black;
  word-break: keep-all;
  white-space: normal;
  &:hover {
    color: $color-primary;
  }
}
.url {
  height: 3.2rem;
  width: 38rem;
  margin-right: 1.6rem;
  padding: 0 2rem;
  border-radius: 1.6rem;
  background-color: $color-gray-lighten-3;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.url__link {
  @include font-regular-s();
}
.table__loader {
  border-top: 1px solid $color-gray-lighten-1;
}
.selected_tag {
  border: 1px solid $color-primary;
  color: $color-primary;
  display: inline-block;
  padding: 0.5rem 1.6rem;
  border-radius: 1.5rem;
  font-weight: bold;
  font-size: 1.3rem;
}
.row--inactive {
  background-color: $color-gray-lighten-3;
}

.contentCourseLinkButton {
  width: 16.8rem;
}

.icon {
  margin-right: 1rem;
  margin-left: -0.4rem;
}
