@use "../../../styles/variables.scss" as *;

.root {
  & + & {
    margin-bottom: 2.4rem;
  }
}

.studentGuardianMessage {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
}

.content {
  padding: 1.2rem 1.6rem;
  word-wrap: break-word;
  min-width: 0;
  word-break: break-all;
}

.studentGuardianMessage__header {
  @extend .header;
}

.studentGuardianMessage__content {
  @extend .content;
  background-color: #f1f7e9;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  margin-left: 3.8rem;

  > a {
    color: $color-primary;
    text-decoration: underline;
  }
}

.studentGuardianMessage__image {
  @extend .image;
  margin-left: 3.8rem;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
}

.operatorGuardianMessage {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.operatorGuardianMessage__header {
  @extend .header;
  justify-content: flex-end;
}

.operatorGuardianMessage__content {
  @extend .content;
  background-color: #88c931;
  color: #fff;
  border-radius: 0.8rem 0 0.8rem 0.8rem;
  margin-right: 3.8rem;

  > a {
    color: #fff;
    text-decoration: underline;
  }
}
.operatorGuardianMessage__contentAnnounce {
  background-color: var(--bui-colors-gray-200);
  color: $color-black;

  > a {
    color: $color-primary;
    text-decoration: underline;
  }
}

.operatorGuardianMessage__image {
  @extend .image;
  margin-right: 3.8rem;
  border-radius: 0.8rem 0 0.8rem 0.8rem;
}

.studentIcon {
  width: 3.6rem;
  height: 3.6rem;
  fill: #89c33b;
  background-color: #fff;
  border-radius: 50%;
}

.studentIconWrapper {
  width: 2.8rem;
  height: 2.8rem;
  background-color: #89c33b;
}

.operatorIcon {
  width: 2.8rem;
  height: 2.8rem;
}

.operatorIconWrapper {
  fill: #abb1c1;
}

.name {
  font-weight: bold;
  margin: 0 1rem;
}

.datetime {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
}

.image {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
}

@include max-screen($breakpoint-mobile) {
}
