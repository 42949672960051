@use "../../styles/variables.scss" as *;

.root {
  margin: 0rem auto 4rem;
  max-width: 104.6rem;
}

.timelineItem {
  border-radius: 0.8rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
  overflow: hidden;

  & + .timelineItem {
    margin-top: 1.6rem;
  }
}
