@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
}
.opened {
  width: 25.6rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: none;
  }
}
