@use "../../../styles/variables.scss" as *;

.root {
  border-top: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
  overflow: auto;
}
.root > table {
  min-width: 102.4rem;
}
.row > td {
  border-style: solid;
  border-color: var(--bui-colors-gray-400);
  border-width: 1px 1px 0 0;
  vertical-align: top;
  &:last-child {
    border-width: 1px 0 0 0;
  }
}
.nameColumn {
  width: 11%;
}
.jobColumn {
  width: 10%;
}
.datetimeColumn {
  width: 14%;
}
.questionColumn {
  width: 35%;
}
.datetimeColumn,
.questionColumn {
  border-right: 1px solid $color-gray-lighten-1;
}
.answerColumn {
  width: 30%;
}
