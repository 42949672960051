@use "../../../styles/variables.scss" as *;

.svg {
  fill: currentColor;
}

.root {
  margin-top: 3.2rem;
}

.label {
  @include font-bold-m();
  padding-left: 0.5rem;
}

.tableWrapper {
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  background-color: #fff;
  margin-top: 1.6rem;
  overflow-x: scroll;
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.tableHeader {
  height: 3.2rem;
  border-bottom: 1px solid $color-gray-lighten-1;

  & > th {
    white-space: nowrap;

    &:first-child {
      padding-left: 1.6rem;
    }

    &:last-child {
      padding-right: 1.6rem;
    }
  }
}

.tableRow {
  height: 6.4rem;
  border-top: 1px solid $color-gray-lighten-1;

  & > td {
    white-space: nowrap;
    padding: 0 1.4rem;

    &:first-child {
      padding-left: 3.2rem;
      text-align: left;
    }

    &:last-child {
      padding-right: 1.6rem;
      text-align: right;
    }
  }
}

.operatorName {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: $color-black-lighten-2;
}

.iconsvg {
  margin-right: 1.6rem;
  fill: currentColor;
}

.deleteLink {
  display: inline-flex;
  align-items: center;
  color: $color-black-lighten-2;
  font-size: $font-bold-xs-font-size;

  &:hover {
    color: $color-notification;
  }
}

.zoomConnected {
  display: inline-flex;
  align-items: center;
  color: $color-primary;
  font-size: $font-bold-xs-font-size;
}

.select {
  min-width: 16rem;
  width: 100%;
}

@include max-screen($breakpoint-mobile) {
  .tableWrapper {
    overflow-x: scroll;
  }
}
