@use "../../../styles/variables.scss" as *;

.root {
  position: relative;
  display: inline-block;
}

.button {
  border-radius: 50%;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background: $color-primary-lighten-2;
}

.iconMessage {
  fill: $color-primary-lighten-1;
}

.badge {
  position: absolute;
  background-color: #f00;
  color: #fff;
  border-radius: 1rem;
  right: -0.3rem;
  top: -0.3rem;
  padding: 0;
  min-width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;

  & > .number {
    font-size: 1rem;
  }
}
