@use "../../../../styles/variables.scss" as *;

.root {
  overflow-x: auto;
}

.table {
  color: $color-black;
  font-size: $font-regular-m-font-size;
}

.contentNameColumn {
  min-width: 20.8rem;
}
.contentNameColumn__name {
  color: $color-black;
  font-weight: bold;
}

.editColumn,
.deleteColumn {
  width: 4.8rem;
  color: $color-black-lighten-2;
  font-size: $font-regular-xs-font-size;
  padding-left: 1rem;
  padding-right: 1rem;
}
.deleteColumn {
  padding-right: 2rem;
}

.emptyTableRow {
  color: $color-black-lighten-1;
  opacity: 0.5;
}

.contentName__durationValue {
  @include font-regular-s();
}
