.table {
  min-width: 114rem;
}
.nameHeader {
  width: 25%;
}

.scoreHeader {
  width: 8%;
}
