@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  height: 100%;
}

.logoIcon {
  width: 3.4rem;
  height: 3.4rem;
  text-align: center;
}

.logoTxt {
  height: 1.6rem;
  margin-left: 1.6rem;
  margin-bottom: 0.6rem;
}
