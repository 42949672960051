@use "../../../styles/variables.scss" as *;

.Breadcrumbs {
  display: flex;
  align-items: center;
  font-size: $font-regular-m-font-size;
  font-weight: bold;
}
.BreadcrumbItemWrapper {
  display: flex;
  align-items: center;
  a {
    color: $color-primary;
  }
}
.BreadcrumbLastItemWrapper {
  color: $color-gray-darken-2;
}

/* 基本的にSeparatorとの感覚は10pxをとる, liを外出ししてclassを受けるように作るのが理想だったがそこを見通して作れていなかった */
/* そのため詳細度が高くなってしまっている */
.Breadcrumbs {
  > .BreadcrumbItemWrapper > .BreadcrumbItem,
  > .BreadcrumbLastItemWrapper > .BreadcrumbItem {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  > .BreadcrumbItemWrapper:first-child > .BreadcrumbItem {
    margin-left: 0;
  }
  > .BreadcrumbLastItemWrapper > .BreadcrumbItem {
    margin-right: 0;
  }
}

.Separator > svg {
  display: block;
}
