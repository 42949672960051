@use "../../../styles/variables.scss" as *;

.root {
  padding-top: 6.7rem;
  margin: 0 auto;
  text-align: center;
  display: block;
}

.heading {
  margin-bottom: 4rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.body {
  margin: 4rem 0;
}

.accept {
  margin-bottom: 3.2rem;
}

.deny {
  color: $color-primary;
}
