.analyticsLectureStudent__heading {
  margin-top: 2rem;
  font-size: 1.8rem;
}
.analyticsLectureStudent__pagination {
  margin-top: 1.6rem;
}
.analyticsLectureStudent__table {
  margin-top: 1rem;
}
