@use "../../../styles/variables.scss" as *;

.root {
  display: inline-flex;
  align-items: center;
}
.label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.labelDisabled {
  color: #a3a7b6;
  cursor: not-allowed;
}
.block {
  display: flex;
  width: 100%;
  margin-top: 1.6rem;
}
.icon {
  fill: #abb0c1;
}
.iconChecked {
  stroke: none;
  fill: $color-primary;
}
.iconDisabled {
  stroke: none;
  fill: #d5d8e0;
}
.label__text {
  margin-left: 0.8rem;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.input {
  display: none;
}
.block__label {
  width: 100%;
}
