@use "../../styles/variables.scss" as *;

.TableControl {
  display: grid;
  position: relative;
  margin-top: 2.4rem;
  grid-template-columns: 1fr auto 1fr;
}
.TableControl > div:last-child {
  margin-left: auto;
}

.TableControl__RightArea {
  display: flex;
  align-items: center;

  > * {
    margin-left: 1.6rem;
  }
}

.TableControl__LeftArea {
  display: flex;
  align-items: center;
}

.MainContentsWrapper {
  margin-top: 2rem;
}
.TabWrapper {
  margin-top: -1rem;
}

@include max-screen($breakpoint-mobile) {
  .TableControl {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .TableControl > div:last-child {
    margin-left: 0;
  }
  .TableControl__RightArea {
    display: flex;
    align-items: center;
    > * {
      margin-left: 0;
    }
  }
}
