@use "../../../styles/variables.scss" as *;

.learningMaterialImage {
  width: 3.8rem;
  height: 5.4rem;
  object-fit: contain;
  margin-right: 2.4rem;
}
.nameCell {
  min-width: 48rem;
}
.editCell {
  min-width: 12rem;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
  & > li {
    margin-top: 1rem;
  }
}
@include max-screen($breakpoint-mobile) {
}
