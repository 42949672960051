@use "../../../styles/variables.scss" as *;

.root {
  position: absolute;
  top: 100%;
  box-shadow: 0 0 1rem 0 rgba($color-gray-darken-1, 0.5);
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.6rem;
  right: 0rem;
  background: #fff;
  color: $color-black-lighten-1;
  max-height: 44rem;
  overflow-y: scroll;
  padding-bottom: 4rem;
  -ms-overflow-style: none;
  z-index: 4;
}

.root::-webkit-scrollbar {
  width: 0;
}

.wide {
  width: 34.5rem;
}

.narrow {
  width: 26.8rem;
}

@include max-screen($breakpoint-mobile) {
  .wide {
    max-height: 40rem;
    position: fixed;
    border-radius: 0.6rem;
    width: 100%;
    left: 0;
    background: #fff;
    color: #5c5f6a;
    top: calc(
      #{$mobile-sp-global-header-height} + #{$mobile-global-header-height}
    );
    border-radius: 0;
    box-shadow: none;
    overflow-y: hidden;
  }
  .root {
    max-height: none;
    border: 0;

    /* for CSS変数をサポートしていないブラウザ */
    height: calc(
      100vh - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-menu-footer-height}
    );

    /* CSS変数をサポートしているブラウザ */
    height: calc(
      var(--innerHeight) - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-menu-footer-height}
    );
  }
}
