@use "../../../styles/variables.scss" as *;

.root {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: flex;
    width: 100%;
    height: $mobile-sp-global-header-height;
    justify-content: space-between;
    align-items: center;
    background: #fff;
  }
}
