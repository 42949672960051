@use "../../../../styles/variables" as *;

.root {
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter {
  flex-grow: 1;
}
.button {
  margin-top: 2rem;
}
