@use "../../../styles/variables.scss" as *;

.list {
  max-width: 104.6rem;
  min-width: 40.8rem;
  margin: 2rem auto;
  border: 1px solid $color-gray-lighten-1;
  background-color: #fff;
  border-radius: 0.8rem;
}

.item {
  height: 7rem;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-lighten-1;
  color: $color-black;

  &:last-child {
    border: 0;
  }

  &:hover {
    color: $color-black;
    background-color: var(--bui-colors-gray-200);
  }

  &.left {
  }

  &.right {
  }
}

.highlight {
  background-color: #f3f7ff;
}

.bold {
  font-weight: bold;
}

.date {
  color: #333333;
  font-size: $font-regular-s-font-size;
}

.icon {
  fill: $color-black-lighten-2;
  margin-right: 1.2rem;
}

@include max-screen($breakpoint-mobile) {
  .list {
    margin-left: 0;
    margin-right: 0;
    min-width: auto;
  }
}
