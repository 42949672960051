@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-bottom: 1px solid $color-gray-lighten-1;
  padding: 1.4rem 0 1.4rem 2.1rem;
}

.root:hover {
  background: $color-gray-lighten-2;
}

.isCurrent {
  border-left: 5px solid $color-gray-darken-2;
  padding: 1.6rem 0 1.6rem 1.6rem;
}

.left {
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 1.6rem;
  width: 100%;
  line-height: 1rem;
}

.name {
  @include font-bold-m();
  width: 100%;
  margin: 0;
}

.link {
  @include font-regular-xs();
  color: $color-primary;
  width: 100%;
  margin: 0;
}

.iconAccountWrapper,
.iconAccount {
  width: 3.6rem;
  height: 3.6rem;
}

.iconAccountWrapper {
  background: #fff;
}

.iconAccount {
  fill: $color-gray-darken-1;
}
