@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  padding: 2rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.6rem;
  margin-top: 2.4rem;
  box-sizing: border-box;
  min-height: 27.8rem;
}

.row {
  display: flex;
  margin-top: 2.4rem;
}

.rowBetween {
  display: flex;
  justify-content: space-between;
}

.navi {
  display: inline-flex;
  align-items: center;
  color: $color-primary;
  fill: $color-primary;
}

.heading {
  margin: 0;
  padding: 0;
  font-size: $font-regular-m-font-size;
}

.date {
  color: #6f7382;
}

.link {
  margin-left: 3.2rem;
  text-decoration: underline;
  color: $color-black;
  font-weight: bold;
}

.notfound {
  margin-top: 8.8rem;
  text-align: center;
  color: $color-gray-darken-3;
  font-weight: bold;
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin-top: 0.8rem;
  }

  .row {
    display: block;
  }

  .date {
    display: block;
  }

  .link {
    display: block;
    margin: 1rem 0 0;
  }
}
