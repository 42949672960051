@use "../../../styles/variables.scss" as *;

.root {
  font-size: 1.4rem;
  border: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
  border-radius: 0.8rem;
  overflow: hidden;
}
.root:first-child {
  margin-top: 0;
}
.main {
  padding: 1.6rem;
  background: #fff;
}
.body {
  margin: 1.6rem 0;
}
.createdAt {
  margin-left: 1.6rem;
}
.comment {
  margin: 1.6rem 0;
}
.icon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.2rem;
}
.commentIcon {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  fill: #abb1c1;
  color: $color-black-lighten-2;
}
.active {
  color: $color-primary;
  fill: $color-primary;
}
