@use "../../../../styles/variables" as *;

.message {
  margin-top: 2rem;
  height: calc(100vh - 24rem);
  min-height: 30rem;
}

.newMessage {
  padding: 2rem 2.4rem;
  font-weight: bold;
}

.newMessage__button {
  width: 100%;
  height: 4.5rem;
  background: #fff;
  border: 1px solid $color-primary;
  color: $color-primary;
  fill: $color-primary;
  font-weight: bold;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 4.5rem;

  &:hover {
    background: $color-gray-lighten-3;
  }
}
.close {
  display: none;
}
.header {
  font-weight: bold;
}

.header__link {
  color: #89c33b;
}

.content {
  padding: 1.2rem 1.6rem;
  word-break: break-all;
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
}

.iconWrapper {
  fill: #abb1c1;
}

.name {
  font-weight: bold;
}

.datetime {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
}

.image {
  width: 18rem;
  height: 18rem;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  object-fit: cover;
  margin-top: 0.8rem;
  margin-left: 3.2rem;
}

.listContainer {
  height: 100%;
}

@include max-screen($breakpoint-mobile) {
  .close {
    display: inline-block;
    fill: #abb1c1;
  }

  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  .content {
    margin-top: 0.8rem;
  }

  .message {
    height: calc(100vh - 4rem);
  }

  .close {
    display: inline-block;
    fill: #abb1c1;
  }
}
