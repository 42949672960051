@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-gray-lighten-1;
  padding: 1rem 2rem 2rem 1.6rem;
  position: relative;
  &:hover {
    background: #eff1f9;
  }
  &:first-child {
    border-top: 1px solid $color-gray-lighten-1;
  }
}
.deleteButton {
  display: none;
  &:hover {
    fill: $color-primary;
  }
}
.root:hover .deleteButton {
  display: block;
}
.left {
}
.right {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
}
.senderName {
  color: $color-gray-darken-3;
  font-weight: bold;
}
.createAt {
  @include font-regular-xs();
  color: $color-black-lighten-2;
  margin-left: 1.6rem;
}
.icon {
  width: 2.8rem;
  height: 2.8rem;
  fill: #abb1c1;
}
.body {
  margin-top: 1.6rem;
  width: 100%;
  overflow-wrap: anywhere;
}
.header {
  display: flex;
}

@include max-screen($breakpoint-mobile) {
  .deleteButton {
    display: block;
  }
}
