@use "../../../styles/variables.scss" as *;

.list {
  padding: 0.8rem 0;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(29, 40, 50, 0.1);
  border: 1px solid color-gray-lighten-1;
  border-radius: 0.5rem;
}
.dropdownItem {
  padding: 1rem 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  fill: $color-gray-darken-1;
  color: $color-black;
  word-break: keep-all;

  &:hover {
    color: $color-primary;
    background: $color-gray-lighten-2;
    fill: $color-primary;
  }

  & > svg {
    margin-right: 0.8rem;
  }
}
.dropdownItem--danger {
  fill: $color-notification;
  color: $color-notification;
  &:hover {
    background: #fff0f0;
    fill: $color-notification;
    color: $color-notification;
  }
}
.icon {
  fill: $color-gray-darken-1;

  &:hover {
    fill: $color-primary;
  }
}

@include max-screen($breakpoint-mobile) {
}
