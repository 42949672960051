@use "../../../styles/variables.scss" as *;

.root {
  max-width: 68rem;
}
.heading {
  @include font-bold-m();
  margin-top: 4.8rem;
}

.body {
  @include font-regular-m();
  padding: 0;
  padding-left: 2rem;
  box-sizing: border-box;
  line-height: 2.4rem;
  margin-top: 2rem;
  .list_item {
    display: list-item;
    list-style-type: decimal;
  }
}
