@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  background-color: #fff;
  border: 1px solid $color-gray-lighten-2;
  border-radius: 0.8rem;
  box-sizing: border-box;
  padding: 1.6rem;
}

.label {
  font-size: $font-bold-xs-font-size;
  color: $color-gray-darken-2;
}

.name {
  margin-bottom: 1.6rem;
}

.template {
  margin-bottom: 1.6rem;
}

.errors {
  color: $color-notification;
  margin-left: 1rem;
  display: inline-block;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.button {
  margin-left: 1.6rem;
  padding: 0 3.2rem;
}

.delete_button {
  padding: 0 3.2rem;
  border: 1px solid $color-notification;
  color: $color-notification;
  background-color: #fff;
}
