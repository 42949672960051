@use "../../../../styles/variables" as *;

.root {
  padding: 3.2rem;
}

.heading {
  font-size: 1.6rem;
  font-weight: bold;
  color: $color-black;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
}

.result {
  border-radius: 0.5rem;
  background-color: #fafafa;
  padding: 2.2rem 3.2rem;
  margin: 3.2rem 0 2rem;
}

.resultHeading {
  font-size: 1.4rem;
  margin: 0 0 1.8rem;
}

.successResult {
  color: $color-primary;

  .successFeedback {
    color: $color-black;

    > a {
      color: $color-primary;
    }
  }
}

.failureResult {
  color: #f15360;
}

.buttonContainer {
  text-align: right;
}

.reloadButton {
  margin-top: 2.6rem;
  width: 20rem;
}

.downloadButton {
  margin-top: 12px;
  margin-bottom: 16px;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1rem;
  }
}
