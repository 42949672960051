@use "../../../../styles/variables.scss" as *;

.StudentCourseResultSummary {
  background-color: #fff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 8px;
  padding: 2.4rem 1.6rem;
  &__head {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  &__title {
    color: $color-black;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__text {
  }
  &__icon {
    flex-shrink: 0;
  }
}

.LinkButton {
  @include font-regular-xs();
  fill: $color-gray-darken-1;
  color: $color-gray-darken-3;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  height: 2.4rem;
  padding: 0 0.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:visited {
    opacity: 0.6;
    fill: $color-gray-darken-1;
    color: $color-gray-darken-3;
  }
}
