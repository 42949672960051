@use "../../../../styles/variables" as *;

.root {
  & + & {
    margin-bottom: 2.4rem;
  }
}

.studentMessage {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  word-break: break-all;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;

  & > * {
    display: inline-block;
    margin-left: 1rem;
  }

  & > :first-child {
    margin-left: 0;
  }
}

.content {
  padding: 1.6rem;
  word-wrap: break-word;
  min-width: 0;
}

.studentMessage__header {
  @extend .header;
}

.studentMessage__content {
  @extend .content;
  background-color: $color-gray-lighten-2;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  margin-left: 3.8rem;

  > a {
    color: $color-primary;
    text-decoration: underline;
  }
}

.message__deletedContent {
  @extend .content;
  color: $color-gray-darken-1;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  margin-left: 3.8rem;
}

.studentMessage__image {
  @extend .image;
  margin-left: 3.8rem;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
}

.operatorMessage {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  word-break: break-all;
}

.operatorMessage__header {
  @extend .header;
  justify-content: flex-end;
}

.operatorMessage__content {
  @extend .content;
  background-color: $color-primary;
  color: #fff;
  border-radius: 0.8rem 0 0.8rem 0.8rem;
  margin-right: 3.8rem;

  > a {
    color: #fff;
    text-decoration: underline;
  }
}

.operatorMessage__image {
  @extend .image;
  margin-right: 3.8rem;
  border-radius: 0.8rem 0 0.8rem 0.8rem;
}

.studentIcon {
  width: 2.4rem;
  height: 2.4rem;
}

.studentIconWrapper {
  fill: $color-icon-pink;
}

.operatorIcon {
  width: 2.8rem;
  height: 2.8rem;
}

.operatorIconWrapper {
  fill: #abb1c1;
}

.name {
  font-weight: bold;
}

.datetime {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
}

.image {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
}

@include max-screen($breakpoint-mobile) {
}
