@use "../../../styles/variables.scss" as *;

.container {
  display: flex;
  flex-grow: 1;
}

.logo {
  margin-left: 2rem;
}

.main {
  display: flex;
  flex-direction: column;
  width: 47.2rem;
}

.children {
  margin: 0 6.2rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  color: $color-gray-darken-1;
  height: 10%;
  padding-bottom: 4rem;

  & > nav > ul {
    display: flex;
    justify-content: center;

    & > li {
      border-right: 1px solid $color-gray-lighten-1;
      padding: 0 0.8rem;
      font-size: $font-regular-xs-font-size;

      & > a {
        color: $color-gray-darken-1;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.copyright {
  font-size: $font-regular-xs-font-size;
  text-align: center;
  width: 100%;
  margin-top: 2.4rem;
  color: $color-gray-darken-1;
}

.aside {
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  margin-top: -6.4rem;
}

.aside__heading {
  font-size: 3.2rem;
  font-weight: normal;
  margin-top: 8.8rem;
}

.aside__message {
  margin-top: 2.4rem;
  font-size: $font-regular-xl-font-size;
}

.aside__image {
  margin-top: 3.2rem;
  width: 80%;
  max-width: 65rem;
}

@include max-screen($breakpoint-tablet) {
  .main {
    width: 100%;
  }

  .children {
    width: 90%;
    margin: auto;
  }

  .aside {
    display: none;
  }
}
