@use "../../../styles/variables.scss" as *;

// 枠線に色を適用する
@mixin applyColorToOutlineStyle($color) {
  background: #fff;
  border: 1px solid $color;
  color: $color;
  fill: $color;
}
// 塗りに色を適用する
@mixin applyColorToSolidStyle($color) {
  color: #fff;
  fill: #fff;
  background: $color;
}

// ハイライト時の色を適用する
@mixin applyColorToHighlightStyle($color) {
  background: $color;
}

// outline時のアイコンと文字の色を適用する
@mixin applyColorToOutlineIcon($color) {
  color: $color-black;
  & > .circleIcon {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.8rem;
    background: $color;
    fill: #fff;
  }
}

// solid時のアイコンと文字の色を適用する
@mixin applyColorToSolidIcon($color) {
  color: #fff;
  & > .circleIcon {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.8rem;
    background: #fff;
    fill: $color;
  }
}

.root {
  border-radius: 1.4rem;
  display: inline-flex;
  align-items: center;
  padding: 0 1.6rem;
  line-height: 1;
  height: 2.8rem;
  font-size: $font-regular-xs-font-size;
  cursor: pointer;
  transition:
    background 0.2s,
    color 0.2s,
    fill 0.2s;
  max-width: 100%;
  box-sizing: border-box;

  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: default;
  }
}
.withIcon {
  padding: 0 0.8rem 0 1.6rem;
}
.label {
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.primary.solid {
  @include applyColorToSolidStyle($color-primary);
}
.primary.outline {
  @include applyColorToOutlineStyle($color-primary);
}
.primary.hoverSolid:hover {
  @include applyColorToSolidStyle($color-primary);
}
.primary.hoverOutline {
  @include applyColorToOutlineStyle($color-primary);
}
.hoverPrimary.hoverSolid:hover {
  @include applyColorToSolidStyle($color-primary);
}
.hoverPrimary.hoverOutline:hover {
  @include applyColorToOutlineStyle($color-primary);
}
.primary.circleIcon.solid {
  @include applyColorToSolidStyle($color-primary);
  @include applyColorToSolidIcon($color-primary);
}
.primary.circleIcon.outline {
  @include applyColorToOutlineStyle($color-primary);
  @include applyColorToOutlineIcon($color-primary);
}
.primary.circleIcon.hoverSolid:hover {
  @include applyColorToSolidStyle($color-primary);
  @include applyColorToSolidIcon($color-primary);
}
.primary.circleIcon.hoverOutline {
  @include applyColorToOutlineStyle($color-primary);
  @include applyColorToOutlineIcon($color-primary);
}
.hoverPrimary.circleIcon.hoverSolid:hover {
  @include applyColorToSolidStyle($color-primary);
  @include applyColorToSolidIcon($color-primary);
}
.hoverPrimary.circleIcon.hoverOutline:hover {
  @include applyColorToOutlineStyle($color-primary);
  @include applyColorToOutlineIcon($color-primary);
}
.primary.circleIcon.highlight.outline {
  @include applyColorToOutlineStyle($color-primary);
  @include applyColorToHighlightStyle($color-primary-lighten-1);
  @include applyColorToOutlineIcon($color-primary);
}
.primary.circleIcon.highlight.hoverSolid:hover {
  @include applyColorToSolidStyle($color-primary);
  @include applyColorToSolidIcon($color-primary);
}

// gray
.grayLighten2 {
  background: $color-gray-lighten-2;
  color: $color-black;
  fill: $color-black;
  font-weight: normal;
}

// light blue
.lightBlue.solid {
  @include applyColorToSolidStyle($color-light-blue);
}
.lightBlue.outline {
  @include applyColorToOutlineStyle($color-light-blue);
}
.hoverLightBlue.hoverSolid:hover {
  @include applyColorToSolidStyle($color-light-blue);
}
.hoverLightBlue.hoverOutline:hover {
  @include applyColorToOutlineStyle($color-light-blue);
}
.hoverLightBlueLighten1:hover {
  @include applyColorToSolidStyle($color-light-blue-lighten-1);
}

// light green
.lightGreen.solid {
  @include applyColorToSolidStyle($color-light-green);
}
.lightGreen.outline {
  @include applyColorToOutlineStyle($color-light-green);
}
.hoverLightGreen.hoverSolid:hover {
  @include applyColorToSolidStyle($color-light-green);
}
.hoverLightGreen.hoverOutline:hover {
  @include applyColorToOutlineStyle($color-light-green);
}
.hoverLightGreenLighten1:hover {
  @include applyColorToSolidStyle($color-light-green-lighten-1);
}

// font weight
.bold {
  font-weight: bold;
}
