@use "../../../styles/variables.scss" as *;

.button {
  display: flex;
}
.button__number {
  width: 3.6rem;
  text-align: left;
}
.panel__contents {
  margin-top: 2rem;
  .contents__element {
    display: flex;
    height: 3.6rem;
    align-items: center;
  }
}

.panel__name--black {
  color: $color-black;
}

.panel__icon {
  svg {
    fill: $color-black;
  }
}
.panel__name {
  margin-left: 1.6rem;
}
.panel {
  margin-left: 3.6rem;
}
