@use "../../../styles/variables.scss" as *;

.root {
  padding: 1rem 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  line-height: 1.6;
  min-height: 4.6rem;
  max-height: 42rem;
  @include max-screen($breakpoint-tablet) {
    max-height: 20rem;
  }
  @include max-screen($breakpoint-mobile) {
    max-height: 13rem;
  }

  &:focus {
    border: 1px solid $color-primary;
  }

  &[readonly],
  &[readonly]:focus {
    background-color: $color-gray-lighten-3;
    border: 1px solid $color-gray-lighten-1;
  }
  &::placeholder {
    color: $color-black;
    opacity: 0.5;
  }
}
.full {
  height: 100%;
}

.hasError,
.hasError:focus {
  border-color: $color-notification;
  background: #fbf0f0;
}
