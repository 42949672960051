@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 6rem;
  display: block;
  width: 68rem;
}

.invitations {
  margin-bottom: 1.6rem;

  .invitationMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .sectionName {
    font-weight: bold;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin-top: 3.2rem;
  }
}
