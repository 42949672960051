@use "../../../../styles/variables" as *;

.container {
  margin-top: 2rem;
}

.root {
  margin: 2rem 0;
  padding: 2.4rem 3.2rem;
  background-color: #ffffff;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
}

.header {
  display: flex;
  align-items: center;
}

.logo {
  & > img {
    width: 4.4rem;
    height: 4.4rem;
  }
}

.title {
  @include font-bold-xl();
  padding: 0 2.4rem;
}

.link {
  display: inline-flex;
  align-items: center;
  fill: $color-primary;
  color: $color-primary;
}

.summary {
  margin: 2rem 0;
}

.summary__description {
  @include font-regular-m();
}

.summary__features {
  margin-top: 3.2rem;
}

.summary__features__heading {
  @include font-bold-m();
}

.summary__features__body {
  @include font-regular-m();
  margin-top: 0.8rem;
}

.action {
  margin-top: 0.8rem;
}

.action__box {
  margin-top: 3.2rem;
  padding: 2.4rem 3.2rem;
  background-color: #fafafa;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courses {
  margin-top: 3.2rem;
}

.courses__heading {
  @include font-bold-m();
}
