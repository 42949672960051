@use "../../../styles/functions.scss" as *;

.root {
  display: flex;
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

$gap_values: "0_4", "0_8", "1", "1_6", "2";
@each $gap in $gap_values {
  .gap#{$gap} {
    gap: #{str-replace($gap, "_", ".")}rem;
  }
}
