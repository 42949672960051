@use "../../../../styles/variables.scss" as *;

.root {
  margin-top: 3.2rem;
  isolation: isolate;
}

.tableControl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto 1fr;
}

.tableControl div:nth-child(1) {
  grid-column-start: 2;
}

.tableControl div:nth-child(2) {
  margin-left: auto;
}

.tableControlRight {
  display: flex;
  align-items: center;

  > * {
    margin-left: 1.6rem;
  }
}

@include max-screen($breakpoint-mobile) {
  .tableControl {
    display: block;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
  }

  .tableControlRight {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

@include max-screen($breakpoint-mobile) {
}
