@use "../../../styles/variables.scss" as *;
:global {
  // react-select側が付与してくるクラスにスタイルをつける
  .react-select {
    --multi-value-bg-color: #{$color-primary};
    --multi-value-font-color: #fff;
    .react-select__multi-value {
      background-color: var(--multi-value-bg-color);
      color: var(--multi-value-font-color);
    }
    .react-select__multi-value__label {
      color: var(--multi-value-font-color);
    }
    .react-select__multi-value__remove {
      width: 2.8rem;
      &:hover {
        background-color: var(--multi-value-bg-color);
        color: var(--multi-value-font-color);
      }
    }
  }
}
