@use "./../../../styles/variables.scss" as *;

.selected_tags_container {
  border: 1px solid $color-gray-lighten-1;
  border-radius: 4px;
  padding: 0.8rem;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  overflow: auto;
  resize: vertical;
  min-height: 9.8rem;
  margin-top: 1.6rem;
}
.selected_tag {
  margin: 0.8rem;
}
.caution_statement {
  @include font-regular-xs();
  color: $color-gray-darken-2;
}
