@use "../../../styles/variables.scss" as *;

.root {
  display: block;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 3.2rem;

  &:hover {
    color: $color-primary;

    .icon {
      fill: $color-primary;
    }
  }
}

.opened {
  justify-content: space-between;
  align-items: center;
}

.label {
  display: block;
}

.collapsible .label {
  display: none;
}

.collapsible.opened .label {
  display: block;
}

.active {
  .label {
    color: $color-primary;
    font-weight: bold;
  }

  .icon {
    fill: $color-primary;
  }
}

.iconWrapper {
  color: $color-black-lighten-2;
  text-align: center;
  line-height: 0;
  margin: 0 2rem;
}

.icon {
  fill: $color-black-lighten-2;
  width: 2.4rem;
  height: 2.4rem;
}

.label {
  width: 100%;
}

@include max-screen($breakpoint-mobile) {
  .root {
    justify-content: left;
    align-items: center;
  }

  .collapsible .label {
    display: block;
  }

  .iconWrapper {
    color: $color-black-lighten-2;
    text-align: center;
  }

  .label {
    display: block;
    width: auto;
  }
}
