@use "../../styles/variables.scss" as *;

.root {
  margin: 2rem auto;
  max-width: 104.6rem;
  min-width: 40.8rem;
}

.timelineItem {
  border-radius: 0.8rem;
  background: #fff;
  border: 1px solid $color-gray-lighten-1;
  overflow: hidden;

  & + .timelineItem {
    margin-top: 1.6rem;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin: 1.6rem 0;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
}
