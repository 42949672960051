@use "../../../../styles/variables" as *;

.table__wrapper {
  background-color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
}

.table__body {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  color: $color-black-lighten-2;

  thead .table__row:first-child {
    border-top: 0;
  }
}

.table__header {
  height: 3.2rem;
  border-bottom: 1px solid $color-gray-lighten-1;
}
.header__course {
  text-align: left;
  padding: 0 6.4rem;
}
.header__operator {
  text-align: left;
  padding: 0 3.2rem;
}
.header__status {
}
.header__menu {
}

.table__row {
  border-top: 1px solid var(--bui-colors-gray-300);
  height: 6.4rem;
  box-sizing: border-box;
}

.table__notfound {
  @include font-regular-m();
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course {
  text-align: left;
  padding-left: 3.2rem;
  width: 50%;
}
.course__success {
  @include font-bold-m();
  color: $color-primary;
  text-decoration: underline;
}
.course__failed {
  @include font-bold-m();
  color: $color-gray-darken-1;
}
.status_success {
  color: #a3c77c;
}
.status_failed {
  color: #e25461;
}
.operator {
  display: flex;
  align-items: center;
  justify-content: left;
}
.operator__icon {
  fill: #abb1c1;
}
.operator__name {
  margin-left: 1.6rem;
}
