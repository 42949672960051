@use "../../../styles/variables.scss" as *;

.root {
  position: relative;
  display: inline-block;
}

.button {
  border-radius: 50%;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background: $color-primary-lighten-2;
}

.iconMessage {
  fill: $color-primary-lighten-1;
}

.badge {
  position: absolute;
  background-color: #f00;
  color: #fff;
  border-radius: 1rem;
  right: -0.3rem;
  top: -0.3rem;
  padding: 0;
  min-width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;

  & > .number {
    font-size: 1rem;
  }
}

.loading {
  text-align: center;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.4rem 1.6rem;
}

.left {
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 1.6rem;
}

.name {
  @include font-bold-s();
}

.datetime {
  @include font-regular-xs();
  color: $color-black-lighten-2;
}

.content {
  @include font-regular-s();
  width: 26rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.8rem;
  line-height: 1.2;
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
  fill: #89c33b;
  background-color: #fff;
  border-radius: 50%;
}
