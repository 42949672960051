@use "../../../styles/variables.scss" as *;

.button {
  padding: 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.button__icon {
  overflow: hidden;
}

.item {
  padding: 0;
}
.panel {
  padding: 0;
}
img {
  margin: 0;
  padding: 0;
}

.theme--gray {
  > div {
    border-radius: 0.5rem;
  }
  > div:not(:first-child) {
    margin-top: 1.6rem;
  }
  .item {
    color: $color-black;
    background-color: $color-gray-lighten-3;
    font-size: 1.3rem;
  }
  .button {
    padding: 1.6rem;
    color: $color-gray-darken-3;
    font-weight: bold;
    font-size: 1.4rem;
    .button__icon {
      height: 1.4rem;
      svg {
        margin-top: -0.4rem;
        fill: #abb1c1;
      }
    }
  }
  .panel {
    padding: 0rem 1.6rem 1.6rem 1.6rem;
    svg {
      fill: #abb1c1;
    }
  }
}
