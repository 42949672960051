@use "../../../styles/variables.scss" as *;

.root {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 2rem;
}

.root.small {
  font-size: 1.2rem;
}

.number {
  font-size: 2rem;
  margin: 0 0.4rem;
}
