@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
  min-width: 0px;
}

@include max-screen($breakpoint-tablet-960) {
  .root {
    min-width: auto;
  }
  .button {
    min-width: auto;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 1rem;
  border-radius: 50%;
}

.button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 0px;

  &:hover {
    & > .name {
      color: $color-primary-lighten-1;
    }
    .iconAccount {
      fill: $color-black-lighten-2;

      &.white {
        fill: $color-gray-darken-2;
      }
    }
    .iconArrow {
      fill: #79a7f2;
    }
  }
}

.button:focus {
  outline: 0;
}

.name {
  margin-left: 1.4rem;

  &.white {
    @include font-regular-m();
  }

  &.main {
    min-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
  }
}

@include max-screen($breakpoint-tablet-960) {
  .arrow,
  .name {
    display: none;
  }
}

.iconAccountWrapper {
  width: 3.6rem;
  height: 3.6rem;
  background: #fff;
}

.iconAccount {
  fill: #abb1c1;
  width: 3.6rem;
  height: 3.6rem;
}

.iconArrow {
  fill: $color-primary-lighten-1;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: inline-flex;
    width: 3.6rem;
    margin-right: 1.6rem;
    justify-content: flex-start;
  }

  .arrow,
  .name {
    display: none;
  }
}
