@use "../../../styles/variables.scss" as *;

.root {
  display: none;

  &:focus {
    outline: 0;
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: $color-primary;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: inline-flex;
    box-sizing: border-box;
    margin-left: 1.6rem;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
  }

  .button {
    display: block;
  }

  .opened .button {
    display: none;
  }
}
