@use "../../../../styles/variables" as *;

.root {
  padding: 3.2rem;
}

.heading {
  font-size: 1.6rem;
  font-weight: bold;
  color: $color-black;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 3rem;
}

.box {
  border-radius: 0.5rem;
  background-color: #fafafa;
  padding: 2.2rem 3.2rem;
  margin: 3.2rem 0 2rem;
}

.boxHeading {
  font-size: 1.4rem;
  margin: 0 0 1.8rem;
  display: flex;
  flex-wrap: wrap;

  .boxHeadingTitle {
    margin: 0 1.6rem 0 0;
  }

  .notice {
    font-size: 1.2rem;
  }
}

.help {
  color: $color-black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > a {
    color: $color-primary;
    fill: $color-primary;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;
}

.downloadButton {
  margin: 0 1rem 1rem 0;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1rem;
  }
}

.uploadForm {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + 2rem);
  margin-top: -2rem;
  margin-left: -2rem;
}

.uploadFormLabel {
  margin: 1rem 0 0.6rem;
}

.uploadFormFields {
  flex: 1 1 75%;
  margin-top: 2rem;
  margin-left: 2rem;
}

.uploadFormButton {
  flex: 1 0 20%;
  margin-top: 2rem;
  margin-left: 2rem;
}

.errorMessage {
  color: $color-notification;
}
