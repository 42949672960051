@use "../../../../../../../styles/variables.scss" as *;

.root {
  padding: 1.4rem 1.6rem;
  display: flex;
}

.textarea {
  padding: 1.4rem 2rem;
  flex-grow: 1;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  color: $color-black;
  resize: vertical;
}

.button {
  width: 10.8rem;
  margin-left: 1rem;
  max-height: 4.6rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1.4rem 1.6rem;
    display: flex;
    flex-wrap: wrap;
  }

  .textarea {
    padding: 0.8rem 2rem;
    height: 3.2rem;
  }

  .button {
    width: 100%;
    height: 4rem;
    margin-top: 0.8rem;
    margin-left: 0;
  }
}
