@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}

.content {
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* NOTE: iOSでスクロールができない問題への対処
  ref: developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling */
  -webkit-overflow-scrolling: touch;
}

.header {
  width: 100%;
  height: 6.5rem; // (1.4 * 2) + 3.6 + 0.1
  border-bottom: 0.1rem solid var(--bui-colors-gray-300);
  padding: 1.4rem 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
}

.logo {
  flex-grow: 1;
}

.main {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  padding: 0 1.6rem;
}

.heading {
  margin-top: 8rem;
  text-align: center;
}

.footer {
  font-size: $font-regular-xs-font-size;
  color: $color-gray-lighten-1;
  margin: 0 auto 4rem auto;
  text-align: end;
}

.footer__copyright {
  font-size: $font-regular-xs-font-size;
  text-align: center;
  width: 100%;
  margin-top: 2.4rem;
  color: $color-gray-darken-1;
}

@include max-screen($breakpoint-mobile) {
  .header {
    height: 6.1rem; // (1.2 * 2) + 3.6 + 0.1
    padding: 1.2rem 1.6rem;
  }
}
