@use "../../../styles/variables.scss" as *;

.root {
}
.operator {
  border-radius: 0.6rem;
  padding: 1.6rem;
  border-radius: 6px;
  background: $color-gray-lighten-3;
  margin: 4rem 0;
  text-align: left;
  display: flex;
  align-items: center;
}
.icon {
  margin-right: 1rem;
  fill: #abb1c1;
  width: 2.8rem;
  height: 2.8rem;
}
.link {
  color: $color-primary;
  font-weight: bold;
}
