@use "../../../../styles/variables" as *;

.guardianEmailModal {
  width: 68rem;
  max-width: 90%;
}
.guardianEmailModal__body {
  padding-bottom: 1.6rem;
}
.guardianEmail {
  margin-top: 2rem;
}
.guardianEmail__flex {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 1.4rem 1.6rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
}
.guardianEmail__flex__email {
  flex-grow: 1;
  padding-left: 1.2rem;
}

@include max-screen($breakpoint-mobile) {
  .guardianEmailModal {
    width: 100%;
    max-width: 100%;
  }
}

.sendEmailButton {
  width: 15.8rem;
}
