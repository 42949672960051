@use "../../../styles/variables.scss" as *;
.main {
  margin-top: 2.4rem;
}
.backLink {
  margin-top: 2rem;
}
.btnChoose {
  margin-top: 1.6rem;
}
.tableFiles {
  overflow-x: auto;
  margin-top: 2.4rem;
}
.note {
  color: $color-black-lighten-2;
  font-size: 1.2rem;
}
.progress {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  width: 100%;
  font-size: 1.2rem;
  margin-right: 4rem;
  gap: 1rem;
}
.progress__sizeInfo {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.progress__percentInfo {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  justify-self: end;
}
.progress__bar {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  --themeProgressBarHeight: 1rem;
  --themeProgressBarTrackColor: #{$color-gray-lighten-1};
  padding-right: 0.4rem;
}

.button--choose {
  height: 3.6rem;
}

.error {
  color: $color-notification;
  margin-top: 1rem;
  & > div:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.submitButton {
  width: 13.8rem;
}
