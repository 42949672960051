@use "../../../styles/variables.scss" as *;

.overlay {
  top: 0;
  left: 0;
  padding: 0;
  background: rgba(90, 90, 90, 0.7);
  position: fixed;
  z-index: $z-index-overlay;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

