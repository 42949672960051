@use "../../../styles/variables.scss" as *;

.root {
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  box-sizing: border-box;
}

.tag {
  margin-right: 1rem;
  margin-top: 1rem;
  max-width: 100%;
  box-sizing: border-box;
}

.selectTag {
  margin-top: 1rem;
}
