@use "../../../styles/variables.scss" as *;
.label {
  padding-top: 1.6rem;
  display: flex;
  align-items: center;
  > :not([hidden]) ~ :not([hidden]) {
    margin-left: 1.6rem;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1.6rem 0;
}

.submitButton {
  width: 13.2rem;
}
