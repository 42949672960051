@use "../../../../styles/variables.scss" as *;

.root {
  padding: 0 1.6rem;
  border-bottom: 1px solid var(--bui-colors-gray-300);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 4.8rem;

  & > h2 {
    padding: 1.6rem 0;
    margin: 0;
    line-height: 1;
  }

  & > * {
    font-size: 1.4rem;
  }
}
.noCloseButton {
  justify-content: center;
}
.icon {
  cursor: pointer;
  width: 3.6rem;
  height: 3.6rem;
  fill: #abb1c1;
}
