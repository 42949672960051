@use "../../../styles/variables" as *;

.form_row {
  display: flex;
  width: 100%;
  margin-bottom: 0.8rem;
}

.title {
  width: 100%;
}

.name {
  width: 34rem;
  margin-left: 0.8rem;
}

.btn_group {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6rem;
}

.errors {
  font-size: $font-regular-xs-font-size;
  color: $color-notification;
}

.submitButton {
  width: 9.2rem;
}
