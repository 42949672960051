@use "../../../../styles/variables" as *;

.root {
  margin: 7.2rem 0 0;
  width: 58.4rem;
}

.title {
  text-align: center;
  margin-top: 4.8rem;
}

.backlink {
  position: absolute;
  margin: 1.6rem 2rem;
  left: 0;
}
.description {
  margin-bottom: 2.4rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    margin-top: 7.2rem;
    width: 100%;
  }
}
