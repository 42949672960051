@use "../../../styles/variables.scss" as *;

.iconContainer {
  font-size: 0;
  fill: $color-black;
}
.text {
  font-weight: bold;
}
.addQuestionnaireContainer {
  text-align: center;
  padding: 1rem 0;
}
.linkButtonContainer {
  width: fit-content;
}
.linkButtonContainer:hover {
  svg {
    fill: $color-primary;
  }
}

.addQuestionnaireButton {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.4rem;
  background-color: var(--bui-colors-gray-100);
  color: $color-black;
  svg {
    fill: currentColor;
  }
}
.addQuestionnaireButton:hover {
  color: $color-primary;
}
