@use "../../styles/variables.scss" as *;

.root {
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.filter {
  flex-grow: 1;
}

@include max-screen($breakpoint-mobile) {
  .navigation {
    flex-wrap: wrap;
  }

  .filter {
    width: 100%;
  }

  .button {
    margin: 0 auto 2rem;
  }
}
