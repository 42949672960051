@use "../../../../styles/variables" as *;

.root {
  padding: 3.2rem;
}

.heading {
  font-size: 1.6rem;
  font-weight: bold;
  color: $color-black;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 3rem;
}

.help {
  color: $color-black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > a {
    color: $color-primary;
    fill: $color-primary;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1rem;
  }
}

.operationBox {
  border-radius: 0.5rem;
  background-color: #fafafa;
  margin-top: 2rem;
  padding: 2rem;
}

.boxHeading {
  font-size: 1.4rem;
  margin: 0 0 1.8rem;
  display: flex;
  flex-wrap: wrap;

  .boxHeadingTitle {
    margin: 0 1.6rem 0 0;
  }

  .notice {
    font-size: 1.2rem;
  }
}

.errorMessage {
  color: $color-notification;
}

.hasError {
  padding: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid $color-notification;
  background: #fbf0f0;
  box-sizing: border-box;
}
