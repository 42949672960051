.root {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & > * {
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
