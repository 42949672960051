@use "../../../styles/variables.scss" as *;

.root {
  --themeMainSectionPadding: 2rem;
}
.detail {
  margin-top: 0.5rem;
  margin-left: 2.2rem;
  width: 100%;
}
.subInfo {
  margin-top: 1rem;
  display: flex;
  font-size: 1.4rem;
  > div:not(:first-child) {
    margin-left: 2rem;
  }
  &__owner {
    display: flex;
    align-items: center;
    color: $color-black-lighten-2;
    font-weight: bold;
    > div:not(first-child) {
      margin-left: 1rem;
    }
  }
}

.boxText {
  background-color: $color-gray-darken-2;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  padding: 0.2rem 0.8rem 0.2rem 0.8rem;
}

.description {
  margin-top: 2rem;
  font-size: 1.4rem;
  word-break: break-all;
}

.thumbnail {
  padding: 0 1.2rem 0 1.2rem;
  img {
    width: 5.4rem;
    height: 5.4rem;
    object-fit: contain;
  }
}

.unitTitle {
  margin-top: 4rem;
}
.units {
  margin-top: 1.6rem;
}

.targetTitle {
  margin-top: 3.2rem;
}

.itemTitle {
  font-size: 1.4rem;
  font-weight: bold;
}

.mainSection {
  margin-top: 2rem;
}

.target {
  background-color: $color-gray-lighten-3;
  padding: 1.6rem;
  border-radius: 0.5rem;
  display: flex;
  margin-top: 1.6rem;
  justify-content: space-between;
  align-items: center;
}
.target__current {
  display: flex;
  .current__name {
    margin-left: 1rem;
  }
}

.otherSectionButtons {
  display: flex;
  > div:not(:first-child) {
    margin-left: 2rem;
  }
}

.editButton {
  width: 12rem;
}
