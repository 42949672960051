@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  margin: 2rem 0 1.2rem;
  position: relative;
}

.label {
  width: 8rem;
  line-height: 2.8rem;
  white-space: nowrap;
}

.tags {
  display: flex;
  margin-left: 1rem;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
}

.tag {
  display: inline-block;
  margin-bottom: 0.8rem;
  max-width: 100%;
}
.tagFilter {
  margin-left: 0.8rem;
}

@include max-screen($breakpoint-mobile) {
  .tagFilter {
    margin-left: 0;
  }
  .tags {
    display: block;
  }
}

@media only print {
  .root {
    display: none;
  }
}
