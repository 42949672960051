@use "../../../../styles/variables.scss" as *;

.form {
  display: flex;
}

.errors {
  margin-top: 2.5rem;
  display: block;
}

.input {
  max-width: 48rem;
}

.input__error {
  color: $color-notification;
  font-size: 1.2rem;
}

.btn {
  margin-left: 1rem;
}
