@use "../../../../styles/variables" as *;

.tagSelectorRoot {
  width: 100%;

  span {
    width: 100%;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.6rem;
}
.disabledRow {
  opacity: 0.5;
}
.selectedTag {
  flex-grow: 1;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.4rem;
  height: 4.6rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
}
.button {
  width: 10.8rem;
  margin-left: 0.8rem;
}
.heading {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0;
  margin-top: 0;
}
.tagName {
  margin-left: 0.8rem;
}

.label {
  @extend .row;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid var(--bui-colors-gray-300);
  &:first-child {
    margin-top: 0.8rem;
  }
  &:hover {
    color: $color-primary;
  }
}
.__labelDisabled {
  cursor: default;
  &:hover {
    color: $color-black;
    & > .icon {
      fill: $color-gray-darken-1;
    }
  }
}
.icon {
  fill: $color-gray-darken-1;
  &:hover {
    fill: $color-primary;
  }
}
.student {
  @extend .row;
  margin-left: 3rem;
  border-bottom: 1px solid var(--bui-colors-gray-300);
  height: 3.2rem;
  justify-content: flex-start;
  padding-left: 0.4rem;
}
.student__icon {
  width: 2rem;
  height: 2rem;
  fill: $color-icon-pink;
}
.student__name {
  margin-left: 0.8rem;
}

@include max-screen($breakpoint-mobile) {
}
