@use "../../../styles/variables.scss" as *;

.LearningMaterials {
  margin-top: 2.8rem;
}
.textNoResults {
  text-align: center;
}
.searchForm {
  width: 59.5rem;
}
@include max-screen($breakpoint-mobile) {
  .searchForm {
    width: 100%;
  }
}

.searchButton {
  width: 10rem;
}
