@use "../../../styles/variables.scss" as *;

.button {
  &:hover {
    fill: $color-primary;
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.input {
  display: none;
}
