@use "../../../styles/variables.scss" as *;

.root {
  max-height: 70%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /*
    FIXME:tooltip表示をする際にはoverflow-xが必要だが、
          CSS的にoverflow-yに引っ張られてvisibleにならない挙動のため、一旦
          tooltipの表示は諦めている
    overflow-x: visible;
  */
}

@include max-screen($breakpoint-mobile) {
  .root {
    max-height: none;
  }
}
