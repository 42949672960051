@use "../../../styles/variables.scss" as *;

.table {
  color: $color-black;
  font-size: $font-regular-m-font-size;
}

.contentNameColumn {
  min-width: 20.8rem;
}
.sizeColumn {
  width: 10rem;
}
.fileTypeColumn {
  width: 10rem;
}
