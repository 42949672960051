@use "../../../styles/variables.scss" as *;

.LectureTableToggleButton {
  padding: 1rem 2rem;
  color: $color-primary;
}

.LectureTableWrapper {
  padding: 0 2rem 2rem;
}

.LectureTable {
  border-radius: 0.4rem;
}

.LectureTable__headerColumn {
  padding: 1rem 1.6rem;
  height: auto;
  background-color: $color-gray-lighten-3;
  border-radius: 0.4rem 0.4rem 0 0;
}

.LectureTable__editLink {
  fill: #abb1c1;
  flex-shrink: 0;

  &:hover {
    fill: $color-primary;
  }
}

.LectureTable__column {
  padding: 1.4rem 2rem;
  width: 25%;
  height: auto;

  &:first-child {
    padding-left: 2rem;
  }

  &:nth-child(2) {
    border-right: 1px solid $color-gray-lighten-1;
  }
}
