@use "../../../styles/variables" as *;

.root {
  font-size: 1.4rem;
  border: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
  border-radius: 0.8rem;
  overflow: hidden;
}

.main {
  background: #fff;
}

.svg {
  fill: currentColor;
}

.content {
  padding: 1.6rem;
  .meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-regular-xs-font-size;
    color: $color-black-lighten-2;
    & > .right {
      display: flex;
      align-items: center;
    }
    .sectionName {
      margin-left: 0.8rem;
    }
  }
  .title {
    @include font-bold-xl();
  }
  .body {
    margin-top: 2.4rem;
  }
  .link {
    text-decoration: underline;
  }
  .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.2rem;
  }
  .commentIcon {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    fill: #abb1c1;
    color: $color-black-lighten-2;
  }
  .active {
    color: $color-primary;
    fill: $color-primary;
  }
}

.comments {
}

.footer {
  padding: 1.6rem;
  background-color: $color-gray-lighten-3;
  border-top: 1px solid $color-gray-lighten-1;
}
