@use "../../../../styles/variables" as *;

.root {
}

.error {
  color: #f15360;
}

@include max-screen($breakpoint-mobile) {
  .root {
    overflow-x: scroll;
  }
}
