@use "../../../../styles/variables" as *;

.fieldset {
  border: 1px solid var(--bui-colors-gray-300);
  border-radius: 0.4rem;
  margin-top: 2.4rem;
  padding: 1.6rem;
}
.fieldset__heading {
  font-size: $font-regular-m-font-size;
  margin: 0;
}
.reason {
  padding: 1rem 0;
  & > label {
    margin-left: 1.4rem;
  }
}
.button {
  display: block;
  margin: 2rem auto 0 auto;
}
.textarea {
  width: 100%;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1.6rem;
}
.error {
  color: $color-notification;
}
@include max-screen($breakpoint-mobile) {
}
