@use "../../../styles/variables.scss" as *;

.root {
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  overflow-y: scroll;
}

.list {
  min-height: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
}

@include max-screen($breakpoint-mobile) {
}
