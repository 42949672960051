@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.4rem 1.6rem;
}

.left {
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 1.6rem;
}

.name {
  @include font-bold-s();
}

.datetime {
  @include font-regular-xs();
  color: $color-black-lighten-2;
}

.content {
  @include font-regular-s();
  width: 26rem;
  margin-top: 0.8rem;
  line-height: 1.2;
  display: flex;
}

.content__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon__user {
  width: 2.8rem;
  height: 2.8rem;
  fill: $color-primary;
}

.icon__smarttag {
  width: 1.6rem;
  height: 1.6rem;
  fill: $color-icon-cyan;
}

.skill_name {
  font-weight: bold;
}
