.CheckboxColumGrid {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  align-items: center;
}
.NameColumnHeader {
  width: 18%;
  padding-left: 1.6rem;
}
.StudentJobColumnHeader {
  width: 8%;
}
.AttendanceStatusColumnHeader {
  width: 8%;
}
.UpdaterColumnHeader {
  width: 12%;
}
.UpdatedAtColumnHeader {
  width: 14%;
}
.AbsenseColumnHeader {
  width: 20%;
}
.StaffNoteColumnHeader {
  width: 20%;
}
