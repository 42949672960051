@use "../../../styles/variables.scss" as *;

.root {
  background: $color-gray-lighten-3;
}
.main {
  color: $color-black-lighten-2;
  fill: $color-black-lighten-2;
}

@include max-screen($breakpoint-mobile) {
  .root {
    font-size: 1.2rem;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .buttons button {
    margin-left: 0;
  }
}
