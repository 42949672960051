@use "../../../styles/variables.scss" as *;

.root {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: block;
  }
}
