@use "../../../styles/variables.scss" as *;

.root {
  text-align: left;
}
.line {
  margin: 2.4rem 0;
}
.list {
  max-width: 54rem;
  margin: 1.6rem auto;
  text-align: left;
}
.list,
.list > li {
  list-style: disc;
}
@include max-screen($breakpoint-mobile) {
}
