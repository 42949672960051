@use "../../../styles/variables" as *;

.root {
}

// フォーム
.searchForm {
  display: flex;
  align-items: flex-end;
}
.searchForm__type {
  width: 26.8rem;
}
.searchForm__type__select {
  height: 4.6rem;
}
.searchForm__name {
  flex-grow: 1;
  margin-left: 0.8rem;
  & > input {
    width: 100%;
    padding: 1.6rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    height: 4.6rem;
  }
}

.searchForm__button {
  width: 10.8rem;
  margin-left: 0.8rem;
}

// 検索結果件数
.searchResultCount {
  margin-top: 2rem;
}

// 検索結果一覧
.searchResult {
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-top: 1.6rem;
}
.searchResult__learningMaterial {
  background: #fff;
  border-bottom: 1px solid $color-gray-lighten-1;
  display: flex;
  align-items: center;
  padding: 1.6rem 3.2rem 1.6rem 2rem;
  &:last-child {
    border-bottom: 1px solid $color-gray-lighten-1;
  }
}
.searchResult__learningMaterial > img {
  height: 6rem;
  object-fit: contain;
}
.searchResult__learningMaterial__name {
  flex-grow: 1;
  margin-left: 2.8rem;
  font-size: $font-regular-m-font-size;
  font-weight: normal;
}
.searchResult__learningMaterial__button {
  width: 15rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  flex-shrink: 0;
}
.errorMessage,
.errorMessage > a,
.errorMessage > a:hover {
  color: $color-notification;
}
.linkInEmptyResultMessage {
  text-decoration: underline;
  font-weight: bold;
}
@include max-screen($breakpoint-mobile) {
  .searchResult__learningMaterial {
    flex-wrap: wrap;
  }
  .searchResult__learningMaterial > img {
  }
  .searchResult__learningMaterial__name {
    margin-left: 1.6rem;
    width: 0;
  }
  .searchResult__learningMaterial__button {
    width: 100%;
    margin-top: 1.6rem;
  }
}
