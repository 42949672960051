@use "../styles/variables.scss" as *;
.level15 {
  background-color: $color-analytics-level-15;
}

.level14 {
  background-color: $color-analytics-level-14;
}

.level13 {
  background-color: $color-analytics-level-13;
}

.level12 {
  background-color: $color-analytics-level-12;
}

.level11 {
  background-color: $color-analytics-level-11;
}

.level00 {
  background-color: $color-analytics-level-00;
}

.level01 {
  background-color: $color-analytics-level-01;
}

.level02 {
  background-color: $color-analytics-level-02;
}

.level03 {
  background-color: $color-analytics-level-03;
}

.level04 {
  background-color: $color-analytics-level-04;
}

.level05 {
  background-color: $color-analytics-level-05;
}
