@use "../../styles/variables.scss" as *;

.titleViewContainer {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}
.title {
  margin-left: 1.2rem;
  font-weight: bold;
}

.flexSplitter {
  margin-left: auto;
}

.resourceOptionListContainer {
  margin-top: 1.6rem;
  padding-left: 0.2rem;
}

.resourceOption {
  display: block;
}
.resourceOption:not(:first-of-type) {
  margin-top: 1rem;
}
.resourceOptionName {
  margin-left: 0.4rem;
}
.linkButtonWrapper {
  line-height: 0;
  width: 2.4rem; // リソースがない場合はアイコン分の空白を確保するため
}
.linkButtonWrapper:not(:last-of-type) {
  margin-right: 0.4rem;
}

.addButton {
  border-radius: 3px;
  background-color: $color-gray-lighten-3;
}
