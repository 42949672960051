@use "../../../styles/variables.scss" as *;

.root {
  display: none;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: flex;
    justify-content: space-between;
    margin: 2rem 2rem 2rem 2.4rem;
    height: 2.4rem;
  }

  .opened {
  }

  .button {
    justify-content: center;
    align-items: center;
    display: none;
  }

  .opened .button {
    display: flex;
  }

  .logoTxt {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}
