@use "../../../../styles/variables.scss" as *;

.root {
  width: 34rem;
  border-right: 1px solid $color-gray-lighten-1;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: none;
  }

  .focused {
    display: block;
    width: 100%;
    height: 100%;
  }
}
