@use "../../../../styles/variables.scss" as *;

.root {
  overflow-x: scroll;
}
.table {
  margin-top: 1.6rem;
  color: $color-black;
  font-size: $font-regular-m-font-size;
}
.noContent {
  text-align: center;
  margin-top: 1.6rem;
}
.iconColumn {
  width: 9.2rem;
  min-width: 9.2rem;
  padding: 0.7rem 0;
}
.iconColumn:first-child {
  padding-left: 0;
}
.thumbnail {
  object-fit: contain;
  width: 4rem;
  height: 5.2rem;
}
.iconColumn,
.studentCount {
  color: $color-black-lighten-2;
}
.studentCount__text {
  display: inline-block;
  margin-left: 0.8rem;
}
.courseName {
  min-width: 20.8rem;
  padding: 1.6rem 0;
}
.courseName__name {
  font-weight: bold;
  color: $color-primary;
  &:hover {
    color: $color-black-lighten-2;
  }
}
.courseName__durationLabel {
  @include font-bold-s();
  color: $color-black;
}
.courseName__durationValue {
  @include font-regular-s();
  color: $color-black;
}
.sharedLabel {
  margin-top: 0.8rem;
}
.sharedLabel__tag {
  background: $color-gray-darken-2;
  color: #fff;
  border-radius: 2px;
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
}
.sharedLabel__sectionName {
  margin-left: 0.8rem;
  color: #878ea2;
  font-weight: bold;
  font-size: $font-regular-xs-font-size;
}
.actionColumn {
  width: 9.2rem;
  color: $color-black-lighten-2;
  padding: 0;
  svg {
    fill: $color-black-lighten-2;
  }
  &:hover {
    svg {
      fill: $color-primary;
    }
  }
}
