@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  flex-wrap: wrap;
}
.imageWrapper {
  position: relative;
}
.image {
  width: 14rem;
  height: 14rem;
  object-fit: contain;
  background: $color-gray-lighten-3;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  margin-top: 1.6rem;
  border: 1px solid $color-gray-lighten-1;
  &:first-child {
    margin-left: 0;
  }
}
.icon {
}
.deleteIcon {
  position: absolute;
  right: 1rem;
  top: 2rem;
}
