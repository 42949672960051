@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 1.6rem;
}

.input {
  width: 50%;
  height: 4.5rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 1.6rem;
}

.btn {
  padding: 0 3.2rem;
  margin-left: 0.8rem;
}

@include max-screen($breakpoint-mobile) {
  .input {
    width: 100%;
    margin: 0;
  }

  .btn {
    width: 100%;
    margin: 1rem 0 0;
  }
}
