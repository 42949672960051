@use "../../../styles/variables.scss" as *;

.modalContainer {
  max-width: 58rem;
}

.modalHeaderContainer {
  margin: 1rem 0;
}

.studentName {
  text-decoration: underline;
  color: $color-primary;
  margin-right: 0.4rem;
}

.summary {
  text-decoration: underline;
  color: $color-black;
}

.fromLectureAttendanceContainer {
  background-color: $color-gray-lighten-3;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 0.8rem 0;
  color: $color-black;
}

.toLectureAttendanceContainer {
  border: 1px solid $color-gray-lighten-1;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 0.8rem 0;
  color: $color-black;
}

.calendarIcon {
  fill: #abb2c1;
}

.arrowDownIcon {
  fill: #abb2c1;
  width: 4rem;
  height: 4rem;
}

.buttonContainer {
  margin-left: 1.6rem;
}

.deleteButton {
  font-size: $font-regular-m-font-size;
  width: 8rem;
}

.u-margin-right-0\.5 {
  margin-right: 0.5rem;
}
.u-margin-left-0\.5 {
  margin-left: 0.5rem;
}
.u-margin-left-2 {
  margin-left: 2rem;
}
