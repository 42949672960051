@use "../../../styles/variables.scss" as *;

.Breadcrumbs {
  display: flex;
  align-items: center;
  font-size: $font-regular-m-font-size;
  font-weight: bold;
  color: $color-gray-darken-2;
  svg {
    fill: currentColor;
  }
}
.BreadcrumbItem {
  svg {
    display: block; // 横に並べた時にiconの下にできてしまう隙間を埋めるため
  }
}
.isLimitedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
}
.BreadcrumbItemLink {
  color: $color-primary;
  text-decoration: none;
}

.BreadcrumbItem--lastItem {
  color: $color-gray-darken-2;
}
.BreadcrumbItem__link {
  a {
    color: $color-primary;
    text-decoration: underline;
  }
}
