@use "../../../styles/variables.scss" as *;

.root {
  height: 3rem;
  list-style-type: none;
  border: 1px solid #dde0e8;
  border-radius: 0.4rem;
  display: inline-flex;
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}
.tab {
  display: flex;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #dde0e8;
  color: $color-gray-darken-3;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background: $color-black-lighten-2;
  }
}
.tab:last-child {
  border-right: 0;
}
@include max-screen($breakpoint-mobile) {
  .root {
    width: 100%;
  }
  .tab {
    flex-grow: 1;
  }
}
