@use "../../../styles/variables.scss" as *;

.iconWrapper {
  fill: #fff;
  display: inline-block;
  cursor: pointer;
  &.redTheme {
    background: $color-notification;
  }
  &.grayTheme {
    background: #abb1c1;
    &:hover {
      background: $color-gray-normal;
    }
  }
  &.smallSize {
    width: 1.6rem;
    height: 1.6rem;
  }
  &.largeSize {
    width: 2.6rem;
    height: 2.6rem;
  }
}

.icon {
  &.smallSize {
    width: 1.6rem;
    height: 1.6rem;
  }
  &.largeSize {
    width: 2.6rem;
    height: 2.6rem;
  }
}
