@use "../../../styles/variables.scss" as *;

.root {
  @include font-regular-s();
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-bottom: 1px solid $color-gray-lighten-3;
  background: $color-gray-lighten-3;
  padding: 1rem 0 1rem 0.5rem;
}

.root:hover {
  background: $color-gray-lighten-2;
  border-bottom: 1px solid $color-gray-lighten-2;

  .iconPlus {
    fill: $color-black-lighten-2;
  }
}

.left {
  margin-left: 2.2rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 1.6rem;
  width: 100%;
  color: $color-black-lighten-2;
}

.iconPlus {
  width: 2.4rem;
  height: 2.4rem;
  fill: $color-gray-darken-1;
}
