@use "../../../styles/variables.scss" as *;

.root {
  padding-left: 1.6rem;
  padding-right: 1.4rem;
  border-bottom: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $mobile-header-notifications-heading-height;
}
.title {
  @include font-bold-m;
  padding: 0;
  display: inline-block;
  margin: 0;
}
.link {
  @include font-regular-xs();
  color: $color-primary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
