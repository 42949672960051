@use "../../../../styles/variables.scss" as *;
.distributeTagModal__submit {
  margin: 2rem auto 1.6rem auto;
  text-align: center;
}
.distributeTagModal__tag {
  margin-top: 1rem;
}
.distributeTagModal__studentsLabel {
  display: flex;
  margin-top: 2rem;
}
.label.distributeTagModal__studentsLabel--subNote {
  margin-left: 2rem;
  font-weight: normal;
}

.distributeTagModal__students {
  margin-top: 1rem;
}

.label {
  font-size: 1.2rem;
  color: $color-gray-darken-2;
  font-weight: bold;
}

.modal {
  width: 98rem;
  max-width: 90%;
}

.studentsView {
  height: 42.8rem;
  overflow: hidden;
}

.submitButton {
  width: 14.8rem;
}
