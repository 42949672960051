@use "../../../../styles/variables.scss" as *;
.u-margin-right-1 {
  margin-right: 1rem;
}
.u-margin-right-0\.5 {
  margin-right: 0.5rem;
}
.u-margin-left-0\.5 {
  margin-left: 0.5rem;
}
.u-space-y-2 > * {
  margin: 2rem 0;
}
.scheduleDetailModalBox {
  margin-left: 0.4rem;
}

.Title {
  font-size: $font-regular-xl-font-size;
  margin: 1.2rem 0;
  display: grid;
  grid-template-columns: 2.6rem 1fr;
  align-items: center;
}
.Title__sectionName {
  margin-top: 0.8rem;
  grid-row: 2;
  grid-column: 2;
}
.Title__summary {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.Locale {
  display: flex;
  align-items: center;
  & > svg {
    fill: #abb2c1;
    margin-left: -0.6rem;
    margin-right: 1rem;
  }
}

.Description {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  & > svg {
    fill: #abb2c1;
    margin-left: -0.6rem;
    margin-right: 1rem;
  }
}
.mutedText {
  color: #a3a7b6;
}

.StudySchedule {
  display: flex;
  margin-left: 3.2rem;
}
.StudySchedule__learningMaterial {
  display: flex;
  margin-right: 1.4rem;
  & > img {
    width: 3.6rem;
    height: 5.8rem;
    object-fit: contain;
  }
}

.ScheduleTime {
  display: flex;
  align-items: flex-start;
  & > svg {
    fill: #abb2c1;
    margin-left: -0.6rem;
    margin-right: 1rem;
  }
}

.icon-adjust-margin {
  // 生徒カレンダーの詳細モーダルでは、左側のアイコンの位置が微妙にネガティブマージンで調整されてるので、それに倣う
  margin-left: -0.6rem;
  margin-right: 1rem;
}
