@use "../../../styles/variables.scss" as *;

// align
.alignLeft {
  text-align: left;
}
.alignCenter {
  text-align: center;
}
.alignRight {
  text-align: right;
}
// bold
.bold {
  font-weight: bold;
}
// size
.xlSize {
  font-size: $font-regular-xl-font-size;
}
.lgSize {
  font-size: $font-regular-l-font-size;
}
.mdSize {
  font-size: $font-regular-m-font-size;
}
.smSize {
  font-size: $font-regular-s-font-size;
}
.xsSize {
  font-size: $font-regular-xs-font-size;
}

.whiteSpaceNormal {
  white-space: normal;
}
.whiteSpaceNowrap {
  white-space: nowrap;
}
.whiteSpacePreLine {
  white-space: pre-line;
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.lineClamp1 {
  -webkit-line-clamp: 1;
}
.lineClamp2 {
  -webkit-line-clamp: 2;
}
.lineClamp3 {
  -webkit-line-clamp: 3;
}
