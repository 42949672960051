@use "../../../styles/variables" as *;

.root {
}
.row {
  display: flex;
  justify-content: space-between;
}
.studentStatus {
  opacity: 0.5;
  font-weight: bold;
}
.learningMaterials {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6rem;
}

@include max-screen($breakpoint-mobile) {
}
