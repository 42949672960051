@use "../../styles/variables.scss" as *;
.root {
  display: flex;
  flex-direction: column;
  // height: 100%;
  height: calc(
    100vh - 11.4rem
  ); // header(6.4) + margin1(1) + margin2(0.8) + margin3(3.2)
}

.resourceConditionSelector {
  flex: 0 0 23.2rem;
  max-width: 23.2rem;
  padding: 1.6rem;
  border-right: solid 1px $color-gray-lighten-1;
  overflow: auto;
}
.resourceConditionSelector > :not(:first-of-type) {
  margin-top: 3.2rem;
}
.scheduleListWrapper {
  margin-top: 1rem;
  flex: 1 1 auto;
  overflow: hidden;
  background: #fff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  display: flex;
  height: 100%;
}
.searchResultLoaderWrapper {
  flex: 1;
}
.menuWrapper {
  margin-top: 1rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    height: calc(
      100vh - 17.4rem
    ); // sp-header(6) + header(6.4) + margin1(1) + margin2(0.8) + margin3(3.2)
  }
  .resourceConditionSelector {
    overflow: initial;
  }
  .scheduleList__result {
    overflow: initial;
  }
  .scheduleListWrapper {
    overflow: auto;
  }
}

@media print {
  .resourceConditionSelector {
    display: none;
  }
  .scheduleListWrapper {
    overflow: initial;
  }
}
