@use "../../../styles/variables.scss" as *;

.avatarWithEnterExitStatus {
  display: inline-grid; // 親がtext-align:centerでセンタリングしているため
  grid-template-rows: 1fr 2.2rem;
  margin-bottom: 1.6rem;
  & > :first-child {
    grid-column: 1/2;
    grid-row: 1/-1;
    width: 6.8rem;
    height: 6.8rem;
    fill: $color-icon-pink;
  }
}
.badge {
  grid-column: 1/2;
  grid-row: 2/3;
  background-color: #5fd156;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  border: 0.3rem solid #fff;
  justify-self: end;
  margin-right: -0.6rem;
}
