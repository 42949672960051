@use "../../styles/variables.scss" as *;

.tableControl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto 1fr;
}
.studentControlMargin {
  margin: 1rem;
}
.lectureControlMargin {
  margin: 2rem;
}

.tableControl div:last-child {
  margin-left: auto;
}

.tableControlLeft {
  display: flex;
  align-items: center;
}
.tableControlRight {
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 1.6rem;
  }
}

@include max-screen($breakpoint-mobile) {
  .tableControl {
    display: block;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
  }

  .tableControlRight {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
