@use "../../../styles/variables.scss" as *;

.termLink {
  color: $color-primary;
  font-weight: bold;
}
.errorMessage {
  color: $color-notification;
  margin-top: 0.8rem;
}
