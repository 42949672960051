@use "../../../../styles/variables.scss" as *;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  padding: 1.6rem;
  background: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid var(--bui-colors-gray-300);
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

@include max-screen($breakpoint-mobile) {
}
