@use "../../../styles/variables.scss" as *;

.box {
  margin-top: 1.8rem;
  box-sizing: border-box;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  background-color: #fff;
}

.row {
  border-bottom: 1px solid $color-gray-lighten-1;
  padding: 2rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }
}

.left {
}

.right {
  white-space: nowrap;
  margin-left: 1rem;
}

.heading {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 0 1.4rem 0;
}

.title {
  margin: 0 2.4rem 0 0;
  font-size: 1.6rem;
  font-weight: bold;
}

.help {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: $color-primary;
  fill: $color-primary;
  font-weight: bold;
}

.note {
  font-size: 1.2rem;
}
