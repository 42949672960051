@use "../../../styles/variables.scss" as *;

.receive__enabled {
  background-color: var(--bui-colors-green-100);
}
.receive__disabled {
  background-color: var(--bui-colors-gray-100);
}
.icon__enabled {
  color: var(--bui-colors-green-400);
}
.icon__disabled {
  color: var(--bui-colors-gray-400);
}
