@use "../../../../styles/variables.scss" as *;

.locationHeader {
  font-size: $font-regular-m-font-size;
  font-weight: bold;
}
.locationInputContainer {
  background-color: $color-gray-lighten-3;
  padding: 2.5rem 2rem 2rem;
  border-radius: 0.5rem;
  margin-top: 1.6rem;
  color: $color-black;
}
.inputContainer {
  width: 34.5rem;
  margin-left: 2rem;
}
.googlemapButtonContainer {
  margin-left: 2rem;
}
.googlemapButton {
  width: 14.2rem;
}
.selectContainer {
  width: 27.3rem;
  margin-left: 3.6rem;
  font-size: 1.6rem;
}
.locationLabel {
  font-size: 1.6rem;
  white-space: nowrap;
}
.description {
  margin-left: 1.6rem;
}
.submitContainer {
  text-align: right;
}
.submit {
  width: 12rem;
}
.text {
  white-space: nowrap;
}
.errorText {
  margin-left: 10rem;
}
.classroomNameLabel {
  color: $color-gray-darken-2;
  font-weight: 700;
}
