@use "../../../../styles/variables.scss" as *;

.heading {
  margin: 2rem 0 0.8rem 0;
  font-size: $font-regular-m-font-size;
}
.heading:first-child {
  margin-top: 0;
}
.name {
  color: $color-primary;
}

.radio {
  margin: 0 5rem 1rem 0;
}
.title::placeholder {
  opacity: 1;
}
.title:focus::placeholder {
  opacity: 0;
}
.password {
  margin-left: 2.4rem;
}
.message {
  position: relative;
}
.message__length {
  color: #8c9aa8;
  position: absolute;
  top: -2.4rem;
  right: 0;
}
.button {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}
.startDate {
  display: flex;
}
.datePicker {
  flex-grow: 1;
  margin-right: 1rem;
}
.time {
  flex-grow: 1;
  border: 1px solid $color-gray-normal;
  border-radius: 0.5rem;
}
.duration {
  width: 8rem;
}
.fieldContainer:first-child {
  margin-top: 0;
}
.fieldContainer {
  margin-top: 1.6rem;
}
