@use "../../../styles/variables.scss" as *;

.root {
}

.attachment {
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
}
.attachment__filename {
  margin-left: 1.4rem;
}
.attachment__link {
  display: inline-flex;
  align-items: center;
  color: $color-primary;
  fill: $color-primary;
  font-weight: normal;
  margin-left: 1.3rem;
}
