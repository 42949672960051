@use "../../../../styles/variables.scss" as *;

.studentNameColumn {
  display: grid;
  grid-template-columns: 2.8rem 1fr max-content;
  align-items: center;
  & > :first-child {
    text-align: right;
  }
  & > :nth-child(2) {
    margin-left: 4rem;
    text-align: left;
  }
  & > :nth-child(3) {
    align-self: center;
    width: 6.4rem;
  }
}
