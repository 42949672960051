@use "../../../styles/variables.scss" as *;

.tableWrapper {
  overflow-x: scroll;
}
.notfound {
  margin-top: 1.6rem;
  text-align: center;
}

.label {
  font-size: $font-regular-xs-font-size;
  font-weight: bold;
}

.checkOnlyActiveStudySchedule {
  display: flex;
  height: 100%;
  align-items: flex-end;
}
