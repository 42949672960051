@use "../../../styles/variables.scss" as *;

.DropdownMenuTrigger {
  // button要素にデフォルトで付く余白をリセット
  padding: 0;
  line-height: 0;
}
.DropdownMenuItem {
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  // ブラウザがつけてくるoutlineをhover時のみリセット
  &:hover:focus-visible {
    outline: 0;
  }
  &:active {
    background: var(--bui-colors-gray-400);
  }
}
.DropdownMenuItemSelected,
.DropdownMenuItemSelected:hover,
.DropdownMenuItemSelected:active {
  background: var(--bui-colors-blue-400);
  color: var(--bui-colors-white);
}
.DropdownMenuContent {
  background: #fff;
  padding: 0.8rem 0;
  box-shadow: 0 2px 10px 0 rgba(29, 40, 50, 0.1);
  border: 1px solid color-gray-lighten-1;
  border-radius: 0.5rem;
}
