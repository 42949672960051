@use "../../../styles/variables.scss" as *;

.root {
  width: 100%;
  height: $mobile-global-header-height;
  background: $color-primary;
  color: $color-primary-lighten-1;
  padding: 1.6rem 1.6rem 1.6rem 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  margin-right: 2.4rem;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0px;
}
@include max-screen($breakpoint-tablet-960) {
  .right {
    min-width: auto;
  }
}

.notificationIcon {
  margin-left: 3.778rem;
}

.help {
  border-radius: 50%;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-left: 1.6rem;

  &:hover {
    background: $color-primary-lighten-2;
  }
}

.iconHelp {
  fill: $color-primary-lighten-1;
}

.headerAccount {
  margin-left: 4.4rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    padding: 1rem 1.6rem;
  }

  .left {
    width: 25rem;
    flex-grow: 0;
    margin-right: 0;
  }

  .right {
    display: flex;
    flex-grow: 1;
  }

  .notificationIcon {
    margin-left: 0;
  }

  .notificationIcon + .notificationIcon {
    margin-left: 2.6rem;
  }

  .help {
    display: none;
  }

  .headerAccount {
    display: none !important;
  }
}

// iPhone5などは幅が狭くて通知アイコンが表示しきれないので特別対応
@include max-screen(374px) {
  .notificationIcon + .notificationIcon {
    margin-left: 1.4rem;
  }
}
