.PieChartWrapper {
  margin-top: 1.6rem;
  height: 27.5rem;
}
.Summary {
  padding: 1.6rem 2rem 2rem;
}

@media only print {
  // NOTE: 印刷では中央からずれて表示されてしまうため中央寄せにする
  .PieChartWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
