@use "../../../styles/variables.scss" as *;

.root {
  @include font-regular-s();
  background: $color-gray-lighten-3;
  color: $color-black-lighten-1;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    /* for CSS変数をサポートしていないブラウザ */
    height: calc(
      100vh - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-menu-footer-height} - #{$mobile-header-notifications-heading-height}
    );

    /* for CSS変数をサポートしているブラウザ */
    height: calc(
      var(--innerHeight) - #{$mobile-sp-global-header-height} - #{$mobile-global-header-height} -
        #{$mobile-header-menu-footer-height} - #{$mobile-header-notifications-heading-height}
    );
  }
}
