.btnChoose {
  margin-top: 2rem;
}
.tableFile {
  overflow-x: auto;
  margin-top: 2rem;
}

.button--choose {
  height: 3.6rem;
}

.submitButton {
  width: 13.8rem;
}
