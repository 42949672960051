@use "../../../styles/variables.scss" as *;

.row {
  width: 100%;
  display: flex;
  .input {
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.input_group {
  margin-top: 1.6rem;
  &:first-child {
    margin-top: 0;
  }
}

.input {
  display: block;
  width: 100%;
}

.errorMessage {
  @include font-regular-xs();
  color: $color-notification;
}

.buttonGroup {
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;
}
