@use "../../../styles/variables.scss" as *;
.detailWrapper {
  margin-left: 0.4rem;
}

.u-flex-align-center {
  display: flex;
  align-items: center;
}
.MembersLabel__summary {
  padding: 0;
  margin: 0;
}
.MemberList {
  display: grid;
  grid-template-columns: repeat(2, 20rem);
  gap: 1rem;
  margin-left: 2.8rem;
  margin-bottom: 4rem;
}
@include max-screen($breakpoint-mobile) {
  .MemberList {
    display: block;
  }
  .MemberList > :not(:first-of-type) {
    margin-top: 1rem;
  }
}

.Member > * + * {
  margin-left: 1rem;
}
.Member__name {
  flex: 1;
}

.LectureMemberSummary {
  margin-top: 1rem;
  margin-left: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
