@use "../../../../styles/variables.scss" as *;

.root {
  overflow: auto;
  max-height: 100%;
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
  width: 100%;
  &--primary {
    color: $color-primary;
    fill: $color-primary;
  }
  &--red {
    color: $color-notification;
    fill: $color-notification;
  }
  &--green {
    color: #78b12b;
    fill: #78b12b;
  }
  &__header {
    background: $color-gray-lighten-3;
    th {
      @include font-bold-s();
      color: $color-black-lighten-2;
      text-align: center;
      height: 3rem;
      width: 16rem;
      border: 1px solid var(--bui-colors-gray-300);
      border-bottom: 0;
      + th {
        border-left: 0;
      }
    }
    &--wide {
      width: auto;
    }
  }
  &__row {
    td {
      height: 4rem;
      padding: 0 1.6rem;
      border: 1px solid var(--bui-colors-gray-300);
      border-bottom: 0;
      text-align: center;
      + td {
        border-left: 0;
      }
    }
  }
  tr:first-child {
    th {
      &:first-child {
        border-top-left-radius: 0.8rem;
      }
      &:last-child {
        border-top-right-radius: 0.8rem;
      }
    }
  }
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 0.8rem;
      }
      &:last-child {
        border-bottom-right-radius: 0.8rem;
      }
    }
  }
}
.courseRow {
  td {
    border-top: 3px double var(--bui-colors-gray-300);
    border-bottom: 1px solid var(--bui-colors-gray-300);
  }
  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
  }
  &__title {
  }
  &__submission {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__completeRate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__score {
  }
}
.unitRow {
  &__title {
    color: $color-primary;
    fill: $color-primary;
    cursor: pointer;
    &:hover {
      color: $color-black-lighten-2;
      fill: $color-black-lighten-2;
    }
  }
}
.contentRow {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__verticalline {
    position: absolute;
    background-color: $color-gray-lighten-1;
    width: 0.2rem;
    top: 0;
    left: 2.8rem;
    height: 100%;
    &--half {
      height: 50%;
    }
  }
  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.8rem;
    gap: 1rem;
  }
  &__link {
    margin-left: 1.6rem;
    display: flex;
    align-items: flex-end;
    color: $color-primary;
    fill: $color-primary;
    &:hover {
      color: $color-black-lighten-2;
      fill: $color-black-lighten-2;
    }
  }
}
