@use "../../styles/variables.scss" as *;

.root {
}

.tableWrapper {
  overflow-x: scroll;
}

.table {
  width: 100%;
  background: #fff;
  fill: #a1a5ae;
  border-collapse: separate;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
  border-spacing: 0;
  word-break: break-word;

  & th {
    padding: 0.8rem 1.4rem;
    font-weight: bold;
    color: $color-black-lighten-2;
    font-size: $font-regular-xs-font-size;
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;

    &:first-child {
      text-align: left;
    }
  }

  & td {
    border-top: 1px solid $color-gray-lighten-1;
    padding: 1.6rem 2rem;
    white-space: nowrap;
  }

  .name {
    width: 11.6rem;
    min-width: 11.6rem;
    box-sizing: border-box;
    word-break: keep-all;
    white-space: normal;
    text-decoration-line: underline;
  }

  .passcodeCell,
  .lineCodeCell,
  .guardianEmailCell {
    padding-right: 1rem;
    text-align: center;
  }

  .passcodeStatusCell,
  .lineCodeStatusCell,
  .guardianEmailStatusCell {
    padding-left: 0;
    text-align: center;
  }

  .dropdownCell {
    padding-left: 0;
  }
  .jobCell {
    text-align: center;
  }
}

.status {
  padding: 0.5rem 0.8rem;
  background: var(--bui-colors-gray-300);
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  word-break: keep-all;
  color: $color-black-lighten-2;
}

.passcodeStatus {
  @extend .status;

  &.active {
    background: $color-primary;
    color: #fff;
  }
}

.lineCode {
  display: inline-block;
  word-break: keep-all;
}

.guardianStatus {
  @extend .status;

  &.active {
    background: rgba($color-light-green, 0.85);
    color: #fff;
  }
}

.dropdown {
  background: #fff;
  padding: 1rem 0;
  border: 1px solid $color-gray-lighten-1;
  box-shadow: 0 2px 10px 0 rgba(29, 40, 50, 0.1);
  border-radius: 0.5rem;
}

.center {
  text-align: center;
}
