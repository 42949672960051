@use "../../../styles/variables.scss" as *;

.jobHumanLabel {
  height: 2.4rem;
  background: #fff;
  color: $color-black-lighten-2;
  border: 1px solid $color-gray-darken-1;
  border-radius: 1.2rem;
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
}
