@use "../../../styles/variables.scss" as *;

/* 高さ、色はフレキシブルなデザインなので、親のCSSから指定させる */
:root {
  --themeProgressBarHeight: 1.4rem;
  --themeProgressBarTrackColor: #dde0e8;
  --themeProgressBarTrackFillColor: #629af7;
}

.track {
  height: var(--themeProgressBarHeight);
  border-radius: 0.7rem;
  width: 100%;
  position: relative;
}
.fillTrack {
  display: inline-block;
  height: var(--themeProgressBarHeight);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: calc(var(--themeProgressBarHeight) / 2);
  background-color: var(--themeProgressBarTrackFillColor);
}

.track--solid {
  background-color: var(--themeProgressBarTrackColor);
}
.track--outline {
  border: 2px solid var(--themeProgressBarTrackColor);
  background-color: transparent;
}
