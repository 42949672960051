@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.name {
  color: $color-black;
  text-decoration: underline;
  margin-left: 0.8rem;
}

.icon {
  fill: $color-icon-pink;
  width: 2.8rem;
  height: 2.8rem;
}

.createdAt {
  margin-left: 1.6rem;
  font-size: 1.2rem;
}

.editLink,
.staffName,
.ellipsisButton {
  fill: $color-black-lighten-2;
  color: $color-black-lighten-2;
}

.staffName {
  font-size: 1.2rem;
  margin-left: 0.8rem;
  margin-right: 1.4rem;
}

.ellipsisButton {
  position: relative;

  &:hover {
    fill: $color-primary;
  }
}

.dropdown {
  position: absolute;
  top: 0;
  right: 3.2rem;
  width: max-content;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(29, 40, 50, 0.1);
  background: #fff;
}

.dropdown__list {
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.dropdown__item {
  display: flex;
  align-items: center;
  color: $color-black;
  fill: $color-black;
  padding: 1rem 1.6rem;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: $color-gray-lighten-2;
    color: $color-primary;
    fill: $color-primary;
  }
}

.dropdown__icon {
  width: 2rem;
  height: 2rem;
}

.dropdown__label {
  margin-left: 0.8rem;
  word-break: keep-all;
}

.pin {
  cursor: pointer;
  fill: $color-primary;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: block;
  }

  .createdAt {
    margin-left: 1.6rem;
    font-size: 1.2rem;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  .right {
    justify-content: flex-end;
    margin-right: -1.6rem;
  }
}
