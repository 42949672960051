@use "../../../styles/variables.scss" as *;

.heading {
  display: flex;
  font-size: 1.8rem;
  margin-top: 0.8rem;
  line-height: 1;
  align-items: center;
}
.helpIcon {
  fill: $color-primary;
  margin-left: 0.4rem;
}
.fieldsetHeading {
  font-size: $font-regular-m-font-size;
  margin-top: 0;
  width: 14rem;
  flex-shrink: 0;
}
.argumentsFieldsetContainer {
  background: $color-gray-lighten-3;
  padding: 2rem;
  border-radius: 0.5rem;
}
.argumentsFieldsetHeading {
  font-size: $font-regular-m-font-size;
}
.argumentsField {
  position: relative;
}
.argumentsFieldsetRow {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
}
.argumentsFieldsetRow__text {
  @extend .argumentsField;
  margin: 0 1.6rem;
}
.argumentsFieldsetRow__textInline {
  display: inline;
  margin: 0;
}
.argumentsFieldsetRow__select {
  @extend .argumentsField;
}
.fieldsetContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 2.4rem;
}
.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6rem;
}
.submit__button {
  width: 12rem;
}

@include max-screen($breakpoint-mobile) {
  .argumentsFieldsetRow {
    display: block;
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .argumentsFieldsetRow__select {
    max-width: 100%;
    margin: 0.8rem 0;
  }
  .fieldsetContainer {
    display: block;
    margin-top: 2.4rem;
  }
  .fieldset {
    margin-left: 0;
  }
}
