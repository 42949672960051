@use "../../../../styles/variables.scss" as *;

.StudentCourseResult {
  height: calc(100vh - 22rem);
  &__container {
    margin-top: 0.8rem;
    background-color: #fff;
    border: 1px solid $color-gray-lighten-1;
    border-radius: 8px;
    padding: 2.4rem 1.6rem;
  }
}
