@use "../../../../styles/variables" as *;

.listContainer {
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  overflow-y: scroll;
}

.list {
  min-height: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
}

.message {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.message__header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    display: inline-flex;
    margin-left: 1rem;
    align-items: center;
  }

  & > :first-child {
    margin-left: 0;
  }
}

.content {
  padding: 1.2rem 1.6rem;
  word-break: break-all;
}

.message__content {
  @extend .content;
  background-color: $color-gray-lighten-2;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  margin-left: 3.8rem;

  > a {
    color: $color-primary;
    text-decoration: underline;
  }
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
}

.iconWrapper {
  fill: #abb1c1;
}

.name {
  font-weight: bold;
}

.datetime {
  font-size: $font-regular-xs-font-size;
  color: $color-black-lighten-2;
}

.image {
  width: 18rem;
  height: 18rem;
  border-radius: 0 0.8rem 0.8rem 0.8rem;
  object-fit: cover;
  margin-top: 0.8rem;
  margin-left: 3.2rem;
}

@include max-screen($breakpoint-mobile) {
  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }
}
