.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.button {
  width: 24rem;
}
.description {
  margin-top: 3.2rem;
}
