@use "../../../styles/variables.scss" as *;

.root {
  text-align: center;
  margin-bottom: 2rem;
}

.fullName {
  color: $color-primary;
  font-size: $font-regular-l-font-size;
  font-weight: bold;
  white-space: normal;
  max-width: 22.4rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .studentEditBtn {
    color: $color-primary;
    font-weight: bold;
    padding-left: 0.8rem;

    &:hover {
      color: $color-gray-darken-2;
    }
  }
}

.job {
  margin-top: 1rem;
}

.messageLink {
  height: 3.2rem;
  border-radius: 1.6rem;
  margin-top: 1.6rem;
}

.iconAvatar {
  width: 6.8rem;
  height: 6.8rem;
  fill: $color-icon-pink;
  margin-bottom: 1.6rem;
}

.iconRecord {
  fill: #abb1c1;
}

@include max-screen($breakpoint-mobile) {
}
