@use "../../../styles/variables.scss" as *;

// Container
.container {
  position: relative;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3; // NOTE: 画面側を操作できないように覆って、ドロップダウンは操作したいのでこの値
}

// List
.list {
  display: none;
  position: absolute;
}

.listVisible {
  .list {
    display: inline-flex;
    z-index: 4;
  }
}

// Item
.item {
}

.item__link {
  display: flex;
  align-items: center;
  color: $color-black;
  fill: $color-black;
  padding: 1rem 1.6rem;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background: $color-gray-lighten-2;
    color: $color-primary;
    fill: $color-primary;
  }
}

// Label
// NOTE: Dropdownのラベルといってもスタイルが物によって違うので、
// 機能的に最低限必要なスタイルだけを当てるのみにしている
.label {
  cursor: pointer;
}
