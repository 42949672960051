@use "../../../styles/variables.scss" as *;

.root {
  box-sizing: border-box;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.6rem;
  background: #fff;
  padding: 2rem 2.4rem 3.6rem 2rem;
}
.heading {
  color: $color-black;
  font-size: $font-regular-m-font-size;
  margin: 0 0 3.2rem 0;
}
.quiet {
  color: $color-gray-darken-3;
  font-weight: normal;
}
.data {
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
}
.count {
  color: $color-black;
}
.count__number {
  font-size: $font-counter-font-size;
}
.diff {
  display: inline-flex;
  align-items: flex-end;
  margin-left: 2.4rem;
}
.diff__label {
  @extend .quiet;
  font-weight: bold;
}
.diff__value {
  fill: $color-gray-darken-2;
  color: $color-gray-darken-2;
  margin-left: 1rem;
  display: inline-flex;
  align-items: center;
}
.positive {
  fill: #a3c77c;
  color: #a3c77c;
}
.negative {
  fill: #f37a89;
  color: #f37a89;
}

@include max-screen($breakpoint-tablet-wide) {
  .heading {
    margin-bottom: 3rem;
  }
  .data {
    justify-content: space-between;
  }
  .diff__value {
    margin-left: 0.6rem;
  }
  .count__number {
    font-size: 3.4rem;
  }
}

@include min-screen($breakpoint-tablet) {
  .root {
    &:hover {
      box-shadow: 0 1px 4px 0 rgba(21, 27, 38, 0.12);
      margin-top: -1px;
    }
  }
}
@include max-screen($breakpoint-mobile) {
  .root {
    margin: 0;
  }
  .data {
    justify-content: flex-start;
  }
  .diff {
    margin-left: 2.4rem;
  }
}
