@use "../../../styles/variables.scss" as *;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.4rem 3.2rem;
  box-sizing: border-box;
}

.opened {
  width: 100%;
  justify-content: space-between;
}

.logoIcon {
  width: 3.6rem;
  height: 3.6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 3.4rem;
    height: 3.4rem;
  }
}

.logoTxt {
  margin-top: 0.4rem;
  margin-left: 0.8rem;
  line-height: 0;
}

.shallowImage {
  width: 100%;
  display: block;
}

.wideImage {
  display: none;
  align-items: center;
  justify-content: center;
}

.opened .shallowImage {
  display: none;
}

.opened .wideImage {
  display: flex;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: none;
  }
}
