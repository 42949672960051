@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 8.8rem;
  text-align: center;
}
.heading {
  font-size: 2.2rem;
}
.description {
  margin-top: 3.2rem;
  & > a {
    color: $color-primary;
    font-weight: bold;
  }
}
.emailContainer {
  margin-top: 4.8rem;

  & > label {
    width: 100%;
    margin-bottom: 1.2rem;
    text-align: left;
    display: block;
  }
  & > input {
    width: 100%;
    padding: 1.6rem;
    border-radius: 0.8rem;
  }
}
.checkboxContainer {
  margin-top: 2.4rem;
}
.buttonContainer {
  width: 24rem;
  max-width: 80%;
  margin: 3.2rem auto 0 auto;
}
.errorMessage {
  color: $color-notification;
  margin-top: 0.8rem;
}

@include max-screen($breakpoint-tablet) {
  .root {
    margin-top: 3.2rem;
  }
  .buttonContainer {
    width: 100%;
  }
}
