@use "../../styles/variables.scss" as *;
/* TODO: ボタン側にリファクタしたい */
.btn {
  height: 3.2rem;
}
.todaybtn {
  border: 1px solid #dde0e8;
  color: $color-gray-darken-3;
}
.todaybtn:hover {
  background-color: var(--bui-colors-gray-800);
  color: #fff;
}
.addbtn {
  margin-right: 1.6rem;
  width: 3.6rem;
  padding: 0;
}

.root {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.rightMenu {
  justify-self: right;
}

.backBtn {
  cursor: pointer;
  color: $color-gray-darken-2;
  fill: $color-gray-darken-2;
  font-weight: normal;

  &:hover {
    color: $color-primary;
    fill: $color-primary;
  }
}

.backArrowIcon {
  width: 2rem;
}
