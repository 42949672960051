@use "../../../styles/variables.scss" as *;
.root {
  color: #fff;
  border-radius: 1.8rem;
  white-space: nowrap;
  padding: 0.6rem 1.2rem;
  font-weight: thin;
  margin-right: 1rem;
}
.colorTypeLightBlue {
  background-color: $color-light-blue;
}
.colorTypeLightGreen {
  background-color: $color-light-green;
}
