@use "../../../styles/variables.scss" as *;

.summaryWrapper {
  padding: 2rem;
}

.summaryText {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  align-items: baseline;
}

.summaryBoxes {
  margin-top: 1.6rem;
}

.lectureDetailWrapper {
  border-top: 1px solid $color-gray-lighten-1;
}

.ScheduleTime {
  display: flex;
  column-gap: 1rem;
  align-items: baseline;
  color: #4f4e5d;
}

.LearningMaterial_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(1.2rem * 20); // 表示を20文字に制限する
}

.Separator {
  line-height: 1.2em;
}

.SummaryBox {
  border-radius: 0.4rem;
  border: 1px solid $color-gray-lighten-1;
  padding: 1rem 1.6rem;
  box-sizing: border-box;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SummaryBox__text {
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 1.6rem;
}

.SummaryBox__number {
  margin-right: 0.4rem;
  font-size: 2rem;
}

.SummaryBox__unit {
  font-size: 1.4rem;
}
