@use "../../../styles/variables.scss" as *;

.LearningMaterialTagName {
  display: flex;
  align-items: center;
}
.LearningMaterialTagName__Warning {
  fill: #f2ba5f;
  margin-left: 0.4rem;
}
.LearningMaterialConnector {
  position: absolute;
  background-color: $color-gray-lighten-1;
  width: 0.2rem;
  top: 0;
  height: 100%;
  left: calc(
    50% - 0.8rem
  ); /* 0.8 = 0.1(線の太さの半分) + 0.7(このセルの右のパディングの半分) */
  margin: auto;
}
.LearningMaterialConnector--HalfHeight {
  height: 50%;
}
.Icon--FillGray {
  fill: $color-gray-darken-1;
}
.TagIcon {
  width: 1.6rem;
  height: 1.6rem;
}
