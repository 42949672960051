@use "../../styles/variables.scss" as *;
.learningMaterialCell {
  min-width: 20rem;
}
.buttonCell {
  text-align: right;
  min-width: 12rem;
  font-size: 1.2rem;
}

.learningMaterialImg {
  width: 3.8rem;
  height: 5.4rem;
  object-fit: contain;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
}

.linkButtonLabel {
  margin-left: 0.8rem;
}

.learningMaterialLabelList {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
  > li {
    margin-top: 1rem;
  }
}
