.buttonContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1.6rem;
}

.button {
  width: 10.4rem;
  height: 4.6rem;
}

.message {
  margin-top: 0.8rem;
}
