@use "../../../styles/variables.scss" as *;

.tab {
  margin-top: -1rem;
}

.tableControl {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 2.4rem;
}
.MaterialSwitchWrapper {
  display: flex;
  align-items: center;
}
.tableControl div:nth-child(1) {
  grid-column-start: 1;
}

.tableControl div:nth-child(3) {
  margin-left: auto;
}

.tableControlRight {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-left: 1.6rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.tableWrapper {
  background-color: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  margin-top: 2rem;
}

.tableTop {
  padding: 1.6rem 2rem 2rem;
}

@include max-screen($breakpoint-mobile) {
  .tableControl {
    display: block;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
  }

  .tableControlRight {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
