@use "../../../styles/variables.scss" as *;

.root {
  margin-top: 3.2rem;
}

.label {
  @include font-bold-m();
  padding-left: 0.5rem;
}

.tableWrapper {
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighten-1;
  background-color: #fff;
  margin-top: 1.6rem;
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.tableHeader {
  height: 3.2rem;
  border-bottom: 1px solid $color-gray-lighten-1;

  & > th {
    white-space: nowrap;

    &:first-child {
      padding-left: 1.6rem;
    }

    &:last-child {
      padding-right: 1.6rem;
    }
  }
}

.tableRow {
  height: 6.4rem;
  border-top: 1px solid $color-gray-lighten-1;

  & > td {
    white-space: nowrap;
    padding: 0 1.4rem;

    &:first-child {
      padding-left: 1.6rem;
    }

    &:last-child {
      padding-right: 1.6rem;
    }
  }
}

.tableCol {
  max-width: 20rem;
}

.tableLastCol {
  text-align: end;
  vertical-align: middle;
  padding-right: 2rem;
}

.btn {
  border: 1px solid $color-primary;
  border-radius: 1.8rem;
  height: 3.5rem;
  width: 15.3rem;
  font-weight: bold;
}

@include max-screen($breakpoint-mobile) {
  .tableWrapper {
    overflow-x: scroll;
  }
}
