@use "../../../styles/variables.scss" as *;

.u-margin-canceler {
  // svg & button に無駄な余白が入るの防ぐ
  svg,
  button {
    display: block;
  }
}

.StaffShareText {
  text-align: left;
  white-space: pre-line;
}

.StaffShareText__IconWrapper {
  flex-shrink: 0;
}
.LastColumnGrid {
  display: grid;
  grid-template-columns: 1fr 3.2rem;
  place-items: center;
}
.studentName {
  text-decoration: underline;
  color: $color-black;
  font-weight: bold;
}
